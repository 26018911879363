/* eslint-disable import/no-unresolved */
import { Grid, GridItem } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect } from 'react';

import BillsTable from '../../components/BillsTable';

function Bills() {
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Pagos - Facturas',
    });
  }, []);

  return (
    <Grid templateColumns='repeat(12, 1fr)'>
      <GridItem colSpan={12}>
        <BillsTable />
      </GridItem>
    </Grid>
  );
}

export default Bills;
