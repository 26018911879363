/* eslint-disable camelcase */
// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IAliasFavoritesResponse } from '../interfaces/IAliasFavorites';
import { IPreconfectionCart, IPreconfectionStatus } from '../interfaces/IButtonPayCart';
import { ICartItemsQuantity } from '../interfaces/ICartItemsQuantity';
import { ICartsResponse } from '../interfaces/ICarts';
import { IMaxItemsResponse } from '../interfaces/IMaxItems';
import { IFormModalityResponse } from '../interfaces/IModalityForm';
import { INextExpirations } from '../interfaces/INextExpirationsBills';
import { IOperatibility } from '../interfaces/IOperatibility';
import { TableCheckout } from '../interfaces/ITableCheckout';
import { PermissionsResponse } from '../interfaces/Permissions';

const newAxios = axios.create({
  baseURL: process.env.PAGOS_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
});

const newAxiosNestPagos = axios.create({
  baseURL: process.env.PAGOS_NEST_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
});

export const getCompanies = () => newAxiosNestPagos.get('companies');
export const postAvailableBills = fetchOptions => newAxiosNestPagos.post('debts', fetchOptions);
export const getModalities = id => newAxiosNestPagos.get(`/payment-modes/companies/${id}/payment-modes`);
export const getModalityForm = (company: string, modality: string) =>
  newAxios.get<IFormModalityResponse>(`/companies/${company}/modalities/${modality}`);
export const createServiceAlias = payload => newAxiosNestPagos.post('/alias', payload);
export const getExistingAlias = payload => newAxiosNestPagos.post('/alias/existing', payload);
export const getMaxItems = () => newAxiosNestPagos.get<IMaxItemsResponse>(`/cart/maxitems`);
export const getCartsItems = () => newAxiosNestPagos.get<ICartsResponse>(`/cart`);
export const getAliasFavorites = (page: number, pageSize: number) => {
  const apiPage = page + 1;
  return newAxiosNestPagos.get<IAliasFavoritesResponse>(`alias/favorites`, { params: { page: apiPage, pageSize } });
};

export const deleteAlias = aliasName => newAxiosNestPagos.delete(`/alias/${aliasName}`);

export const getVoucher = getVoucherParams => {
  const searchParams = new URLSearchParams();

  Object.keys(getVoucherParams).forEach(key => {
    const value = getVoucherParams[key];
    if (Array.isArray(value)) {
      value.forEach(arrayValue => searchParams.append(key, arrayValue));
    } else {
      searchParams.append(key, value);
    }
  });

  const searchParamsString = searchParams.toString();

  return newAxiosNestPagos.get(`/vouchers?${searchParamsString}`);
};
export const getPermissions = () => newAxiosNestPagos.get<PermissionsResponse>(`/permission/operator`);
export const createCartItem = payload => newAxiosNestPagos.post(`/cart`, payload);
export const createCartItemList = (items: AxiosRequestConfig[]) => Promise.allSettled(items);
export const getCartCompanyItems = () => newAxiosNestPagos.get<ICartsResponse>(`/cart`);
export const deleteCarts = items => newAxiosNestPagos.post('/cart/delete', items);
export const getCartItemsQuantity = () => newAxiosNestPagos.get<ICartItemsQuantity>(`/cart/maxitems`);
export const preconfection = () => newAxios.post<IPreconfectionCart>(`/payments/preconfection`);
export const preconfectionStatus = preconfectionId =>
  newAxios.get<IPreconfectionStatus>(`/payments/preconfection/status/${preconfectionId}`);
export const deleteBillsPreconfection = () => newAxios.delete(`/carts/invoice/items`);
export const downloadReceipt = payment =>
  newAxios.get(`/voucher/pdf/${payment}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });
export const getOperatibility = () => newAxiosNestPagos.get<IOperatibility>(`operability`);
export const downloadReceiptZip = postZipPdfBody =>
  newAxios.post('/voucher/zip/pdf/', postZipPdfBody, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/zip',
    },
  });

export const updateServiceAlias = (currentAlias: string, newAliasName: string) =>
  newAxiosNestPagos.patch(`/alias/${currentAlias}`, { alias_name: newAliasName });
// export const getCompaniesReceipt = () => newAxios.get('companies/voucher');
export const getCompaniesReceipt = () => newAxiosNestPagos.get('/vouchers-companies');
export const getNextExpirations = (sortProperty: string, sortDirection: string, page: number, size: number) =>
  newAxios.get<INextExpirations>('nextExpirations', {
    params: { page, size, 'sort-field': sortProperty, 'sort-direction': sortDirection },
  });

export const getDataTableCheckout = ({ params = null }): Promise<AxiosResponse<TableCheckout>> => {
  return newAxiosNestPagos.get(`/accounts`, { params: { ...params } });
};

export const deleteItemsFromCart = ({ items }) => newAxiosNestPagos.post('/cart/delete', { items });
export const getPaymentStatus = ({ batch_id }) => newAxiosNestPagos.get(`payments/${batch_id}`);
export const postPayment = ({ debit_account_id }) => newAxiosNestPagos.post('/payments', { debit_account_id });
