import React from 'react';

function Binoculars() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='120' height='120' fill='none' viewBox='0 0 120 120'>
      <path fill='#AAC7FF' d='M73.5 38.499V24c0-5.79 4.71-10.5 10.5-10.5S94.5 18.21 94.5 24v14.499h-21z' />
      <path
        fill='#2172DC'
        d='M84 15c4.962 0 9 4.038 9 9v12.999H75V24c0-4.962 4.038-9 9-9zm0-3c-6.627 0-12 5.373-12 12v15.999h24V24c0-6.627-5.373-12-12-12z'
      />
      <path
        fill='#AAC7FF'
        d='M70.5 79.383V38.532c0-4.251 3.885-13.032 15.891-13.032 10.92 0 16.113 7.932 16.167 8.01.066.171 14.868 31.251 15.888 45.873H70.5z'
      />
      <path
        fill='#2172DC'
        d='M86.391 27c9.375 0 14.118 6.204 14.817 7.197 1.215 2.571 13.815 29.481 15.588 43.689H72V38.532C72 35.541 74.643 27 86.391 27zm0-3C72.963 24 69 34.02 69 38.532v42.354h51c0-14.373-16.182-48.18-16.182-48.18S98.259 24 86.391 24z'
      />
      <path
        fill='#AAC7FF'
        d='M49.5 42.141c2.058-.543 6.735-1.641 10.863-1.641 4.059 0 8.256 1.062 10.137 1.608v24.87c-2.247-.618-6.105-1.476-10.137-1.476-4.155 0-8.448.915-10.863 1.527V42.141z'
      />
      <path
        fill='#2172DC'
        d='M60.363 42c3.228 0 6.57.714 8.637 1.251V65.055c-2.328-.534-5.43-1.056-8.637-1.056-3.372 0-6.825.579-9.363 1.134V43.308C53.277 42.756 57.012 42 60.363 42zm0-3C54.612 39 48 41.001 48 41.001V69s6.405-2.001 12.363-2.001C66.321 66.999 72 69 72 69V57v-3.999-12S66.114 39 60.363 39z'
      />
      <path fill='#EBF0FF' d='M94.5 58.5a24 24 0 100 48 24 24 0 000-48z' />
      <path
        fill='#2172DC'
        d='M94.5 60c12.405 0 22.5 10.092 22.5 22.5S106.905 105 94.5 105 72 94.908 72 82.5 82.095 60 94.5 60zm0-3C80.418 57 69 68.418 69 82.5S80.418 108 94.5 108 120 96.582 120 82.5 108.582 57 94.5 57z'
      />
      <path stroke='#fff' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='4' d='M84 84c0-6.627 5.373-12 12-12' />
      <path fill='#AAC7FF' d='M25.5 38.499V24c0-5.79 4.71-10.5 10.5-10.5S46.5 18.21 46.5 24v14.499h-21z' />
      <path
        fill='#2172DC'
        d='M36 15c4.962 0 9 4.038 9 9v12.999H27V24c0-4.962 4.038-9 9-9zm0-3c-6.627 0-12 5.373-12 12v15.999h24V24c0-6.627-5.373-12-12-12z'
      />
      <path
        fill='#AAC7FF'
        d='M1.557 79.383c1.02-14.625 15.822-45.708 15.978-46.032.12-.165 5.22-7.851 16.074-7.851 12.006 0 15.891 8.778 15.891 13.032v40.854H1.557v-.003z'
      />
      <path
        fill='#2172DC'
        d='M33.609 27C45.357 27 48 35.541 48 38.532v39.354H3.207c1.77-14.208 14.373-41.118 15.588-43.689.678-.966 5.442-7.197 14.814-7.197zm0-3c-11.868 0-17.43 8.706-17.43 8.706S0 66.513 0 80.886h51V38.532C51 34.02 47.037 24 33.609 24z'
      />
      <path fill='#EBF0FF' d='M25.5 58.5a24 24 0 100 48 24 24 0 000-48z' />
      <path
        fill='#2172DC'
        d='M25.5 60C37.905 60 48 70.092 48 82.5S37.905 105 25.5 105 3 94.908 3 82.5 13.095 60 25.5 60zm0-3C11.418 57 0 68.418 0 82.5S11.418 108 25.5 108 51 96.582 51 82.5 39.582 57 25.5 57z'
      />
      <path stroke='#fff' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='4' d='M15 84c0-6.627 5.373-12 12-12' />
    </svg>
  );
}

export default Binoculars;
