import { Divider, GridItem } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { useContext } from 'react';

import AppContext from '../../context/Provider';
import { IBill } from '../../interfaces/ICarts';
import { AccordionBody } from '../../text/GeneralText';
import TableComponent from '../TableComponent';
import BillsCompanyColumns from './components/BillsCompanyCConfig';

interface BillsCompanyCartsProps {
  bills: IBill[];
  snackbarAction: (company: string, success: boolean, type: string) => void;
}
// Tabla de carrito de pagos
function BillsCompanyCarts({ bills, snackbarAction }: BillsCompanyCartsProps) {
  const { setRowsSelected } = useContext(AppContext);

  if (bills.length === 0) {
    return <span>{AccordionBody.Error}</span>;
  }

  return (
    <GridItem colSpan={12}>
      <TableComponent<IBill>
        inputData={bills}
        columnsData={BillsCompanyColumns(snackbarAction) as ColumnDef<RowData>[]}
        setSelectedRows={setRowsSelected}
      />

      <Divider className='lineGray' />
    </GridItem>
  );
}
export default BillsCompanyCarts;
