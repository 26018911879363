import styled from 'styled-components';

import EmptyImg from '../../assets/images/beware.png';
import DeleteImg from '../../assets/images/Cancel.png';
import { colors, fonts, sizeFont } from '../../styled/generalStyles';

export const Title = styled.div`
  font-family: ${fonts.headingBold};
  font-size: ${sizeFont.fontSize28};
  line-height: 36px;
  text-align: center;
  margin-top: 12px;
`;

export const SubTitle = styled.div`
  font-family: ${fonts.body};
  font-size: ${sizeFont.fontSize14};
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
`;

export const PaddingModal = styled.div`
  padding: 50px 25px 32px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    text-align: center;
    margin-top: 18px;
    height: 75px;
    width: 75px;
  }

  .delete {
    background: url(${DeleteImg}) no-repeat;
    background-position: center;
  }

  .empty {
    background: url(${EmptyImg}) no-repeat;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
`;

export const iconClose = {
  marginTop: '15px',
  boxShadow: 'none',
  color: colors.blue,
  marginRight: '15px',
  background: 'none',
};
