/* eslint-disable import/no-unresolved */
import { AccordionButton, AccordionIcon, Badge, Box, Button, Text } from '@chakra-ui/react';
import { OpenMode } from '@wow/utils';

import { SummaryHeaderProps } from '../../../interfaces/ISummary';
import { colors } from '../../../styled/generalStyles';
import { CheckoutTexts } from '../../../text/GeneralText';

function SummaryHeader({
  handleHover,
  isExpanded,
  isSuccess,
  variantBadge,
  badgeText,
  title,
  subTitle,
  isOnlyToggle = false,
}: SummaryHeaderProps) {
  const authorizedLink = () => {
    window.open(
      OpenMode({
        module: 'urlComplete',
        urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_TRANSFERENCIAS_AUTORIZACIONES}`,
      }),
    );
  };
  return (
    <div className='container-header_grid'>
      <Box className='flex justify-between items-center flex-row'>
        <Box marginLeft='12px'>
          {' '}
          <Badge variant={variantBadge}>{badgeText}</Badge>
        </Box>
        <Box width='100%' marginLeft='32px' className='flex flex-col justify-center items-start'>
          <Text className='titleLgBold'>{title}</Text>
          <Text textStyle='bodyMdSemi'>{subTitle}</Text>
        </Box>
      </Box>
      {isSuccess ? (
        <div className='accordion-buttons'>
          <Button onClick={authorizedLink} marginX='12px'>
            {CheckoutTexts.title1}
          </Button>
          <AccordionButton
            marginX='12px'
            className={`accordion-button-arrow ${!isExpanded ? 'selected' : ''}`}
            _expanded={{ bg: colors.lightBlueNew }}
            onMouseEnter={() => handleHover(isExpanded)}
            onMouseLeave={() => handleHover(!isExpanded)}
          >
            <AccordionIcon />
          </AccordionButton>
        </div>
      ) : null}
      {isOnlyToggle ? (
        <div className='accordion-buttons justify-content-end '>
          <AccordionButton
            marginX='12px'
            className={`accordion-button-arrow ${!isExpanded ? 'selected' : ''}`}
            _expanded={{ bg: colors.lightBlueNew }}
            onMouseEnter={() => handleHover(isExpanded)}
            onMouseLeave={() => handleHover(!isExpanded)}
          >
            <AccordionIcon />
          </AccordionButton>
        </div>
      ) : null}
    </div>
  );
}
export default SummaryHeader;
