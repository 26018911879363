const DropdownActions = {
  isOpen: false,
  callFunction: null,
  setIsOpen(state) {
    this.isOpen = state;
    if (this.callFunction) {
      this.callFunction();
    }
  },
  setCallFunction(func) {
    this.callFunction = func;
  },
};

export default DropdownActions;
