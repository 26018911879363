import styled from 'styled-components';

const Container = styled.div`
  .chakra-badge {
    text-transform: lowercase;
  }
  .chakra-badge p:first-letter {
    text-transform: uppercase;
  }
`;

export default Container;
