function Binoculars() {
  return (
    <svg width='114' height='102' viewBox='0 0 114 102' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.5 1.5H112.5V100.5H1.5V1.5Z' fill='white' />
      <path d='M111 3V99H3V3H111ZM114 0H0V102H114V0Z' fill='#2172DC' />
      <path d='M3 3H111V21H3V3Z' fill='#AAC7FF' />
      <path
        d='M57 88.5C41.286 88.5 28.5 75.714 28.5 60C28.5 44.286 41.286 31.5 57 31.5C72.714 31.5 85.5 44.286 85.5 60C85.5 75.714 72.714 88.5 57 88.5ZM47.118 76.941L49.119 77.826C51.5989 78.9325 54.2845 79.5029 57 79.5C67.752 79.5 76.5 70.752 76.5 60C76.502 57.2845 75.9316 54.5992 74.826 52.119L73.941 50.121L47.118 76.941ZM57 40.5C51.8302 40.5064 46.874 42.5629 43.2184 46.2184C39.5629 49.874 37.5064 54.8302 37.5 60C37.5 62.766 38.082 65.463 39.234 68.013L40.128 69.993L66.993 43.128L65.016 42.234C62.4983 41.0909 59.7651 40.4996 57 40.5Z'
        fill='#AAC7FF'
      />
      <path
        d='M57 33C71.889 33 84 45.111 84 60C84 74.889 71.889 87 57 87C42.111 87 30 74.889 30 60C30 45.111 42.111 33 57 33ZM39.657 72.588L69.591 42.654L65.637 40.866C62.9237 39.6362 59.979 39 57 39C45.42 39 36 48.42 36 60C36 62.985 36.63 65.889 37.869 68.634L39.657 72.588ZM57 81C68.58 81 78 71.58 78 60C78 57.066 77.394 54.21 76.2 51.51L74.43 47.511L44.514 77.427L48.513 79.197C51.1838 80.388 54.0757 81.0024 57 81ZM57 30C40.431 30 27 43.431 27 60C27 76.569 40.431 90 57 90C73.569 90 87 76.569 87 60C87 43.431 73.569 30 57 30ZM40.602 67.398C39.5474 65.0742 39.0012 62.5519 39 60C39 55.2261 40.8964 50.6477 44.2721 47.2721C47.6477 43.8964 52.2261 42 57 42C59.64 42 62.139 42.582 64.398 43.602L40.602 67.398ZM57 78C54.4936 78.0002 52.015 77.4738 49.725 76.455L73.455 52.725C74.442 54.951 75 57.408 75 60C75 64.7739 73.1036 69.3523 69.7279 72.7279C66.3523 76.1036 61.7739 78 57 78Z'
        fill='#2172DC'
      />
    </svg>
  );
}

export default Binoculars;
