import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { AmountTypes, ComponentProps } from '../../interfaces/IInputWithAmount';
import { InputAmount } from '../../text/GeneralText';
import { transformStringToCurrency, transformStringToNumber } from '../../utils/converters';
import { LabeledInput } from './styled';

function InputWithAmount({ value, minAmount, amountType, maxAmount, onChange }: ComponentProps) {
  const [paymentValue, setPaymentValue] = useState(value);
  const [isErrorMinAmount, setIsErrorMinAmount] = useState(false);
  const [isErrorMaxAmount, setIsErrorMaxAmount] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const numberValue = transformStringToNumber(inputRef.current.value);
    setIsErrorMinAmount(numberValue !== 0 && numberValue < Number(minAmount));
    setIsErrorMaxAmount(numberValue > Number(maxAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentValue, minAmount, maxAmount]);

  function isErrorAmount() {
    if (isErrorMinAmount || isErrorMaxAmount || paymentValue === 0) {
      return 'inputError';
    }
    return 'inputEnabled inputAmountOpen';
  }

  function isError() {
    if (isErrorMinAmount && !isErrorMaxAmount) {
      return (
        <FormErrorMessage width='100%' margin='0' className='error-message'>
          {InputAmount.montoMin} {transformStringToCurrency(minAmount)}
        </FormErrorMessage>
      );
    }
    if (isErrorMaxAmount && !isErrorMinAmount) {
      return (
        <FormErrorMessage width='100%' margin='0' className='error-message'>
          {InputAmount.montoMax} {transformStringToCurrency(maxAmount)}
        </FormErrorMessage>
      );
    }
    if (paymentValue === 0) {
      return (
        <FormErrorMessage width='100%' margin='0' className='error-message'>
          {InputAmount.addAmount}
        </FormErrorMessage>
      );
    }
    return null;
  }

  return (
    <span>
      {amountType === AmountTypes.ABIERTO ? (
        <LabeledInput>
          <FormControl isInvalid={isErrorMinAmount || isErrorMaxAmount || paymentValue === 0}>
            <CurrencyInput
              ref={inputRef}
              defaultValue={paymentValue}
              decimalsLimit={2}
              onValueChange={val => {
                onChange(transformStringToNumber(val));
                setPaymentValue(transformStringToNumber(val));
              }}
              prefix='$ '
              decimalSeparator=','
              groupSeparator='.'
              className={isErrorAmount()}
              decimalScale={2}
              maxLength={15}
              onKeyDown={e => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              }}
            />
            {isError()}
          </FormControl>
        </LabeledInput>
      ) : (
        <LabeledInput>
          <FormControl isInvalid={isErrorMinAmount || isErrorMaxAmount || paymentValue === 0}>
            <CurrencyInput
              ref={inputRef}
              defaultValue={paymentValue}
              decimalsLimit={2}
              onValueChange={val => setPaymentValue(transformStringToNumber(val))}
              prefix='$ '
              decimalSeparator=','
              groupSeparator='.'
              disabled
              className='inputDisabled inputFont'
              decimalScale={2}
            />
            {isError()}
          </FormControl>
        </LabeledInput>
      )}
    </span>
  );
}

export default InputWithAmount;
