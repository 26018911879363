import { Input } from '@chakra-ui/react';
import styled from 'styled-components';

import { body14Simple, colors } from '../../styled/generalStyles';

export const ContainerSubtitle = styled.div`
  .textH6 {
    margin: 15px 0px 15px 0px !important;
  }

  .textbodyMd {
    margin-bottom: 10px;
  }
`;

export const ContainerFooter = styled.div`
  .marginButton {
    margin: 0px 15px 0px 0px !important;
  }
`;

export const CustomInput = styled(Input)`
  width: 100%;
`;

export const companyname = {
  fontFamily: 'Red Hat Display',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '22px',
  marginBottom: '10px',
};
export const iconFocusGray = {
  width: '20px',
  height: '20px',
  color: `${colors.white}`,
};

export const iconFocusBlue = {
  width: '20px',
  height: '20px',
  color: `${colors.blue}`,
};

export const iconError = {
  width: '20px',
  height: '20px',
  color: `${colors.error}`,
};

export const iconStar = {
  marginTop: '20px',
  width: '20px',
  height: '19px',
};

export const inputText = {
  height: '40px',
  background: `${colors.white}`,
  border: '1px solid',
  borderColor: `${colors.light}`,
  borderRadius: '4px',
  fontFamily: `${body14Simple}`,
  '::placeholder': {
    color: `${colors.light}`,
  },
  ':focus': {
    zIndex: '-1',
    borderColor: `${colors.white} !important`,
  },
  ':hover': {
    borderColor: `${colors.textgray}`,
  },
};

export const iconClose = {
  padding: '20px 20px 0px 0px',
  ':hover': {
    background: 'none',
  },
  ':focus': {
    boxShadow: 'none',
  },
};
