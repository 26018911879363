import { Box, GridItem, Text } from '@chakra-ui/react';

import { Bill } from '../../../interfaces/ISummary';
import { ColumnsConfigText } from '../../../text/GeneralText';
import { transformNumberToCurrency } from '../../../utils/converters';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import { StyledFlexText, StyledGrid } from '../styled';

function SummaryBills({ isLineVisible = false, summaryData }: { isLineVisible?: boolean; summaryData: Bill[] }) {
  return (
    <div>
      {summaryData?.map((bill, index) => (
        <StyledGrid $isLineVisible={isLineVisible} index={index} key={bill?.identifier} $isLast={index === summaryData.length - 1}>
          <Box>
            <GridItem colStart={1} colEnd={4}>
              <StyledFlexText>
                <TextEllipsisWithTooltip
                  placement='top'
                  textStyle='titleMdBold'
                  maxWidth='380px'
                  width='380px'
                  text={`${bill?.alias_name ? `${bill.company_name} - ${bill.alias_name}` : bill?.company_name}`}
                >
                  <span>{`${bill?.alias_name ? `${bill.company_name} - ${bill.alias_name}` : bill?.company_name}`}</span>
                </TextEllipsisWithTooltip>
                <Text textStyle='bodyMd'>{bill?.client_id}</Text>
              </StyledFlexText>
            </GridItem>
          </Box>
          <Box marginX='50px'>
            <GridItem colStart={4} colEnd={5}>
              <StyledFlexText>
                <Text textStyle='titleMdBold'>{ColumnsConfigText.vencimiento}</Text>
                <Text textStyle='bodyMd'>
                  {bill?.expiration_date_1?.includes('s/vto') ? ColumnsConfigText.sinVencimiento : bill?.expiration_date_1}
                </Text>
              </StyledFlexText>
            </GridItem>
          </Box>
          <Box marginX='50px'>
            <GridItem colStart={5} colEnd={7}>
              <StyledFlexText>
                <Text textStyle='titleMdBold'>{ColumnsConfigText.importe}</Text>
                <Text textStyle='bodyMd'>{transformNumberToCurrency(bill?.amount)}</Text>
              </StyledFlexText>
            </GridItem>
          </Box>
        </StyledGrid>
      ))}
    </div>
  );
}

export default SummaryBills;
