import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

import { colors } from '../../../styled/generalStyles';

export const Container = styled(Box)`
  .flexButtonFooter {
    display: flex;
    justify-content: center;
    margin: 0px 24px 24px 24px;
  }

  .titleH3 {
    text-align: center;
  }

  .subTitle {
    margin-top: 20px;
    text-align: center;
  }

  .margin15 {
    margin-left: 15px;
  }
  .textBodyMd {
    text-align: center;
    margin-top: 20px;
  }
`;

export const PaddingModal = styled.div`
  padding: 50px;
  padding: 50px 25px 40px 25px;
  .img {
    margin: auto;
    height: 72.2px;
    width: 72.2px;
    margin-top: 23px;
    margin-bottom: 20px;
  }
`;

export const iconClose = {
  marginTop: '15px',
  color: colors.blue,
  marginRight: '15px',
  boxShadow: 'none',
  background: 'none',
};
