/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useReducer } from 'react';

import { RESET_ITEMS, SET_COUNT, SET_SELECTED_ITEMS, SET_TEMP_SELECTED_ITEMS, UPDATE_SELECTED_ITEMS } from '../components/filter/constants';

const initialState = {
  selectedItems: [],
  tempSelectedItems: [],
  count: 0,
};
// Reducer
const reducer = (state, action) => {
  const actionHandlers = {
    [SET_SELECTED_ITEMS]: () => ({ ...state, selectedItems: action.payload }),
    [SET_COUNT]: () => ({ ...state, count: action.payload }),
    [UPDATE_SELECTED_ITEMS]: () => ({ ...state, ...action.payload }),
    [SET_TEMP_SELECTED_ITEMS]: () => ({ ...state, tempSelectedItems: action.payload }),
    [RESET_ITEMS]: () => ({ ...initialState }),
  };
  const handler = actionHandlers[action.type] || state;
  return handler();
};

interface IParams {
  dispatch: React.Dispatch<void>;
  state: string;
  item: object;
}
interface IUseFilter {
  deleteAllFilters: () => void;
  handleChangeOption: (params: IParams) => void;
}
const useFilter = ({ handleChangeOption }: Partial<IUseFilter>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedItems, tempSelectedItems, count } = state;

  // handle the selected items
  const handleClickOption = useCallback(
    (item: any) => {
      if (handleChangeOption) {
        handleChangeOption({ dispatch, state, item });
      } else {
        dispatch({
          type: SET_SELECTED_ITEMS,
          payload: selectedItems.includes(item) ? selectedItems.filter(itemName => itemName !== item) : [...selectedItems, item],
        });
      }
    },

    [handleChangeOption, state, selectedItems],
  );

  // function to clean internal filters
  const deleteSelectedItemsTemp = useCallback(() => dispatch({ type: SET_SELECTED_ITEMS, payload: [] }), []);

  // function to clean all filters not in memory if you want to delete the filters in params to any promise you must use the context
  // with the method clearFilterValues
  const deleteAllFilters = useCallback(() => {
    dispatch({ type: RESET_ITEMS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, tempSelectedItems, count]);

  const handleInnerChangesTag = ({ onClose }: { onClose: () => void }) => {
    dispatch({ type: SET_SELECTED_ITEMS, payload: tempSelectedItems });
    onClose();
  };

  const handleSelectedTempItems = useCallback(() => {
    dispatch({ type: SET_SELECTED_ITEMS, payload: state.tempSelectedItems });
  }, [state.tempSelectedItems]);

  return { state, handleInnerChangesTag, handleClickOption, deleteSelectedItemsTemp, deleteAllFilters, dispatch, handleSelectedTempItems };
};

export default useFilter;
