import { Badge, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { BadgeVariant } from '../../interfaces/IBadgeVariantTypes';
import StatusTypes from '../../interfaces/IStatus';
import { StatusBadgeTexts } from '../../text/GeneralText';
import Container from './styled';

interface ComponentProps {
  status: string;
  noTooltip?: boolean;
}

const initialState = { status: ' ', tooltipMessage: ' ', variant: 'neutral-light' as BadgeVariant };

function BillStatus({ status, noTooltip }: ComponentProps) {
  const [badge, setBadge] = useState<{ status: string; tooltipMessage: string; variant: BadgeVariant }>(initialState);
  useEffect(() => {
    switch (status?.toLowerCase()) {
      case StatusTypes.PAGADA:
        return setBadge({ status, tooltipMessage: StatusBadgeTexts.mensajeFacturaCuentaConPagoRealizado, variant: 'success-dark' });
      case StatusTypes.AGREGADA_AL_CARRITO:
        return setBadge({ status, tooltipMessage: StatusBadgeTexts.mensajeFacturaYaEnCarrito, variant: 'neutral-light' });
      case StatusTypes.VENCIDA:
        return setBadge({ status, tooltipMessage: '', variant: 'error-light' });
      case StatusTypes.VENCE_HOY:
        return setBadge({ status, tooltipMessage: '', variant: 'warning-light' });
      case StatusTypes.POR_VENCER:
      case StatusTypes.SIN_FECHA:
        return setBadge({ status, tooltipMessage: '', variant: 'info-light' });
      case StatusTypes.EN_PROCESO:
        return setBadge({ status, tooltipMessage: '', variant: 'neutral-light' });
      default:
        return setBadge({ status, tooltipMessage: '', variant: 'neutral-light' });
    }
  }, [status]);

  const shouldDisplayTooltip = badge.tooltipMessage !== '' && !noTooltip;

  return (
    <span>
      <Container>
        {shouldDisplayTooltip ? (
          <Tooltip placement='right' label={badge.tooltipMessage} hasArrow>
            <Badge variant={`${badge.variant}`} data-testid={`${badge.variant}`}>
              <p>{badge.status}</p>
            </Badge>
          </Tooltip>
        ) : (
          <Badge variant={`${badge.variant}`} data-testid={`${badge.variant}`}>
            <p>{badge.status}</p>
          </Badge>
        )}
      </Container>
    </span>
  );
}

export default BillStatus;
