/* eslint-disable import/no-import-module-exports */
import DOMPurify from 'dompurify';

import { WizardHome } from '../../text/GeneralText';

export const WizardSteps = [
  {
    title: WizardHome.titleInitialZero,
    // eslint-disable-next-line  react/no-danger
    description: <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(WizardHome.descTitlePay) }} />,
    target: 'titlePay',
    offsetExtraY: 130,
    offsetExtraX: 0,
    enableScroll: false,
  },
  {
    title: WizardHome.titleSearchBillStep,
    description: WizardHome.descSearch,
    target: 'searchBills',
    offsetExtraY: 75,
    offsetExtraX: 128,
  },
  {
    title: WizardHome.titleExpirationCalendarStep,
    description: WizardHome.descExpiration,
    target: 'calendarBills',
    placement: 'bottom',
    offsetExtraY: 135,
    offsetExtraX: 100,
  },
  {
    title: WizardHome.titlePreparePayment,
    description: WizardHome.descriptionPreparePayment,
    target: 'prepare-payment-btn',
    placement: 'bottom',
    offsetExtraY: 135,
    offsetExtraX: 100,
  },
  {
    title: WizardHome.titleShortcutStep,
    description: WizardHome.descShorcut,
    target: 'shortcuts',
    offsetExtraY: 83,
    offsetExtraX: 130,
  },
];

export default WizardSteps;
