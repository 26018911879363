/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { Tooltip } from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect } from 'react';
import { ShoppingCart } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { getCartItemsQuantity } from '../../services/api';
import { TooltipTexts } from '../../text/GeneralText';
import { BoxIcon } from './styled';

function GoCartButton() {
  const { operative, startOperative, endOperative, cartItemsCount, setCartItemsCount, validateOperatibility, permissions } =
    useContext(AppContext);

  const navigate = useNavigate();

  const handleGoToCart = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Ir al carrito',
    });
    return navigate(`/${routesNames.PaymentCart}`);
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      const res = await getCartItemsQuantity();
      if (res.data.maxItems <= cartItemsCount) {
        setCartItemsCount(res.data.maxItems);
      }
    };

    fetchCartItems();
    validateOperatibility();
  }, []);

  const getTooltipLabel = () => {
    if (!operative) {
      return TooltipTexts.iconAddCart(startOperative, endOperative);
    }
    return TooltipTexts.withoutPermissions;
  };

  const isDisabled = !operative || !permissions?.generacionPreconfeccionPropia || !permissions?.generacionConfeccion;
  return (
    <BoxIcon isDisabled={isDisabled}>
      <div>
        <Tooltip
          isDisabled={operative && permissions?.generacionPreconfeccionPropia && permissions?.generacionConfeccion}
          hasArrow
          placement='left'
          label={getTooltipLabel()}
          shouldWrapChildren
        >
          <span data-testid='button-cart-wrapper'>
            <IconButtonComponent
              aria-label='icon-button-cart'
              variant='icon-outline'
              size='i-md'
              data-testid='button-cart'
              onClick={handleGoToCart}
              isDisabled={isDisabled}
              icon={<ShoppingCart />}
              meta={cartItemsCount > 0}
            />
          </span>
        </Tooltip>
      </div>
    </BoxIcon>
  );
}

export default GoCartButton;
