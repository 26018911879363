/* eslint-disable import/no-unresolved */
import { Grid, GridItem } from '@chakra-ui/react';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { useContext } from 'react';

import CheckoutList from '../../components/CheckoutList';
import AppContext from '../../context/Provider';
import { PreparePaymentText } from '../../text/GeneralText';

function Checkout() {
  const { isLoadingScreen } = useContext(AppContext);
  return (
    <>
      {isLoadingScreen && <LoadingScreen title={PreparePaymentText.title} description={PreparePaymentText.subtitle} />}
      <Grid templateColumns='repeat(12, 1fr)'>
        <GridItem colSpan={12}>
          <CheckoutList />
        </GridItem>
      </Grid>
    </>
  );
}

export default Checkout;
