/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuList, ModalContent, ModalContentProps } from '@chakra-ui/react';
import styled, { css } from 'styled-components';

import { colors, fonts, fontWeight, sizeFont } from '../../../styled/generalStyles';

export const ContainerMenu = styled.div`
  outline: rgb(190, 198, 220) solid 1px;
  display: grid;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: var(--chakra-space-4);
  min-height: 64px;
  color: var(--others-on-surface, #2f3034);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const ContainerMenuList = styled(MenuList)`
  &.menu-list {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
    min-width: 284px;
    border-radius: 12px;
    & > div.chakra-menu__group:first-of-type > div {
      padding: 10px 8px 8px 16px;
    }
    & > div.chakra-menu__group:nth-of-type(2) {
      padding: 16px 0px 20px 16px;
      display: block;
      max-height: 270px;
      overflow-y: auto;
      > * {
        height: 48px;
      }
    }
    & > div.chakra-menu__group:last-of-type {
      display: flex;
      justify-content: space-around;
    }
  }
`;

export const ContainerCartSummary = styled.div`
  outline: rgb(190, 198, 220) solid 1px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  min-height: 152px;
  color: ${colors.textgray};
  text-align: center;
`;

export const ContainerCartSummaryTotal = styled.div<{ isLessThan1000?: boolean }>`
  padding: 8px 16px 4px 16px;
  display: flex;
  background: ${colors.lightBlueNew};
  border-radius: 12px 12px 0 0;
  align-items: ${({ isLessThan1000 }) => (isLessThan1000 ? 'flex-start' : 'center')};
  justify-content: ${({ isLessThan1000 }) => (isLessThan1000 ? 'space-around' : 'space-between')};
  min-height: ${({ isLessThan1000 }) => (isLessThan1000 ? '100px' : '72px')};
  flex-direction: ${({ isLessThan1000 }) => (isLessThan1000 ? 'column' : 'row')};
  color: ${colors.textgray};
  font-family: Red Hat Display;
  font-size: ${sizeFont.fontSize24};
  font-style: normal;
  font-weight: ${fontWeight.fw7x};
  line-height: 32px;
`;

export const ContainerCartSummaryBills = styled.div<{ isLessThan1000?: boolean }>`
  outline: rgb(190, 198, 220) solid 1px;
  display: flex;
  border-radius: 0 0 12px 12px;
  padding: 4px 16px 4px 16px;
  align-items: ${({ isLessThan1000 }) => (isLessThan1000 ? 'flex-start' : 'center')};
  justify-content: ${({ isLessThan1000 }) => (isLessThan1000 ? 'space-around' : 'space-between')};
  min-height: ${({ isLessThan1000 }) => (isLessThan1000 ? '127px' : '80px')};
  color: ${colors.textgray};
  text-align: center;
  flex-direction: ${({ isLessThan1000 }) => (isLessThan1000 ? 'column' : 'row')};
  font-family: ${fonts.body};
  font-size: ${sizeFont.fontSize18};
  font-style: normal;
  font-weight: ${fontWeight.fw5x};
  line-height: 24px;
`;

export const CartSummaryBillsButton = styled.div<{ isLessThan1000?: boolean }>`
  display: flex;
  width: ${({ isLessThan1000 }) => (isLessThan1000 ? '-webkit-fill-available' : 'auto')};
  justify-content: flex-end;
`;

export const CartSummaryTotalText = styled.div<{ isLessThan1000?: boolean }>`
  display: flex;
  width: ${({ isLessThan1000 }) => (isLessThan1000 ? '-webkit-fill-available' : 'auto')};
  justify-content: flex-end;
  font-size: 28px;
  font-family: ${fonts.heading};
`;

export const TableContainer = styled.div<{ isLessThan1000?: boolean }>`
  margin-top: ${({ isLessThan1000 }) => (isLessThan1000 ? '-171px' : '-96px')};

  .margin-avatar {
    text-align: center;
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .margin-avatar {
      padding: 0 11px 0 5px;
    }
  }

  @media (min-width: 1401px) {
    .margin-avatar {
      padding: 0px 11px 0px 0px;
    }
  }
  @media (min-width: 1670px) {
    .margin-avatar {
      padding: 0;
    }
  }
  .bank-name-cell {
    text-align: start;
  }
`;

export const Container = styled.div`
  .drawer {
    padding: 32px 24px;
    > header {
      padding: 0;
    }
    > button[type='button'] {
      top: 2rem;
      right: 2rem;
    }
  }
  .title,
  .sub-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
  .sub-title {
    font-size: 14px;
    font-family: ${fonts.body};
  }
  .total {
    border: rgb(190, 198, 220) solid 1px;
    padding: 12px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    border-radius: 12px;
    font-size: 20px;
    height: 84px;
    align-items: center;
    font-family: ${fonts.heading};
    > span {
      font-size: 28px;
    }
  }
  .container-table {
    font-family: Inter !important;
    margin-top: 16px;
    padding: 0;
    overflow-x: hidden;
    margin-bottom: 48px;
    > table {
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid #c7c6ca;
      border-spacing: 0 1px;
      border-collapse: initial;
      > thead {
        height: 44px;
        background: var(--Color-Neutral-40, #eff0fa);
        font-size: 16px;
        > * {
          padding: 12px;
          max-width: 119px;
        }
      }
    }
    & th {
      font-family: ${fonts.body};
      font-weight: 500;
      text-transform: lowercase;
      font-size: 16px;
      color: var(--Grey-120, #2f3034);
      letter-spacing: 0.15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
    &:first-letter {
      text-transform: uppercase;
    }
    &:nth-of-type(2) {
      font-weight: 400;
      font-size: 14px;
    }
    }
    
    & td {
      padding: 12px;
      border: none;
    }
    & tr {
      outline 1px solid var(--chakra-colors-chakra-border-color);
    }
    .aliasName {
      max-width: 164px;
      padding: 12px 5px;
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const styledModalContentCheckoutDrawer: ModalContentProps['css'] = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '56px 32px 18px 32px',
  gap: '16px',
};

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ContainerSup = styled('sup')`
  font-family: ${fonts.bodyBold};
  font-size: 14px;
  top: -10px;
  right: 4px;
`;

export const ContainerModalTextDrawerCheckoutDelete = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const ContainerModalPayInCheckout: ModalContentProps['css'] = {
  width: '396px',
  display: 'flex',
  alignItems: 'center',
  padding: '56px 32px 18px 32px',
  gap: '16px',
  textAlign: 'center',
};
