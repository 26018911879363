import { PlacementWithLogical, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

interface TextEllipsisProps {
  text: string;
  placement?: PlacementWithLogical;
  maxWidth?: TextProps['maxWidth'];
  padding?: TextProps['padding'];
  textStyle?: string;
}

function TextEllipsisWithTooltip(props: TextEllipsisProps & TextProps) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const { maxWidth, placement = 'start-start', text, padding, textStyle = 'bodyMd', ...restProps } = props;

  useEffect(() => {
    if (textRef.current) {
      const elem = textRef.current;
      setHasEllipsis(elem.offsetHeight < elem.scrollHeight || elem.offsetWidth < elem.scrollWidth);
    }
  }, [text, maxWidth]);

  return (
    <Tooltip direction='rtl' hasArrow label={text} placement={placement} isDisabled={!hasEllipsis} maxWidth='1000px'>
      <Text
        ref={textRef}
        textStyle={textStyle}
        textAlign='left'
        padding={padding}
        display='block'
        textOverflow='ellipsis'
        maxWidth={maxWidth || '145px'}
        overflow='hidden'
        whiteSpace='nowrap'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      >
        {text}
      </Text>
    </Tooltip>
  );
}

export default TextEllipsisWithTooltip;
