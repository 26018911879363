import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

export const GlobalContain = styled.div`
  margin-top: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .card {
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.light};
    border-radius: 6px;
    padding: 12px;
    width: 100%;
    margin-bottom: 12px;
    min-height: 110px;
    :hover {
      background-color: ${colors.primaryBlue};
      .arrow {
        background-color: ${colors.primaryHover};
      }
    }
    @media (min-width: 980px) and (max-width: 1064px) {
      height: 196px;
    }
    @media (min-width: 1065px) and (max-width: 1222px) {
      height: 170px;
    }
    @media (min-width: 1223px) and (max-width: 1490px) {
      height: 150px;
    }
  }

  .insider {
    width: 100%;
    padding: 0px 5px 0px 12px;
  }

  .pad7 {
    padding: 7px 0px 7px 0px;
    color: ${colors.blue};
  }
`;
export const columnOne = {
  columnCount: 1,
  width: '50%',
};

export const columnPair = {
  columnCount: 2,
  columnGap: '24px',
};

export const columnOdd = {
  columnCount: 3,
  columnGap: '24px',
};

export const GoButton = styled.button`
  display: flex;
  align-self: center;
  padding: 8px;
  border-radius: 8px;
`;

export const Wizard = styled.div`
  .directAccess {
    margin-top: 30px;
  }
  padding: 5px;
`;

export default { Container };
