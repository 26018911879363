import { FormControl } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import AppContext from '../../context/Provider';
import { ISelectComponent } from '../../interfaces/ISelectComponent';
import { DataFilterInformation } from '../../text/GeneralText';
import Styled, { StyledError } from './styled';

function SelectComponent({ valueOptions, name, handle, placeholder, handleBlur, handleFocus }: ISelectComponent) {
  const { isBillsError, modalitiesForm } = useContext(AppContext);
  const initValue = modalitiesForm.fields[name] !== undefined ? modalitiesForm.fields[name] : null;

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(initValue);
  const [dataFilter, setDataFilter] = useState([{ value: '', label: '' }]);

  useEffect(() => {
    setDataFilter(
      valueOptions.map(item => {
        return { value: item.code, label: item.description };
      }),
    );
  }, [valueOptions]);
  const onBlur = () => {
    handleBlur(value, name, placeholder);
  };

  const onFocus = () => {
    handleFocus(value, name, placeholder);
  };
  return (
    <FormControl>
      <Select
        name={name}
        placeholder={placeholder}
        styles={isBillsError ? StyledError : Styled}
        inputValue={inputValue}
        onInputChange={v => setInputValue(v)}
        onChange={val => {
          setValue(val);
          handle(val, name);
        }}
        value={value}
        options={dataFilter}
        onBlur={onBlur}
        onFocus={onFocus}
        noOptionsMessage={() => {
          return DataFilterInformation.noInformation;
        }}
      />
    </FormControl>
  );
}

export default SelectComponent;
