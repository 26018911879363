import { Box, Grid, GridItem, Skeleton, Stack } from '@chakra-ui/react';
import { Fragment, memo } from 'react';

function SkeletonReceipts({ num = 4 }: { num: number }) {
  const skeletonGridItem = Array.from({ length: num }, (_, index) => index);
  return (
    <>
      <Stack>
        <Skeleton rounded='md' color='#E3E2E6' height='27.352px' />
      </Stack>
      <Box marginTop='4px'>
        <Grid h='30px' templateColumns='repeat(27, 1fr)' gap={1}>
          {skeletonGridItem.map(skeleton => (
            <Fragment key={skeleton}>
              <GridItem colStart={1} colEnd={2}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={2} colEnd={7}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={7} colEnd={14}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={14} colEnd={19}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={19} colEnd={22}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={22} colEnd={27}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
              <GridItem colStart={27} colEnd={28}>
                <Skeleton rounded='md' height='28px' />
              </GridItem>
            </Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default memo(SkeletonReceipts);
