/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-unresolved

import { Button, Grid, GridItem } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { TablePaginationComponent } from '@wow/table-pagination';
import { OpenMode, pushAnalyticsEvent } from '@wow/utils';
import JSZip from 'jszip';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { IVouchers, Pagination as PaginationInterfaces } from '../../interfaces/IAvailableVouchers';
import { DownloadVoucher } from '../../interfaces/IDownloadVoucher';
import { getCompaniesReceipt, getVoucher } from '../../services/api';
import { AccountSelectionFilterTexts, Buttons, PaymentReceiptsText, SnackbarTexts } from '../../text/GeneralText';
import { dateValueToString, dateValueToStringYYYYMMHHSS } from '../../utils/converters';
import createPdf from '../../utils/createPDF';
import { getJumpValue } from '../../utils/onJumpPagination';
import CommonPage from '../CommonPage';
import EmptyStateScreen from '../EmptyStateScreen';
import useCreateNewPayment from '../EmptyStateScreen/useCreateNewPayment';
import WithFilter from '../filter/WithFilter';
import TableComponent from '../TableComponent';
import getPaymentReceiptsColumns from './components/PaymentReceiptsCConfig';
import SkeletonReceipts from './components/SkeletonReceipts';
import { Container, Link } from './styled';

const urlIssuedTransfers = OpenMode({
  module: 'urlComplete',
  urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_REPORTES_TEF}`,
});

const NO_FILTERS = 0;
function PaymentReceipts() {
  const [pagination, setPagination] = useState<PaginationInterfaces>({ total_elements: 0, limit: 0, total_page: 0 });
  const [data, setData] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [size, setSize] = useState<number>(15);
  const [sortProp, setSortProp] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('Desc');
  const [filters, setFilters] = useState([]);
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const [temporalVoucher, setTemporalVoucher] = useState(null);
  const [handleGoNewPayment] = useCreateNewPayment();
  const { permissions, clearFilterValues, handleFilters, filterValues, setRowsSelected, rowsSelected } = useContext(AppContext);
  const { ToastUtilities } = useChakraToast();
  const [deleteAllFilters, setDeleteAllFilters] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const isAvailableDeleteAllFilters = filterValues && Object.values(filterValues).flat().length === NO_FILTERS;
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const [lastFiltersApplied, setLastFiltersApplied] = useState([]);
  useEffect(() => {
    getCompaniesReceipt()
      .then(res => {
        const filtersMapper = res.data.companies.map(company => ({
          code: company.name,
          codeName: company.name,
        }));
        setFilters(filtersMapper);
        setIsFilterAvailable(filtersMapper.length > 1);
      })
      .catch(() => {
        setIsFilterAvailable(false);
      });
  }, []);

  //! This is a patch for the table component component 17/12/2024 removed when DS have the fix for this
  useEffect(() => {
    const selectorCenterElement = document.querySelector('[data-testid="select"]');
    if (selectorCenterElement) {
      if (pagination.total_elements <= 15) {
        selectorCenterElement.setAttribute('disabled', '');
      } else {
        selectorCenterElement.removeAttribute('disabled');
      }
    }
  }, [page, pagination.total_elements]);

  useEffect(() => {
    if (!filtersApplied) {
      getReceipts();
    }
    setFiltersApplied(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, sortProp, sortOrder]);

  const deleteFilters = () => {
    setPage(0);
    setSize(15);
    getReceipts([]);
    setFiltersApplied(true);
    setDeleteAllFilters(true);
    clearFilterValues();
  };
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Comprobantes',
    });
  }, []);

  useEffect(() => {
    if (deleteAllFilters) {
      setDeleteAllFilters(false);
    }
  }, [deleteAllFilters]);

  const getReceipts = (filter?) => {
    setIsLoading(true);
    getVoucher(
      {
        'company-name': Array.isArray(filter) ? [...filter] : [],
        'date-since': '2021-01-01',
        'date-until': dateValueToString(new Date()),
        sort: `${sortProp}${sortOrder}`,
        limit: size,
        offset: page * size,
      },
      // TODO take this params in the other task of payments i let this for reference
      //   {
      //   companyService: filter ? [...filter] : [],
      //   dateFrom: '2021-01-01',
      //   dateTo: dateValueToString(new Date()),
      //   page: Number(page),
      //   size: Number(size),
      //   sortProperty: sortProp,
      //   sortDirection: sortOrder,
      // }
    )
      .then(res => {
        setData(res.data.vouchers);
        setPagination(res.data.page);
        setIsLoading(false);
        setHasFetchedData(true);
        setHasErrorConnection(false);
      })
      .catch(() => {
        setHasErrorConnection(true);
        setIsLoading(false);
        setHasFetchedData(true);
      });
  };

  const handleOnClick = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion Comprobantes',
      item_id: 'Ver transferencias emitidas',
    });
  };

  const setSort = (sortPropCol: string, sortOrderCol: string) => {
    setSortProp(sortPropCol);
    setSortOrder(sortOrderCol);
  };

  const handlerDownloadZip = async () => {
    const listPayments = rowsSelected.map(row => row.original);
    const vouchersList = listPayments.map(listPayment => listPayment.voucher);
    const zip = new JSZip();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < vouchersList.length; i++) {
      const voucherDate = dateValueToStringYYYYMMHHSS(listPayments[i].updated_date);
      const pdfBytes = await createPdf(true, vouchersList[i], `${listPayments[i].company_name}`, null);
      zip.file(`${listPayments[i].company_name}_${voucherDate}.pdf`, pdfBytes);
    }

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      const url = URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'comprobante de pagos.zip';
      link.click();
    } catch {
      renderToastError();
    }
  };

  const handlerDlReceipt = async (voucher: DownloadVoucher) => {
    try {
      await createPdf(false, voucher.voucher, voucher.company_name, voucher.updated_date);
    } catch {
      setTemporalVoucher(voucher);
      renderToastError();
    }
  };

  const renderToastError = () =>
    ToastUtilities.error({
      description: SnackbarTexts.downloadFailed,
      id: 'toast-id',
      toastOptions: {
        ctaLabel: Buttons.retryConnection,
        ctaOnClick: () => {
          if (temporalVoucher) {
            handlerDlReceipt(temporalVoucher);
          }
          handlerDownloadZip();
        },
      },
    });

  const handleFilterChange = filter => {
    setFiltersApplied(true);
    setLastFiltersApplied(filter);
    setPage(0);
    setSize(15);
    getReceipts(filter);
  };
  return (
    <Container>
      <CommonPage
        headline={PaymentReceiptsText.title}
        text={
          <>
            {PaymentReceiptsText.subtitle}{' '}
            <Link target='_blank' href={urlIssuedTransfers} rel='noopener noreferrer' onClick={handleOnClick}>
              {PaymentReceiptsText.linkTitle}
            </Link>
            .
          </>
        }
      />
      <>
        <Grid h='auto' templateColumns='repeat(1, 1fr)' gap={4}>
          <GridItem colStart={1} colEnd={3}>
            <Grid
              borderWidth='1px'
              borderColor='rgb(190, 198, 220)'
              outline='none'
              padding='12px'
              marginBottom='36px'
              minHeight='64px'
              borderRadius='8px'
              h='auto'
              templateColumns='repeat(6, 1fr)'
              gap={2}
            >
              <WithFilter
                data={filters}
                id='filter'
                filterLabel={PaymentReceiptsText.FilterCompany}
                handleSendInfo={filter => {
                  handleFilters('filter', filter, values => {
                    handleFilterChange(values);
                  });
                }}
                titleMenu={PaymentReceiptsText.subtitleMenuFilter}
                resetInitialState={deleteAllFilters}
                filterTitle={PaymentReceiptsText.filter}
                isDisabled={!isFilterAvailable}
              />

              <GridItem colStart={8} colEnd={9}>
                <Button isDisabled={isAvailableDeleteAllFilters} onClick={deleteFilters} variant='primary-text'>
                  {AccountSelectionFilterTexts.generalDeleteAction}
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        {isLoading ? (
          <SkeletonReceipts num={4} />
        ) : (
          <>
            <GridItem colSpan={12}>
              {!hasErrorConnection ? (
                <>
                  {isLoading || !hasFetchedData ? null : (
                    <TableComponent<IVouchers[]>
                      inputData={data.filter(Boolean)}
                      columnsData={getPaymentReceiptsColumns(setSort, handlerDlReceipt) as ColumnDef<RowData>[]}
                      setSelectedRows={setRowsSelected}
                      emptyContent={
                        data?.length === 0 ? (
                          <EmptyStateScreen
                            page='PAYMENT_RECEIPTS'
                            variant='NO_DATA'
                            hasButton={permissions?.generacionPreconfeccionPropia}
                            onRetry={handleGoNewPayment}
                          />
                        ) : null
                      }
                    />
                  )}
                </>
              ) : (
                <EmptyStateScreen page='PAYMENT_RECEIPTS' variant='ERROR_CONN' hasButton onRetry={() => getReceipts(lastFiltersApplied)} />
              )}
              {!isLoading && hasFetchedData && data?.length > 0 ? (
                <TablePaginationComponent
                  totalPages={pagination.total_page || 1}
                  currentIndex={page}
                  totalRecords={pagination.total_elements}
                  perPage={[15, 30, 45]}
                  disabledPerPage={pagination.total_elements <= 15}
                  currentPerPage={pagination.limit}
                  onNext={() => setPage(page + 1)}
                  onPrev={() => setPage(page - 1)}
                  onFirst={() => setPage(0)}
                  onLast={() => setPage(pagination.total_page - 1)}
                  onJump={value => setPage(Number(getJumpValue(value)))}
                  onPerPageChange={value => {
                    setSize(Number(value));
                    setPage(0);
                  }}
                />
              ) : null}
            </GridItem>{' '}
          </>
        )}
      </>
    </Container>
  );
}

export default PaymentReceipts;
