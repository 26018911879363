/* eslint-disable consistent-return */
import { Avatar, Box, Text } from '@chakra-ui/react';

import { Account } from '../../../interfaces/ITableCheckout';
import { ColumnsConfigTable } from '../../../interfaces/ITableComponent';
import { colors, fontWeight } from '../../../styled/generalStyles';
import { CheckoutTexts } from '../../../text/GeneralText';
import { transformNumberToCurrency } from '../../../utils/converters';
import RowSelectCheckbox from '../../Checkbox/RowSelectCheckbox';

const isPositive = (number: number): boolean => number > 0;

const getBalanceText = (isBalanceServiceUnavalaible: boolean, balance: number | null) => {
  if (isBalanceServiceUnavalaible) {
    return (
      <Text size='13px' textColor={colors.textgray} fontWeight={fontWeight.fw4x}>
        {CheckoutTexts.isBalanceServiceUnavalaibleTrue}
      </Text>
    );
  }
  if (!isBalanceServiceUnavalaible) {
    if (balance) {
      return (
        <Text size='14px' textColor={isPositive(balance) ? colors.textgray : colors.error110} textStyle='labelLgBold'>
          {transformNumberToCurrency(balance)}
        </Text>
      );
    }
    return (
      <Text size='13px' textColor={colors.textgray} fontWeight={fontWeight.fw4x}>
        {CheckoutTexts.isBalanceServiceUnavalaibleFalse}
      </Text>
    );
  }
};

export default (isBalanceServiceUnavalaible: boolean): ColumnsConfigTable<Account>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <RowSelectCheckbox
        isRadioButtonActive
        {...{
          checked: table.getIsAllRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
          isSomeSelected: table.getIsSomeRowsSelected(),

          id: 'head',
        }}
      />
    ),
    cell: ({ row }) => {
      return (
        <RowSelectCheckbox
          isRadioButtonActive
          {...{
            checked: row.getIsSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      );
    },
  },
  {
    accessorKey: 'bankLogo',
    cell: info => {
      const logo = info.getValue() as string;
      const img = `${process.env.URL_BANK_LOGOS}${logo}`;
      return <Avatar src={img} size='md' />;
    },
    classNameCell: 'margin-avatar',
  },
  {
    accessorKey: 'bankName',
    cell: info => {
      const name = info.getValue() as string;
      return <Text textStyle='h6'>{name}</Text>;
    },
    classNameCell: 'bank-name-cell',
  },
  {
    accessorKey: 'bankcode',
    cell: ({ row }) => {
      const data = row.original;
      return (
        <Box display='flex' justifyContent='start' flexDirection='column' alignItems='start'>
          <Text textStyle='labelLgBold' align='start'>{`${data.type} | ${data.currency} | ${data.number}`}</Text>
          <Text textStyle='bodyMd'>{data.isOwnAccount ? CheckoutTexts.ownAccount : CheckoutTexts.linkedAccount}</Text>
        </Box>
      );
    },
  },
  {
    accessorKey: 'balance',
    cell: info => {
      const balance = info.getValue() as number | null;
      const balanceText = getBalanceText(isBalanceServiceUnavalaible, balance);
      return <div>{balanceText}</div>;
    },
  },
];
