import { Button, MenuGroup } from '@chakra-ui/react';

import { FooterFilterMenuProps } from '../../../interfaces/IAccountSelectionFilter';
import { AccountSelectionFilterTexts } from '../../../text/GeneralText';

function FooterFilterMenu({ handleSendInfo, deleteSelectedItems, isDisabledButtonDelete, inPromise }: FooterFilterMenuProps) {
  return (
    <MenuGroup margin='10px'>
      <Button width='100%' margin='10px' size='md' variant='primary-text' onClick={deleteSelectedItems} isDisabled={isDisabledButtonDelete}>
        {AccountSelectionFilterTexts.deleteButton}
      </Button>
      <Button width='100%' margin='10px' size='md' onClick={handleSendInfo} isDisabled={inPromise}>
        {AccountSelectionFilterTexts.applyButton}
      </Button>
    </MenuGroup>
  );
}
export default FooterFilterMenu;
