import styled from 'styled-components';

const Container = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .head {
    display: flex;
  }
  .headline {
    > p {
      line-height: 24px;
      margin-top: 12px;
    }
  }
  .subheader {
    > p {
      line-height: 36px;
    }
  }
  .container-text > p {
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

export default Container;
