import { colors, fonts, fontSize } from '../../styled/generalStyles';

const Styled = {
  control: styles => ({
    ...styles,
    borderRadius: '4px',
    height: '40px',
    borderColor: colors.light,
    ':hover': {
      borderColor: `${colors.light} !important`,
    },
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  dropdownIndicator: styles => ({
    ...styles,
    color: colors.lightgray,
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.body,
    fontSize: fontSize.semiSmall,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  }),

  option: (base, { isFocused }) => {
    return {
      ...base,
      backgroundColor: isFocused ? colors.gray : colors.white,
      height: '48px',
      fontFamily: fonts.body,
      fontSize: fontSize.semiSmall,
      display: 'flex',
      alignItems: 'center',
      color: colors.textgray,
      ':active': {
        ...base[':active'],
        backgroundColor: colors.backGray,
      },
    };
  },

  menu: styles => ({
    ...styles,
    borderRadius: '0px',
    padding: '8px 12px 12px 16px',
    position: 'absolute',
    overflow: 'auto',
    maxHeight: '125px !important',
  }),
};

export const StyledError = {
  control: styles => ({
    ...styles,
    borderRadius: '4px',
    height: '40px',
    border: '2px solid',
    borderColor: `${colors.red} !important`,
    ':hover': {
      borderColor: `${colors.red} !important`,
    },
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  dropdownIndicator: styles => ({
    ...styles,
    color: colors.lightgray,
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.body,
    fontSize: fontSize.semiSmall,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  }),

  menu: styles => ({
    ...styles,
    borderRadius: '0px',
    padding: '8px 12px 12px 16px',
  }),
};

export default Styled;
