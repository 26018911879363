import React from 'react';

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='120' height='120' fill='none' viewBox='0 0 120 120'>
      <path fill='#EBF0FF' d='M45.882 50.622l19.08-19.08 5.502 5.502-19.08 19.08-5.502-5.502z' />
      <path
        fill='#2172DC'
        d='M64.956 33.663l3.38 3.381L51.382 54 48 50.619l16.956-16.956zm0-4.242L43.758 50.619l7.623 7.623 21.198-21.198-7.623-7.623z'
      />
      <path fill='#EBF0FF' d='M63.882 68.622l19.08-19.08 5.502 5.502-19.08 19.08-5.502-5.502z' />
      <path
        fill='#2172DC'
        d='M82.956 51.663l3.38 3.381L69.382 72 66 68.619l16.956-16.956zm0-4.242L61.758 68.619l7.623 7.623 21.198-21.198-7.623-7.623z'
      />
      <path
        fill='#AAC7FF'
        d='M61.314 23.586L75.78 9.12a14.207 14.207 0 0110.104-4.185c3.816 0 7.404 1.488 10.104 4.185l4.002 4.005L108 5.121 114.879 12l-8.004 8.004 4.005 4.002a14.312 14.312 0 010 20.211L96.414 58.683l-35.1-35.097z'
      />
      <path
        fill='#2172DC'
        d='M85.887 6.435c3.417 0 6.627 1.329 9.045 3.747l5.064 5.064L108 7.242 112.758 12l-8.004 8.004 5.064 5.064a12.715 12.715 0 013.747 9.045 12.703 12.703 0 01-3.747 9.045L96.414 56.562 63.438 23.586l13.404-13.404a12.71 12.71 0 019.045-3.747zM108 3l-8.004 8.004-2.946-2.943a15.742 15.742 0 00-11.166-4.626 15.742 15.742 0 00-11.166 4.626L59.193 23.586 96.41 60.804l15.525-15.525a15.785 15.785 0 003.423-17.21 15.785 15.785 0 00-3.423-5.122l-2.943-2.943L117 12l-9-9z'
      />
      <path
        fill='#AAC7FF'
        d='M49.02 29.538l6.198-6.201c1.932-1.932 4.404-3.039 6.783-3.039 1.686 0 3.132.561 4.19 1.617l31.89 31.89c2.632 2.634 1.996 7.557-1.424 10.974l-6.201 6.198L49.02 29.538z'
      />
      <path
        fill='#2172DC'
        d='M62.004 21.798c.89 0 2.16.204 3.132 1.176l31.89 31.89c2.013 2.013 1.362 6.069-1.425 8.853l-5.14 5.139-39.32-39.318 5.139-5.139c1.629-1.629 3.768-2.601 5.724-2.601zm0-3c-2.661 0-5.556 1.191-7.845 3.48l-7.26 7.26 43.563 43.563 7.26-7.26c4.011-4.011 4.647-9.873 1.425-13.095l-31.89-31.89c-1.383-1.386-3.255-2.058-5.253-2.058z'
      />
      <path
        fill='#AAC7FF'
        d='M33.534 115.644a14.223 14.223 0 01-5.471-1.081 14.226 14.226 0 01-4.633-3.104l-4.002-4.005L12 114.879 5.12 108l7.426-7.425L8.54 96.57a14.31 14.31 0 010-20.211l14.466-14.466L58.104 96.99l-14.466 14.466a14.197 14.197 0 01-10.104 4.188z'
      />
      <path
        fill='#2172DC'
        d='M23.007 64.017l32.976 32.976-13.404 13.404a12.71 12.71 0 01-9.045 3.747 12.703 12.703 0 01-9.045-3.747l-5.064-5.064L12 112.758 7.242 108l7.425-7.425-5.064-5.064a12.81 12.81 0 010-18.09l13.404-13.404zm0-4.242L7.482 75.3a15.79 15.79 0 000 22.332l2.943 2.943L3 108l9 9 7.425-7.425 2.943 2.943a15.742 15.742 0 0011.166 4.626c4.04 0 8.082-1.542 11.166-4.626l15.525-15.525-37.218-37.218z'
      />
      <path
        fill='#AAC7FF'
        d='M57.834 100.53a6.46 6.46 0 01-4.608-1.866l-31.89-31.89c-2.631-2.631-2.454-7.095.399-9.948l5.175-5.175L68.352 93.09l-5.175 5.175c-1.44 1.44-3.387 2.265-5.343 2.265z'
      />
      <path
        fill='#2172DC'
        d='M26.907 53.772l39.32 39.321-4.112 4.113a6.123 6.123 0 01-4.281 1.824c-1.368 0-2.628-.507-3.55-1.428l-31.89-31.89a4.985 4.985 0 01-1.424-3.732c.05-1.515.696-2.97 1.82-4.095l4.117-4.113zm0-4.242l-6.234 6.234c-3.444 3.444-3.621 8.847-.4 12.072l31.89 31.89a7.952 7.952 0 005.67 2.304 9.093 9.093 0 006.403-2.703l6.234-6.234L26.907 49.53z'
      />
    </svg>
  );
}

export default Icon;
