export const PaymentReceiptsText = {
  title: 'Comprobantes',
  subtitle: 'Podrás ver y descargar tus comprobantes cuando se ejecuten las transferencias. Conocé el estado de tus pagos desde ',
  linkTitle: 'Transferencias emitidas',
  paymentReceiptsEmpty: 'Todavía no tenés comprobantes',
  paymentReceiptsEmptySub: 'Cuando pagues tus servicios, podrás ver y descargar los comprobantes desde acá.',
  paymentEmptyButton: 'Pagar servicio',
  loading: {
    title: 'Estamos buscando tus comprobantes',
    subTitle: 'Esto puede demorar unos minutos.',
  },
  buttonDownload: 'Descargar comprobantes',
  filter: 'Filtrá por:',
  FilterCompany: 'Empresa',
  subtitleMenuFilter: 'Podés seleccionar más de una',
  MenuCompanyClean: 'Borrar',
  MenuCompanyApply: 'Aplicar',
  buttonRestore: 'Restablecer',
};

export const DataFilterInformation = {
  title: 'Guardá y pagá el servicio',
  subtitle: 'Buscá el servicio',
  description: 'Seleccioná el servicio que querés pagar e ingresá los datos para visualizar tus facturas.',
  filterValue: 'Buscá entre más de 1.000 empresas',
  errorTitle: 'El sistema está inhabilitado para operar',
  errorText: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
  inputError: 'Ingresá una empresa para poder avanzar',
  noInformation: 'No se encontraron resultados',
  placeholder: 'Empresa',
};

export const AlertBannerInformation = {
  default: {
    title: '¿Dónde encuentro la información?',
    description:
      'Encontrá los datos en la factura del servicio. La mayoría de las empresas lo tienen en el margen superior derecho o al dorso de la factura.',
  },
  error: {
    title: 'No encontramos tus facturas',
    description: 'Verificá los datos ingresados antes de reintentar la búsqueda. Tené en cuenta que quizás no tenés facturas disponibles.',
    titleCartFull: 'Carrito lleno',
    descriptionCartFull: (limitBills: number) => `Alcanzaste el máximo de ${limitBills} facturas. Para continuar, modificá tu selección.`,
    descriptionCartFullPay: (limitBills: number) =>
      `Alcanzaste el máximo de ${limitBills} facturas. Pagá el carrito y creá uno nuevo para abonar las facturas que quedaron impagas.`,
    goToCart: 'Ver carrito',
    prepackagingTitle: 'Tenés pagos pendientes',
    prepackagingDescription: 'Debido a un error, algunas facturas continúan impagas. Volvé a intentar el pago.',
    operatibilityTitle: 'Pago de servicios inactivo',
    gireAvailabilityTitle: 'El sistema está inhabilitado para operar',
    gireAvailabilityDescription:
      'No podés pagar tus facturas en este momento, pero ya estamos trabajando para solucionarlo. Volvé a intentar más tarde.',
    gireAvailabilityDescriptionAccountSelection:
      'Estamos trabjando para solucionarlo, pero no podrás realizar ningun pago hasta que se solucione. Intentá más tarde.',
    operatibilityDescription: (start: string, end: string) =>
      `Operá de lunes a viernes de ${start} a ${end} hs. Fuera de ese horario, solo podrás visualizar tus facturas.`,
    errorOnPaymentFromBottomNavTitle: 'No podemos preparar el pago',
    errorOnPaymentFromBottomNavDescription:
      'Agregamos tus facturas al carrito, pero no pudimos avanzar con el pago. Para continuar intentá nuevamente.',
    errorOnSummaryPaymentTitle: 'No podemos avanzar con el pago',
    errorOnSummaryPaymentSubtitle: 'Las facturas tuvieron error, por eso las eliminamos. Verificá el detalle y reintentá el pago.',
  },
};

export const DataSelectInformation = {
  title: 'Elegí una referencia',
  subtitle: 'Seleccioná el dato que vas a usar para identificar tus facturas más rápido.',
  placeholder: 'Identificador del servicio',
};

export const Buttons = {
  seeBills: 'Ver facturas',
  back: 'Volver',
  backToHome: 'Volver a Pagos',
  addService: 'Agregar nuevo servicio',
  addToCart: 'Agregar al carrito',
  pay: 'Pagar ',
  emptyCart: 'Vaciar el carrito',
  preparePayment: 'Preparar pago',
  goToConfection: 'Ir a preconfección',
  retryConnection: 'Reintentar',
};

export const FormValidation = {
  text1: 'Este dato es obligatorio',
};

export const FormRequestError = {
  text: 'El dato ingresado es incorrecto',
};

export const ModalitiesFormInformation = {
  title: 'Completá los campos',
  subtitile: 'Encontrá los datos en la factura del servicio, generalmente ubicados en el margen superior derecho o en el dorso.',
};
export const BillsTableInformation = {
  title: 'Guardá y pagá el servicio',
  titleMyBills: 'Tus facturas de ',
  subtitle: 'Seleccioná las facturas que querés pagar y agregalas al carrito.',
  text: 'Total a pagar',
  currency: '$ ',
};

export const BillsText = {
  billDetail: 'Detalle de factura',
};

export const InputAmount = {
  montoMin: 'Monto mín:',
  montoMax: 'Monto máx:',
  addAmount: 'Ingresá un monto',
};

export const ColumnsConfigText = {
  sinVencimiento: '-',
  vencimiento: 'Vencimiento',
  importe: 'Importe',
};

export const AliasModalText = {
  createAlias: 'Guardar servicio favorito',
  title: 'Nombrá el servicio y guardalo como favorito',
  titleEdit: 'Editá el nombre de tu servicio',
  subtitle: 'Encontrá las facturas en la página de Inicio de forma más rápida y sin tener que crear un pago.',
  successMessage1: '¡Listo! Guardamos',
  successMesage2: 'como favorito.',
  errorMessage1: 'No pudimos guardar',
  errorMessage2: 'como favorito. Intentalo nuevamente.',
  creationDateField: 'Fecha de adhesión:',
  inputLabelCreate: 'Asignale un nombre a este servicio',
  LabelCreate: 'Nombre',
  inputLabelEdit: 'Editá el nombre',
  inputWarning: 'Podés completar hasta 50 caracteres alfanuméricos',
  inputError: 'Completá hasta 50 caracteres alfanuméricos',
  cancelButton: 'Cancelar',
  saveAliasButton: 'Guardar',
};

export const StatusBadgeTexts = {
  mensajeFacturaYaEnCarrito: 'Esta factura ya está en el “carrito”. Solo tenés que pagarla.',
  mensajeFacturaCuentaConPagoRealizado: 'Esta factura ya tiene un pago realizado.',
  mensajeFacturaSeEncuentraEnCircuitoTransferencia: 'Esta factura ya está en el circuito de transferencias.',
  badgeSinEstado: 'sin estado',
  badgePorVencer: 'por vencer',
};

export const TooltipTexts = {
  detalle: 'Ver detalle',
  codigoDeFactura: 'La referencia de tu factura puede contener números o letras y números.',
  checkboxDisabledBills: (start: string, end: string) => `Pagá tus facturas de lunes a viernes de ${start} a ${end} horas`,
  checkboxSelected: 'Deseleccionar',
  checkboxUnselected: 'Seleccionar',
  buttonAddCart: (start: string, end: string) => `Hacelo de lunes a viernes de ${start} a ${end} horas`,
  buttonAddCartDisabled: 'Seleccioná al menos una factura de la tabla para habilitar la acción',
  iconAddCart: (start: string, end: string) => `Llená el carrito de lunes a viernes de ${start} a ${end} horas`,
  withoutPermissions: 'Necesitás los permisos correspondientes para operar',
  download: 'Descargar',
  gireFall: `El sistema está inhabilitado para operar. Buscá el servicio que querés pagar en otro momento`,
  gireFallTableButtons: `El sistema esta inhabilitado para operar. Pagá tus facturas en otro momento.`,
  gireFallPayText: `Intentá el pago en otro momento. El sistema está inhabilitado para operar.`,
};

export default DataFilterInformation;

export const AliasDeleteModalText = {
  title: '¿Querés eliminar el servicio como favorito?',
  subtitle: 'Esta acción no se puede deshacer. Una vez eliminado, tendrás que volver a crear un pago.',
  successMessage1: 'Eliminaste a',
  successMesage2: 'de tus favoritos',
  errorMessage1: 'No pudimos eliminar a',
  errorMessage2: 'de tus favoritos. Intentalo nuevamente.',
  cancelButton: 'Cancelar',
  deleteAliasButton: 'Sí, eliminar',
};

export const DataHomeInformation = {
  title: 'Pagos favoritos',
  subtitle: 'Encontrá los servicios que guardaste como favoritos y asignaste un nombre.',
  favoriteEmpty: 'Nada por aquí',
  subFavoriteEmpty: 'Guardá tus servicios como favoritos y encontrá las facturas próximas a vencer.',
};

export const DataHeaderHome = {
  title: 'Pagos',
  createPaymentButton: 'Crear Pago',
};

export const DataExpirationHome = {
  title: 'Próximos vencimientos de tus servicios',
  subtitle: 'Encontrá las facturas de tus servicios favoritos.',
  filter: 'Filtrá por:',
  delete: 'Borrar filtros',
  company: 'Empresa',
  date: 'Fecha inicio',
  displayPage: 'No podemos mostrar la página',
  licence: 'Para visualizar esta sección, pedí a un administrador general que te de los permisos correspondientes.',
  noDataTitle: 'Nada por aquí',
  noDataSubtitle: 'Guardá tus servicios como favoritos y encontrá las facturas próximas a vencer.',
  noDataButtonLink: 'Agregar servicio favorito',
};

export const AccessHome = {
  titleFav: 'Servicios favoritos',
  descriptionFav: 'Encontrá los servicios que pagas con frecuencia.',
  titlePre: 'Preconfección BtoB propias',
  descriptionPre: 'Terminá de confeccionar todos tus pagos de servicios.',
  titleCom: 'Comprobantes',
  descriptionCom: 'Encontrá los comprobantes de pagos de servicios y descargalos fácilmente.',
  titleAuth: 'Autorizaciones',
  descriptionAuth: 'Autorizá tus pagos pendientes.',
};

export const DataCartInformation = {
  title: 'Tu carrito de pagos',
  subtitle: 'Tu carrito está vacío',
  emptyDescription: 'Agregá facturas desde <strong>Próximos vencimientos</strong> o <strong>Buscar servicio.</strong>',
  mainDescription: (limitBills: number, limitTime: string) =>
    `Podés pagar hasta ${limitBills} facturas antes de las ${limitTime} horas. Pasado ese horario, se eliminan del carrito y tendrás que cargarlas al día siguiente.`,
  createPaymentLink: 'Agregar facturas',
  currentPage: 'Carrito',
  previousPage: 'Pagos',
  totalBills: 'facturas',
  totalBill: 'factura',
  totalPay: 'Total a pagar: ',
};

export const noData = {
  title: 'Nada por aquí',
};

export const ConnectionError = {
  title: 'La información no está disponible',
  subtitle: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
};

export const SystemUnavailable = {
  title: 'El sistema está inhabilitado para operar',
  subtitle: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
};

export const AccordionHeader = {
  PaymentSubtitle: 'A pagar',
  facturas: (totalBill: number) => `${totalBill === 1 ? 'factura' : 'facturas'}`,
};

export const AccordionBody = {
  Error: 'Error al cargar la tabla',
};

export const EmptyCartModal = {
  title: '¿Querés vaciar el carrito?',
  subTitle: 'Esta acción no se puede deshacer porque vas a eliminar todas tus facturas.',
  cancelButton: 'Cancelar',
  aceptButton: 'Si, vaciar',
};

export const ConfirmationModalTexts = {
  delete: {
    type: 'delete',
    bill: {
      title: '¿Querés eliminar la factura de este carrito?',
      title2: `¿Querés eliminar la factura?`,
      subTitle: 'Para pagarla más tarde tenés que cargarla desde Crear pago > Selección de servicio.',
      subTitleCompany: (companyName: string, aliasName = ' ') => `Eliminarás la factura de ${companyName}  ${aliasName}. `,
      subtitle3: 'Podés volver a agregarla más adelante.',
      subtitle4: 'Podrás pagarla más tarde desde',
      subtitle5: 'Próximos vencimientos de tus servicios.',
    },
    company: {
      title: '¿Querés eliminar la empresa del listado?',
      subTitle: 'Podés volver a agregar las facturas de dicha empresa más adelante.',
    },
    cancelButton: 'Cancelar',
    aceptButton: 'Sí, eliminar',
  },
  empty: {
    type: 'empty',
    cart: {
      title: '¿Querés vaciar el carrito?',
      subTitle: 'Esta acción no se puede deshacer porque vas a eliminar todas tus facturas.',
    },
    cancelButton: 'Cancelar',
    aceptButton: 'Sí, vaciar',
  },
};

export const ProofPayment = {
  columns: {
    company: 'Empresa/Servicio',
    assignedName: 'Nombre asignado',
    billCode: 'Código de factura',
    paymentDate: 'Fecha de pago',
    ammountPaid: 'Pagado',
    download: 'Descargar',
  },
};

export const PreparePaymentText = {
  title: 'Estamos procesando tu pago',
  subtitle: 'Esto puede demorar unos minutos. No cierres la pantalla hasta que se termine el proceso.',
};

export const InformationModalTxt = {
  titleButtonAff: 'Continuar',
  titleButtonDeleteInvalidForPay: 'Sí, eliminar',
  titleButtonSeeBills: 'Ver facturas',
};
export const ErrorPreparePay = {
  title: 'Facturas impagas',
  text: (companies: string) =>
    `Algunas facturas de ${companies} no se pueden pagar ahora y las eliminamos para evitar inconvenientes. Podés pagar el resto de tu carrito desde acá.`,
};

export const InvalidForPay = {
  title: 'Eliminá facturas pagadas o preconfeccionadas',
  text: (companies: string, switchWord: boolean) =>
    `${companies} ${
      switchWord ? 'tienen' : 'tiene'
    } facturas pagadas o preconfeccionadas. Borralas desde el listado de facturas y evitá duplicar la información.`,
};

export const SnackbarTexts = {
  deleteCompanySuccess: (company: string) => `Eliminaste a ${company} de tu carrito.`,
  deleteCompanyError: (company: string) => `No podemos eliminar a ${company} de tu carrito. Intentá de nuevo más tarde.`,
  deleteBillSuccess: (bill: string) => `Eliminaste a la factura de ${bill} de tu carrito.`,
  deleteBillError: (bill: string) => `No podemos eliminar a la factura de ${bill} de tu carrito. Intentá de nuevo más tarde.`,
  editAliasSuccess: (company: string, alias: string) => `Editaste a ${company} - ${alias} con éxito.`,
  editAliasError: (company: string, alias: string) => `No podemos editar a ${company} - ${alias} con éxito.`,
  deleteAliasSucess: (company: string, alias: string) => `Eliminaste a ${company} - ${alias} de tus favoritos.`,
  deleteAliasError: (company: string, alias: string) =>
    `No pudimos eliminar a ${company} - ${alias} de tus favoritos. Intentalo nuevamente.`,
  createAliasSucess: (company: string, alias: string) => `¡Listo! Guardamos ${company} - ${alias} como favorito.`,
  createAliasError: (company: string) => `No pudimos guardar ${company} como favorito. Intentalo nuevamente.`,
  buttonRetry: 'Reintentar',
  downloadFailed: 'No podemos realizar la descarga.',
  createDefaultAlias: (company: string) => `Guardamos ${company} como favorito. Podés ver las facturas en Próximos vencimientos.`,
  createDefaultAliasError: (company: string) =>
    `Intentamos guardar ${company} como favorito pero no pudimos. Continuá el pago e intentalo más tarde.`,
};

export const WizardHome = {
  titleInitialZero: 'Potenciamos tu experiencia',
  descTitlePay: 'Renovamos la sección de <strong>Pagos</strong> con nuevas funcionalidades para que agilices tu operatoria.',
  titleSearchBillStep: 'Encontrá tus servicios',
  descSearch: 'Buscá los servicios que pagás con frecuencia y guardalos como favoritos.',
  titleExpirationCalendarStep: '¡Visualizá tus vencimientos!',
  descExpiration: 'Encontrá información centralizada de las facturas de tus servicios favoritos y pagalas desde acá. ',
  titleShortcutStep: 'Accesos directos',
  descShorcut: 'Usá nuestros atajos para una gestión de pagos de servicios más ágil. Para otras opciones, accedé al menú.',
  titlePreparePayment: '¡Pagá más rápido!',
  descriptionPreparePayment: 'Seleccioná tu cuenta bancaria y pagá tus facturas más fácil, ¡todo desde un solo lugar!',
};
export const IndexWizardSteps = {
  initialStep: 0,
  stepOne: 1,
  stepTwo: 2,
  stepThree: 3,
  stepOneFour: 4,
};
export const SummaryPaymentsTexts = {
  title1: 'Estado de tus pagos',
  subHeader1: 'Visualizá un resumen de tus pagos y revisá el detalle de cada factura.',
  badgeSuccess: 'Éxito',
  titleSuccess: 'Preparaste tus facturas con éxito',
  subtitleSuccess: 'Terminá de confeccionar todos tus pagos.',
  goToConfection: 'Ir a preconfección',
  goToPayments: 'Ir a Pagos',
  badgeError: 'Error',
  titlePreconfection: 'Algunas facturas quedaron sin preparar',
  subtitlePreconfection: 'Volvé al carrito para continuar el proceso.',
  goToCart: 'Ir al carrito',
  titlePrepare: 'Eliminamos facturas por un error en el sistema',
  subtitlePrepare: 'Volvé a cargar las facturas en el carrito.',
  badgeInfo: 'Aviso',
  titleInvalidForPay: 'Eliminamos facturas pagadas o preconfeccionadas',
  subtitleInvalidForPay: 'Alguien de tu organización avanzó con el pago.',
  summaryPaymentsEmpty: 'Nada por aquí',
  summaryPaymentsEmptySub: 'La información ya no está disponible. Para continuar, volvé a Pagos.',
  titleSuccess2: (length: number) => (length > 1 ? `Preparaste ${length} facturas con éxito` : `Preparaste ${length} factura con éxito`),
  subtitle1: 'Si tenés permisos, autorizá. De lo contrario, esperá a que otra persona apruebe.',
  titleError1: (length: number) => (length > 1 ? `[${length}] facturas no se prepararon` : `[${length}] factura no se preparó`),
  subtitleError1: 'Reintentá el pago desde Próximos vencimientos o Buscar servicio.',
  title2: (length: number) =>
    length > 1
      ? `${length} facturas quedaron en el carrito. Reintentá el pago desde ahí.`
      : `${length} factura quedo en el carrito. Reintentá el pago desde ahí.`,
  title3: (length: number) =>
    length > 1
      ? `${length} facturas no pudieron ser procesadas. Reintentá el pago en otro momento.`
      : `${length} factura no pudo ser procesada. Reintentá el pago en otro momento.`,
  titleInfo: (length: number) => (length > 1 ? `Otra persona preparó ${length} facturas` : `Otra persona preparó ${length} factura`),
  subTitleInfo: (length: number) =>
    length > 1 ? `Las eliminamos para evitar duplicar la información.` : `La eliminamos para evitar duplicar la información.`,
};

export const FavoritePaymentsTexts = {
  header: 'Servicios favoritos',
  subheader: 'Encontrá más rápido tus servicios frecuentes y agregá nuevos favoritos.',
};

export const GoCreatePaymentButtonTexts = {
  btnCreatePayment: 'Buscar servicio',
  btnAddToFavorite: 'Agregar servicio favorito',
};

export const cardFavoritesText = {
  from: 'Desde ',
};

export const BottomNavTexts = {
  disabledWithErrorAmount: 'Verificá que los montos estén completos para poder continuar',
  deselectBills: 'Deseleccionar',
  addToCart: 'Agregar al carrito',
  preparePayment: 'Preparar pago',
  disabledButton: 'Se llenó el carrito. Deseleccioná facturas para continuar.',
  totalToPay: (amount: string) => `Total a pagar: ${amount}`,
  billsSelected: (amount: number) => (amount > 1 ? `${amount} facturas seleccionadas` : `${amount} factura seleccionada`),
  billsOnCart: (amount: number) => (amount > 1 ? `${amount} facturas en tu carrito` : `${amount} factura en tu carrito`),
};

export const ToastTexts = {
  successMessage1: 'Eliminaste la factura con éxito.',
  errorOnAddItemToCart: 'Algunas facturas no avanzaron. Para continuar, intentá nuevamente.',
  errorMessage2: 'No podemos eliminar la factura.',
  ctaLabel1: 'Reintentar',
  errorMessage3: 'No podemos cargar la información.',
  errorMessage4: 'No podemos procesar  el pago. Volvé a intentarlo.',
  errorMessage5: 'Se ha perdido la conexión a Internet.',
  reconnectedMessage: 'Se restableció la conexión.',
};

export const CheckoutTexts = {
  ownAccount: 'Cuenta propia',
  linkedAccount: 'Cuenta vinculada',
  mainSubHeader: 'Seleccioná la cuenta',
  title1: 'Ir a Autorizar',
  mainText:
    'Elegí una cuenta, revisá las facturas y pagalas antes de las 18:00 horas. Si no lo hacés, se guardarán en el carrito hasta ese momento.',
  checkoutTextsEmptyNoAccess: 'No podemos mostrar la página',
  checkoutTextsEmptyNoAccesSub:
    'Notamos que no tenés permisos para visualizar esta sección. Contactá a tu administrador para solicitar ayuda.',
  checkoutTextsEmptyNoData: 'Todavía no tenés cuentas',
  checkoutTextsEmptyNoDataSub: 'Para pagar tus facturas, la persona que tiene los permisos en tu empresa debe agregar cuentas bancarias.',
  checkoutTextsEmptyAccessNoData: 'Nada por aquí',
  checkoutTextsEmptyAccessNoDataSub: 'La información ya no está disponible. Para continuar, volvé a Pagos.',
  checkoutTextsEmptyErrorConect: 'La información no está disponible.',
  checkoutTextsEmptyErrorConectSub: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
  goToHome: 'Ir al inicio',
  goToPayments: 'Ir a Pagos',
  goToAccount: 'Agregar',
  retryCheckout: 'Reintentar',
  isBalanceServiceUnavalaibleTrue: 'No podemos mostrar el saldo',
  isBalanceServiceUnavalaibleFalse: 'No tenés permiso para ver el saldo',
};

export const AccountSelectionFilterTexts = {
  label: 'Banco',
  company: 'Empresas',
  title: 'Podés seleccionar más de uno',
  applyButton: 'Aplicar',
  deleteButton: 'Borrar',
  actionFilter: 'Filtrá por:',
  generalDeleteAction: 'Borrar filtros',
};

export const CartSummaryTexts = {
  totalToPayText: 'Total',
  totalToPayAmount: (amount: string) => `${amount}`,
  billsToPay: (amount: number) => (amount > 1 ? `Tenés ${amount} facturas a pagar` : `Tenés ${amount} factura a pagar`),
  seeDetailButton: 'Ver detalle',
  titleDrawer: 'Detalle de tus facturas',
  labelTotal: 'Total',
  getBillAmount: (itemLength: number) => `${itemLength} factura${itemLength > 1 ? 's' : ''}`,
};

export const ModalTextsCheckoutPayButton = {
  title: PreparePaymentText.title,
  description:
    'Esto puede demorar unos minutos. Cuando finalice el proceso, podrás visualizar las facturas en la sección <strong>Autorizar</strong> dentro de <strong>Transferencias</strong>. ',
  acceptButton: 'Entendido',
};

export const AlertBannerCheckoutHome = {
  title: PreparePaymentText.title,
  description:
    'Cuando finalice, podrás visualizar las facturas en la sección <strong>Autorizar</strong> dentro de <strong>Transferencias</strong>.',
  buttonLabel: 'ir a Autorizar',
};
