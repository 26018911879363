import styled from '@emotion/styled';

import { colors, fonts, fontWeight, sizeFont } from '../../../styled/generalStyles';

export const TotalPaymentRow = styled.div`
  display: grid;
  grid-template-columns: 7.25fr 2.75fr 0.75fr;
  gap: 14px;
  margin-top: 22px;

  .title {
    margin-left: 24px;
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize18};
    color: ${colors.textgray};
    font-weight: ${fontWeight.fw5x};
    line-height: 24px;
    text-align: right;
    margin-right: 30px;
  }

  .total {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize18};
    color: ${colors.textgray};
    font-weight: ${fontWeight.fw5x};
    line-height: 24px;
  }
`;

export const CellTotalToPay = styled.div`
  font-weight: bold;
  text-align: end;
`;

export const SpanBadge = styled.div`
  .contentLeft {
    justify-content: left;
  }
`;

export const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 75px;
  z-index: 10;
`;

export default SnackbarContainer;
