/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-unresolved */
import { Box, Grid, GridItem, Skeleton, Spinner, Stack } from '@chakra-ui/react';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccordionCart from '../../components/AccordionCart';
import CommonPage from '../../components/CommonPage';
import EmptyStateScreen from '../../components/EmptyStateScreen';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { AlertBannerInformation, DataCartInformation, SnackbarTexts } from '../../text/GeneralText';
import { Container } from './styled';

function Cart() {
  const {
    maxCartItem,
    cartItems,
    cartTotalAmount,
    cartItemsCount,
    getCartData,
    cartFull,
    setCartFull,
    endOperative,
    startOperative,
    operative,
    gireAvailable,
    validateOperatibility,
    errorPrepackaging,
    setErrorPrepackaging,
    isLoadingScreen,
    setIsLoadingScreen,
  } = useContext(AppContext);
  const [isFetching, setIsFetching] = useState(true);
  const { ToastUtilities } = useChakraToast();
  const DURATION_IN_MILLISECONDS = 4000;
  const navigate = useNavigate();
  // Get Cart items data
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Carrito',
    });
    setIsLoadingScreen(false);
    setIsFetching(true);
    const fetchCartData = async () => {
      try {
        await getCartData();
        setIsFetching(false);
      } catch {
        setIsFetching(true);
      }
    };

    fetchCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTotalAmount]);

  useEffect(() => {
    if (cartItems && cartItems?.length === 0) {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Carrito vacio',
      });
    }
  }, [cartItems]);

  useEffect(() => {
    validateOperatibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // muestra carrito
  useEffect(() => {
    if (cartItemsCount && maxCartItem) {
      setCartFull(cartItemsCount >= maxCartItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount, maxCartItem]);

  const handleClose = () => setErrorPrepackaging(false);

  const handleToastAction = async (companyUpdated: string, successful: boolean, type: string) => {
    const MIN_BILL = 1;
    const MIN_COMPANY = 1;
    const cartData = await getCartData();
    const companyNames = cartData.map(cart => cart.name);
    let text = '';
    if (companyNames.length < MIN_COMPANY || cartItemsCount === MIN_BILL) {
      return;
    }
    ToastUtilities.closeAll();
    if (successful) {
      if (type === 'bill' && cartItemsCount >= MIN_BILL) {
        text = SnackbarTexts.deleteBillSuccess(companyUpdated);
      }
      if (type === 'company' && cartItems.length >= MIN_BILL) {
        text = SnackbarTexts.deleteCompanySuccess(companyUpdated);
      }
    } else {
      text = type === 'bill' ? SnackbarTexts.deleteBillError(companyUpdated) : SnackbarTexts.deleteCompanyError(companyUpdated);
    }
    ToastUtilities[successful ? 'success' : 'error']({ description: text, duration: DURATION_IN_MILLISECONDS });
  };

  const handleGoBackHome = () => {
    navigate(`/${routesNames.Home}`);
  };

  const renderCartView = () => {
    return (
      <GridItem colSpan={12} className='flex'>
        {cartItems && cartItems?.length !== 0 ? (
          <AccordionCart
            companies={cartItems}
            total_amount={cartTotalAmount}
            total_items={cartItemsCount}
            snackbarAction={handleToastAction}
          />
        ) : (
          <EmptyStateScreen page='CART' variant='NO_DATA' onRetry={handleGoBackHome} hasButton />
        )}
      </GridItem>
    );
  };
  const getAlertContentOperative = (): { title: string; description: string } => {
    if (!operative) {
      return {
        title: AlertBannerInformation.error.operatibilityTitle,
        description: AlertBannerInformation.error.operatibilityDescription(startOperative, endOperative),
      };
    }
    if (!gireAvailable) {
      return {
        title: AlertBannerInformation.error.gireAvailabilityTitle,
        description: AlertBannerInformation.error.gireAvailabilityDescription,
      };
    }
    return { title: '', description: '' };
  };
  const { description, title } = getAlertContentOperative();
  return (
    <Container>
      {isLoadingScreen ? (
        <>
          <Stack>
            <Skeleton color='#E3E2E6' height='27.352px' />
            <Skeleton color='#E3E2E6' height='27.352px' />
            <Skeleton color='#E3E2E6' height='27.352px' />
          </Stack>
          <Box marginTop='20px'>
            <Grid h='589px' templateColumns='repeat(2, 1fr)' gap={4}>
              <GridItem rowStart={1} rowEnd={2}>
                <Skeleton height='64px' />
              </GridItem>
              <GridItem rowStart={2} rowEnd={3}>
                <Skeleton height='507px' />
              </GridItem>
              <GridItem colStart={2} rowStart={1} rowEnd={3}>
                <Skeleton height='100%' />
              </GridItem>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid templateColumns='repeat(12, 1fr)' columnGap={16} rowGap={0}>
          <GridItem colSpan={12} className='flex'>
            <CommonPage headline={DataCartInformation.title} text={DataCartInformation.mainDescription(maxCartItem, endOperative)} />
            {!operative && (
              <AlertComponent className='alertConfig' id='alert-cartOperative' variant='destructive' width='100%'>
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription>{description}</AlertDescription>
              </AlertComponent>
            )}
            {!gireAvailable && (
              <AlertComponent className='alertConfig' id='alert-cartOperative' variant='destructive' width='100%'>
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription>{description}</AlertDescription>
              </AlertComponent>
            )}
            {operative && errorPrepackaging && cartItemsCount >= 1 && (
              <AlertComponent
                className='alertConfig'
                id='alert-cartErrorPrepackaging'
                variant='destructive'
                width='100%'
                onClose={handleClose}
              >
                <AlertTitle>{AlertBannerInformation.error.titleCartFull}</AlertTitle>
                <AlertDescription>{AlertBannerInformation.error.descriptionCartFullPay(maxCartItem)}</AlertDescription>
              </AlertComponent>
            )}
            {operative && !errorPrepackaging && cartFull && cartItemsCount >= 1 && (
              <AlertComponent className='alertConfig' id='alert-cartFullCart' variant='destructive' width='100%'>
                <AlertTitle>{AlertBannerInformation.error.titleCartFull}</AlertTitle>
                <AlertDescription>{AlertBannerInformation.error.descriptionCartFullPay(maxCartItem)}</AlertDescription>
              </AlertComponent>
            )}
            {isFetching && (
              <div className='spinner'>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#2172DC' size='xl' />
              </div>
            )}
          </GridItem>
          {!isFetching && renderCartView()}
        </Grid>
      )}
    </Container>
  );
}

export default Cart;
