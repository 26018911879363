/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Checkbox, Radio, Tooltip } from '@chakra-ui/react';
import { HTMLProps, useContext, useEffect, useRef } from 'react';

import AppContext from '../../context/Provider';
import { TooltipTexts } from '../../text/GeneralText';

interface ComponentProps {
  isSomeSelected?: boolean;
  isRadioButtonActive?: boolean;
}

function RowSelectCheckbox({ isSomeSelected, isRadioButtonActive = false, ...rest }: ComponentProps & HTMLProps<HTMLInputElement>) {
  const { operative, startOperative, endOperative, gireAvailable } = useContext(AppContext);
  const { checked, onChange, id, disabled } = rest;
  const RowSelectCheckboxRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (typeof isSomeSelected === 'boolean') {
      RowSelectCheckboxRef.current.indeterminate = !rest.checked && isSomeSelected;
    }
  }, [RowSelectCheckboxRef, isSomeSelected, rest.checked]);

  const checkboxBills = () => {
    return (
      <Box>
        {isRadioButtonActive ? (
          <Radio ref={RowSelectCheckboxRef} isChecked={checked} onChange={onChange} size='lg' isDisabled={disabled} />
        ) : (
          <Checkbox
            ref={RowSelectCheckboxRef}
            isChecked={checked}
            onChange={onChange}
            isIndeterminate={isSomeSelected}
            size='lg'
            isDisabled={disabled}
          />
        )}
      </Box>
    );
  };
  const getTooltipLabel = (operative, id, startOperative, endOperative, gireAvailable): string => {
    if (!operative || !gireAvailable) {
      return TooltipTexts.gireFallTableButtons;
    }

    if (id === 'head') {
      return TooltipTexts.checkboxDisabledBills(startOperative, endOperative);
    }

    return '';
  };
  const shouldShowTooltip = disabled || !operative || !gireAvailable;
  const tooltipLabel = getTooltipLabel(operative, id, startOperative, endOperative, gireAvailable);
  return (
    <span>
      {shouldShowTooltip ? (
        <Tooltip label={tooltipLabel} placement='right' hasArrow>
          {checkboxBills()}
        </Tooltip>
      ) : (
        checkboxBills()
      )}
    </span>
  );
}

export default RowSelectCheckbox;
