/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Grid, GridItem, Spinner } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useState } from 'react';

import CommonPage from '../../components/CommonPage';
import EmptyStateScreen from '../../components/EmptyStateScreen';
import FilterComponent from '../../components/FilterComponent';
import SelectComponent from '../../components/MainSelect';
import ModalitiesForm from '../../components/ModalitiesForm';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { getCompanies, getModalities } from '../../services/api';
import { DataFilterInformation, DataSelectInformation } from '../../text/GeneralText';
import Container from './styled';

function CreatePay() {
  const { gireAvailable, validateOperatibility } = useContext(AppContext);
  const [paymentModalities, setPaymentModalities] = useState(null);
  const [isErrorFilter, setIsErrorFilter] = useState(false);
  const [isErrorSelect, setIsErrorSelect] = useState(false);
  const [lengthModality, setLengthModality] = useState(false);
  const [idModality, setIdModality] = useState('');
  const [idOneModality, setIdOneModality] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const { setPersistedForm, persistedForm, setIsBillsError, setModalitiesForm, modalitiesForm, setIsButtonAvailable } =
    useContext(AppContext);

  const handleSelectedCompany = item => {
    setSelectedCompany(null);
    setIdModality(null);
    setPaymentModalities(null);
    setLengthModality(false);
    setIdOneModality(null);
    setIsBillsError(false);
    setIsButtonAvailable(false);
    // if value of select Company is diferent to empty string
    if (item !== null) {
      setSelectedCompany(item.value);
      setPersistedForm({
        companyId: item.value,
        companyName: item.label,
        // path: '/pagos/guardar-y-pagar-servicio/facturas',
        path: `/${routesNames.PaymentBills}`,
      });
      setModalitiesForm({ ...modalitiesForm, fields: {} });
      getModalities(item.value).then(res => {
        if (res.data.paymentModes.length > 1) {
          setLengthModality(true);
          setPaymentModalities(res.data);
        } else {
          setIdOneModality(res.data.paymentModes[0].id);
          setPaymentModalities(res.data);
        }
        return res.data;
      });
    }
  };

  useEffect(() => {
    validateOperatibility();
    if (idOneModality) {
      setPersistedForm({ ...persistedForm, modalityId: idOneModality });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOneModality]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Crea tu pago',
    });
    getBusiness();
  }, []);

  const getBusiness = () => {
    setIsLoading(true);
    getCompanies()
      .then(res => {
        setHasErrorConnection(false);
        setFilterData(res);
        setIsErrorFilter(false);
        setIsErrorSelect(false);
        setIsLoading(false);
      })
      .catch(error => {
        setHasErrorConnection(true);
        setIsErrorFilter(true);
        setIsErrorSelect(true);
        setIsLoading(false);
      });
  };

  // al entrar a la ruta, si en context esta guardado el path anterior como /pagos/guardar-y-pagar-servicio/facturas, se ejecuta
  useEffect(() => {
    if (persistedForm.path === `/${routesNames.PaymentBills}`) {
      getModalities(persistedForm.companyId).then(res => {
        if (res.data.paymentModes.length > 1) {
          setLengthModality(true);
          setPaymentModalities(res.data);
        } else {
          setIdOneModality(res.data.paymentModes[0].id);
          setPaymentModalities(res.data);
        }
        return res.data;
      });
      setSelectedCompany(persistedForm.companyId);
      setIdModality(persistedForm.modalityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataModality = childData => {
    setIdModality(childData);
  };

  const searchModality = () => {
    if (!isErrorSelect && paymentModalities && lengthModality === true) {
      return (
        <>
          <GridItem colSpan={12} className='flex marginTop30'>
            <CommonPage subheader={DataSelectInformation.title} text={DataSelectInformation.subtitle} />
          </GridItem>
          <GridItem className='stickySelect' colSpan={5}>
            <SelectComponent
              parentSend={handleDataModality}
              dataSelect={paymentModalities}
              initValue={
                persistedForm.modalityId
                  ? {
                    value: persistedForm.modalityId,
                    label: persistedForm.modalityLabel,
                  }
                  : null
              }
            />
          </GridItem>
        </>
      );
    }
  };

  return (
    <Container>
      <Grid templateColumns='repeat(12, 1fr)'>
        <GridItem colSpan={12}>
          <CommonPage
            headline={DataFilterInformation.title}
            showBreadcrumb
            subheader={gireAvailable ? DataFilterInformation.subtitle : ''}
            text={gireAvailable ? DataFilterInformation.description : ''}
          >
            {isLoading && !hasErrorConnection && (
              <div className='spinner'>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#2172DC' size='xl' />
              </div>
            )}
          </CommonPage>
        </GridItem>
        {hasErrorConnection && (
          <GridItem colSpan={12} className='flex'>
            <EmptyStateScreen page='SERVICE_PAYMENT' variant='ERROR_CONN' hasButton onRetry={getBusiness} />
          </GridItem>
        )}
        {!gireAvailable && (
          <GridItem colSpan={12} className='flex'>
            <EmptyStateScreen page='SERVICE_PAYMENT' variant='SYSTEM_UNAVAILABLE' hasButton onRetry={getBusiness} />
          </GridItem>
        )}
        {!hasErrorConnection && !isLoading && gireAvailable && (
          <>
            {!isErrorFilter && (
              <GridItem className='stickySelect' colSpan={5}>
                <FilterComponent
                  handleValue={handleSelectedCompany}
                  filterInfo={DataFilterInformation}
                  dataFilter={filterData}
                  initValue={
                    !persistedForm.companyId
                      ? null
                      : {
                        value: persistedForm.companyId,
                        label: persistedForm.companyName,
                      }
                  }
                />
              </GridItem>
            )}
            {searchModality()}
            {selectedCompany && idOneModality && (
              <GridItem colSpan={12} className='marginTop30'>
                <ModalitiesForm forms={paymentModalities?.paymentModes} companyCode={selectedCompany} modalityId={idOneModality} />
              </GridItem>
            )}
            {selectedCompany && idModality && lengthModality && (
              <GridItem colSpan={12} className='marginTop30'>
                <ModalitiesForm forms={paymentModalities?.paymentModes} companyCode={selectedCompany} modalityId={idModality} />
              </GridItem>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default CreatePay;
