import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { IBill } from '../../../interfaces/ICarts';
import { colors } from '../../../styled/generalStyles';
import { AccordionHeader } from '../../../text/GeneralText';
import { transformNumberToCurrency } from '../../../utils/converters';
import BillsCompanyCarts from '../../BillsCompanyCarts';
import DeleteCompanyButton from '../DeleteCompanyButton';

interface AccordionCompanyItemProps {
  bills: IBill[];
  name: string;
  total: number;
  snackbarAction: (company: string, success: boolean, type: string) => void;
}

function AccordionCompanyItem({ bills, name, total, snackbarAction }: AccordionCompanyItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isTabExpanded, setIsTabExpanded] = useState(false);

  const handleHoverEnterArrow = () => {
    setIsHovered(true);
  };

  const handleHoverLeaveArrow = () => {
    setIsHovered(false);
  };

  return (
    <AccordionItem className='accordion-item' data-testid='accor-item'>
      {({ isExpanded }) => {
        setTimeout(() => {
          setIsTabExpanded(isExpanded);
        }, 0);

        return (
          <>
            <div className={`accordion-header ${isExpanded ? 'selected' : ''} ${isHovered ? 'hovered' : ''}`} data-testid='accor-header'>
              <Box className='flexCenter'>
                <Text textStyle='h5Bold'>{name}</Text>
                <Text textStyle='bodyMd'>
                  {bills.length} {AccordionHeader.facturas(bills.length)}
                </Text>
              </Box>

              <Box className='flexEnd'>
                {isExpanded ? (
                  <Text textStyle='labelLgBold'>{AccordionHeader.PaymentSubtitle}</Text>
                ) : (
                  <Text textStyle='h5Bold'>{AccordionHeader.PaymentSubtitle}</Text>
                )}
                <Text textStyle='bodyMd' role='rowheader'>
                  {transformNumberToCurrency(total)}
                </Text>
              </Box>

              <div className='accordion-buttons'>
                <DeleteCompanyButton bills={bills} snackbarAction={(company, success, type) => snackbarAction(company, success, type)} />
                <AccordionButton
                  className={`accordion-button-arrow ${isExpanded ? 'selected' : ''}`}
                  _expanded={{ bg: colors.lightBlueNew }}
                  onMouseEnter={handleHoverEnterArrow}
                  onMouseLeave={handleHoverLeaveArrow}
                >
                  <AccordionIcon />
                </AccordionButton>
              </div>
            </div>
            <AccordionPanel pb={4}>
              {isTabExpanded && (
                <BillsCompanyCarts
                  key={name}
                  bills={bills}
                  snackbarAction={(company, success, type) => snackbarAction(company, success, type)}
                />
              )}
            </AccordionPanel>
          </>
        );
      }}
    </AccordionItem>
  );
}

export default AccordionCompanyItem;
