import styled from 'styled-components';

import Search from '../../assets/images/Union.png';
import { colors, fonts, sizeFont } from '../../styled/generalStyles';

const Container = styled.div`
  .error-text {
    font-size: 12px;
    color: red;
  }

  .input-error {
    border-color: red;
  }
`;

export const Styled = {
  control: styles => ({
    ...styles,
    borderRadius: '4px',
    height: '40px',
    display: 'flex',
    alignContent: 'center',
    fontFamily: fonts.body,
    fontSize: sizeFont.fontSize14,
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    marginLeft: '30px',
  }),

  option: (base, { isFocused }) => {
    return {
      ...base,
      backgroundColor: isFocused ? colors.gray : colors.white,
      height: '48px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      color: colors.textgray,
      ':active': {
        ...base[':active'],
        backgroundColor: colors.backGray,
      },
    };
  },

  clearIndicator: (base, { isFocused }) => {
    return {
      ...base,
      color: isFocused ? colors.blue : 'gray',
      ':hover': {
        color: isFocused ? colors.blue : 'gray',
      },
    };
  },

  menu: styles => ({
    ...styles,
    borderRadius: '0px',
    padding: '8px 12px 12px 12px',
    position: 'relative',
  }),

  input: styles => ({ ...styles, ...searchIcon(Search) }),

  singleValue: styles => ({
    ...styles,
    marginLeft: '30px',
  }),
};

const searchIcon = image => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    content: '" "',
    marginRight: 8,
    paddingRight: 16,
    height: '20px',
    width: '4px',
  },
});

export { Container, searchIcon };
