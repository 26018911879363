import { useContext, useState } from 'react';

import AppContext from '../context/Provider';
import { FormValidation } from '../text/GeneralText';
import { stringToDate } from '../utils/converters';

const useFormValidation = () => {
  const { persistedForm, setPersistedForm, modalitiesForm, setModalitiesForm, isBillsError, setIsBillsError } = useContext(AppContext);
  const [form, setForm] = useState({});
  const [isFormError, setIsFormError] = useState([]);
  const [formErrorMessage, setformErrorMessage] = useState({});
  const [label, setlabel] = useState([]);
  const [, setValueEvent] = useState('');

  const handleChange = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    isBillsError && setIsBillsError(false);
    setForm({
      ...form,
      [name]: value,
    });
    setModalitiesForm({
      ...modalitiesForm,
      fields: { ...modalitiesForm.fields, [name]: value },
    });
    setPersistedForm({ ...persistedForm, [name]: { ...persistedForm[name], field: name, value } });
    if (value.length > 0) {
      setlabel([...label, name]);
      setIsFormError(isFormError.filter(el => el !== name));
    }
  };

  const handleDateChange = (value, name, val) => {
    const divIcon = document.getElementById(val.position);
    if (value && isBillsError === false) {
      divIcon.setAttribute('class', 'chakra-icon iconGray css-18rw4e7');
    }
    isBillsError && setIsBillsError(false);
    setForm({
      ...form,
      [name]: value,
    });
    setModalitiesForm({
      ...modalitiesForm,
      fields: { ...modalitiesForm.fields, [name]: value },
    });
    if (value) {
      setlabel([...label, name]);
      setIsFormError(isFormError.filter(el => el !== name));
      (document.getElementById(val.field) as HTMLInputElement).innerHTML = '';
    }
  };

  const handleComboChange = (value, name) => {
    isBillsError && setIsBillsError(false);
    setForm({
      ...form,
      [name]: value.value,
    });
    setModalitiesForm({
      ...modalitiesForm,
      fields: { ...modalitiesForm.fields, [name]: value.value },
    });
    setPersistedForm({ ...persistedForm, [name]: { ...persistedForm[name], field: name, value } });
    if (value.value) {
      setlabel([...label, name]);
      setIsFormError(isFormError.filter(el => el !== name));
    }
  };

  const handleBlur = (e: { target: HTMLInputElement }, val) => {
    const { value, name } = e.target;
    (document.getElementById(val.label) as HTMLInputElement).placeholder = val.label;
    (document.getElementById(val.field) as HTMLInputElement).innerHTML = '';
    handleChange(e);
    if (value.length < 1) {
      setIsFormError([...isFormError, name]);
      setformErrorMessage({ ...formErrorMessage, [name]: FormValidation.text1 });
      setlabel([...label, name]);
    } else {
      setlabel(label.filter(el => el !== name));
    }
  };

  const handleBlurDate = (e: { target: HTMLInputElement }, val) => {
    const { value, name } = e.target;
    e.target.placeholder = val.label;
    (document.getElementById(val.field) as HTMLInputElement).innerHTML = '';
    const date = stringToDate(value);
    handleDateChange(date, name, val);
    if (value.length < 1) {
      setIsFormError([...isFormError, name]);
      setformErrorMessage({ ...formErrorMessage, [name]: FormValidation.text1 });
      setlabel([...label, name]);
    } else {
      setlabel(label.filter(el => el !== name));
    }
    if (e && val.position) {
      const divIcon = document.getElementById(val.position);
      divIcon.setAttribute('class', 'chakra-icon iconGray css-18rw4e7');
    }
  };

  const handleBlurCombo = (value, name) => {
    if (!value) {
      setIsFormError([...isFormError, name]);
      setformErrorMessage({ ...formErrorMessage, [name]: FormValidation.text1 });
      setlabel([...label, name]);
    } else {
      setlabel(label.filter(el => el !== name));
    }
  };

  const handleFocus = (e: { target: HTMLInputElement }, val) => {
    const { id, placeholder } = e.target;
    setValueEvent(placeholder);
    (document.getElementById(id) as HTMLInputElement).placeholder = '';
    (document.getElementById(val.field) as HTMLInputElement).innerHTML = id;

    if (isBillsError) {
      cleanField(e);
      (document.getElementById(val.label) as HTMLInputElement).innerHTML = '';
      setIsBillsError(false);
    }
  };

  const handleFocusCombo = () => {
    isBillsError && setIsBillsError(false);
  };

  const handleFocusDate = (item, val) => {
    // eslint-disable-next-line no-param-reassign
    item.target.placeholder = '';
    (document.getElementById(val.field) as HTMLInputElement).innerHTML = val.label;
    const divIcon = document.getElementById(val.position);
    divIcon.setAttribute('class', 'chakra-icon iconBlue css-18rw4e7');
    if (isBillsError === true) {
      isBillsError && setIsBillsError(false);
      divIcon.setAttribute('class', 'chakra-icon iconBlue css-18rw4e7');
    }
  };

  const cleanField = (e: { target: HTMLInputElement }) => {
    const { name } = e.target;
    setForm({
      ...form,
      [name]: '',
    });
    setModalitiesForm({
      ...modalitiesForm,
      fields: { ...modalitiesForm.fields, [name]: '' },
    });
    setPersistedForm({ ...persistedForm, [name]: { ...persistedForm[name], field: name, value: '' } });
  };

  const handleClick = name => {
    setForm({
      ...form,
      [name]: '',
    });
    setModalitiesForm({
      ...modalitiesForm,
      fields: { ...modalitiesForm.fields, [name]: '' },
    });
  };

  return {
    form,
    isFormError,
    formErrorMessage,
    label,
    handleChange,
    handleBlur,
    handleDateChange,
    handleComboChange,
    handleBlurCombo,
    handleFocusCombo,
    handleBlurDate,
    handleFocus,
    handleFocusDate,
    handleClick,
  };
};

export default useFormValidation;
