/* eslint-disable import/extensions */

import { Text, Tooltip } from '@chakra-ui/react';

import ColumnSort from '../../../commons/ColumnSort';
import { INextExpiration } from '../../../interfaces/INextExpirationsBills';
import { ColumnsConfigTable } from '../../../interfaces/ITableComponent';
import { ColumnsConfigText, TooltipTexts } from '../../../text/GeneralText';
import { capitalizeFirst, transformStringToCurrency, transformStringToDate } from '../../../utils/converters';
import BillStatus from '../../BillStatus';
import RowSelectCheckbox from '../../Checkbox/RowSelectCheckbox';
import DrawerDetail from '../../DrawerDetails';
import InputWithAmount from '../../InputWithAmount';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import { CellTotalToPay } from './styled';

export default (
  sortFunction,
  operative,
  updateAmountToPayValue,
  amountToPayValues,
  gireAvailable,
): ColumnsConfigTable<INextExpiration>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <RowSelectCheckbox
        {...{
          checked: !operative ? false : table.getIsAllRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
          isSomeSelected: table.getIsSomeRowsSelected(),
          disabled: !operative || !gireAvailable,
          id: 'head',
        }}
      />
    ),
    cell: ({ row }) => (
      <RowSelectCheckbox
        key={row.original.hash}
        {...{
          checked: (!operative ? false : row.getIsSelected()) || Boolean(row.original.isWizard),
          onChange: row.getToggleSelectedHandler(),
          disabled: !row.original.enabled || !operative || !gireAvailable,
        }}
      />
    ),
  },
  {
    accessorKey: 'alias',
    id: 'pagoFavorito',
    cell: info => (
      <TextEllipsisWithTooltip
        text={`${info.row.original.companyName} - ${info.row.original.alias}`}
        placement='right'
        textStyle='labelLgBold'
        padding='0'
        className='maxWidthAlias'
      />
    ),
    header: () => (
      <ColumnSort onSort={order => sortFunction('EmpAlias', order)}>
        <Text textStyle='titleSmBold'>Servicio favorito</Text>
      </ColumnSort>
    ),
    enableSorting: false,
    className: 'maxWidthAlias',
    classNameCell: 'cellAliasTableHome',
  },
  {
    accessorKey: 'status',
    id: 'status-nextExpirations',
    cell: info => (
      <span>
        <BillStatus status={capitalizeFirst(info.getValue())} />
      </span>
    ),
    header: () => <Text textStyle='titleSmBold'>Estado</Text>,
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'reference',
    id: 'billCode-nextExpirations',
    header: () => (
      <Tooltip placement='top' label={TooltipTexts.codigoDeFactura} hasArrow>
        <Text textStyle='titleSmBold'>Código de factura</Text>
      </Tooltip>
    ),
    cell: info => <TextEllipsisWithTooltip text={info.getValue() as string} placement='right' padding='0' className='maxWidth' />,
    align: 'start',
    className: 'maxWidth',
  },
  {
    accessorKey: 'dueDate',
    id: 'vencimiento-nextExpirations',
    header: () => (
      <ColumnSort onSort={order => sortFunction('FechaVto', order)}>
        <Text textStyle='titleSmBold'>Vencimiento</Text>
      </ColumnSort>
    ),
    cell: info => <p>{info.getValue() ? transformStringToDate(info.getValue() as string) : ColumnsConfigText.sinVencimiento}</p>,
    enableSorting: false,
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'amount1',
    id: 'Total',
    header: () => <Text textStyle='titleSmBold'>Total</Text>,
    cell: info => (
      <CellTotalToPay>
        <p>{transformStringToCurrency(info.getValue() as string)}</p>
      </CellTotalToPay>
    ),
    className: 'marginRight25',
    align: 'end',
  },
  {
    accessorKey: 'amount1',
    id: 'toPay',
    cell: info => (
      <InputWithAmount
        key={info.row.original.hash}
        value={amountToPayValues[info.row.original.hash] || info.row.original.amountToPay}
        onChange={val => {
          updateAmountToPayValue(info.row.original.hash, val);
          /* eslint-disable no-param-reassign */
          info.row.original.amountToPay = val;
          info.table.resetColumnOrder();
        }}
        minAmount={info.row.original.minAmount}
        amountType={info.row.original.amountType}
        maxAmount={info.row.original.maxAmount}
      />
    ),
    header: () => <Text textStyle='titleSmBold'>A pagar</Text>,
    align: 'start',
    className: 'paddingLeft16',
    classNameCell: 'paddingLeft16',
  },
  {
    accessorKey: 'detail',
    id: 'detail',
    header: '',
    cell: detalle => (
      <DrawerDetail
        title={`${detalle.row.original.companyName} ${detalle.row.original.alias ? ` - ${detalle.row.original.alias}` : ''}`}
        drawerDetail={detalle.row.original.detail}
        enabled={detalle.row.original.detail.length > 0}
        status={detalle.row.original.status}
        drawerVariant='drawerExpirationCalendar'
      />
    ),
  },
];
