/* eslint-disable import/no-unresolved */
import { Button, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Cancel from '../../../assets/images/Cancel';
import { FavoriteModalProps } from '../../../interfaces/IFavoriteModal';
import { AliasDeleteModalText } from '../../../text/GeneralText';
import { Container, iconClose, PaddingModal } from './styled';

FavoriteModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  alias: PropTypes.oneOfType([PropTypes.object]),
};

function FavoriteModal(props: FavoriteModalProps) {
  const { isOpen, onClose, alias, onDelete }: FavoriteModalProps = props;

  const handleDeleteAlias = () => {
    onDelete(alias);
    onClose();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} data-testid='modal' isCentered>
      <ModalOverlay />
      <ModalContent>
        <Container>
          <ModalCloseButton style={iconClose} />
          <PaddingModal>
            <div className='img'>
              <Cancel />
            </div>
            <Text textStyle='h3' className='titleH3'>
              {`${AliasDeleteModalText.title}`}
            </Text>
            <Text textStyle='bodyMd' className='textBodyMd'>
              {AliasDeleteModalText.subtitle}
            </Text>
          </PaddingModal>

          <div className='flexButtonFooter'>
            <Button paddingX='24px' variant='primary-text' onClick={onClose} size='md'>
              {AliasDeleteModalText.cancelButton}
            </Button>
            <Button
              paddingX='24px'
              size='md'
              className='margin15'
              variant='primary'
              data-testid='btnDeleteModal'
              onClick={handleDeleteAlias}
            >
              {AliasDeleteModalText.deleteAliasButton}
            </Button>
          </div>
        </Container>
      </ModalContent>
    </Modal>
  );
}
export default FavoriteModal;
