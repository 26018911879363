import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

const Container = styled.div`
  .stickySelect {
    position: sticky;
  }

  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .cart-icon {
    display: flex;
    flex-direction: row-reverse;
    margin-right: -4%;
    color: ${colors.blue};
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .marginTop30 {
    margin-top: 30px;
  }
`;

export default Container;
