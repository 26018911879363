import { Button, Icon, Tooltip } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { IButton } from '../../interfaces/IButtonPayCart';
import { Company, IBill } from '../../interfaces/ICarts';
import { createCartItem, createCartItemList, getOperatibility } from '../../services/api';
import { BottomNavTexts, TooltipTexts } from '../../text/GeneralText';

function PreparePaymentButton({
  buttonText,
  fromButtonNav,
  handleOnClickFromBottomNav,
  isDisabled,
  setErrorOnAddItemToCart,
  isCartFullWithBillsChecked,
  isErrorAmountPay,
}: IButton) {
  const { cartItems, setOperative, setStartOperative, setEndOperative, operative, getCartData, setIsLoadingScreen, gireAvailable } =
    useContext(AppContext);
  const navigate = useNavigate();

  const confirm = async () => {
    if (!fromButtonNav) {
      pushAnalyticsEvent({
        event: 'preparar_pago',
        content_type: 'Botones seccion carrito',
      });
    }
    setIsLoadingScreen(true);

    getOperatibility().then(res => {
      if (!res.data.operability.operative) {
        setOperative(res.data.operability.operative);
        setStartOperative(res.data.operability.start);
        setEndOperative(res.data.operability.end);
        setIsLoadingScreen(false);
      } else {
        validatePayment();
      }
    });
  };

  const validatePayment = async () => {
    const bills = [];
    let errorOnAddToCartRequests = false;
    if (fromButtonNav) {
      const addToCartRequests = [];
      const rowsSelected = handleOnClickFromBottomNav();
      rowsSelected.forEach(row => {
        addToCartRequests.push(createCartItem({ items: [{ identifier: row.hash, currency: 'ARS', amount: row.amount }] }));
      });
      await createCartItemList(addToCartRequests).then(async result => {
        result.forEach(res => {
          if (res.status === 'rejected') {
            errorOnAddToCartRequests = true;
          }
        });
        if (!errorOnAddToCartRequests) {
          const companies: Company[] = await getCartData();
          companies.forEach((company: Company) =>
            company.items.forEach(bill => {
              bills.push(bill);
            }),
          );
        } else {
          setIsLoadingScreen(false);
          setErrorOnAddItemToCart(true);
        }
      });
    } else {
      cartItems.forEach((company: Company) =>
        company.items.forEach((bill: IBill) => {
          bills.push(bill);
        }),
      );
    }
    if (!errorOnAddToCartRequests) {
      navigate(`/${routesNames.AccountSelection}`);
      setIsLoadingScreen(false);
    }
  };

  return (
    <>
      {isCartFullWithBillsChecked && (
        <Tooltip
          isDisabled={operative && gireAvailable && !isErrorAmountPay}
          hasArrow
          placement='top'
          label={!operative || !gireAvailable ? TooltipTexts.gireFallTableButtons : BottomNavTexts.disabledButton}
        >
          <Button
            variant='primary'
            onClick={() => confirm()}
            rightIcon={<Icon as={ChevronRight} />}
            isDisabled={!operative || !gireAvailable || isDisabled}
            data-testid='prepare-payment-btn'
            size='md'
            marginLeft='15px'
            id='prepare-payment-btn'
          >
            {buttonText}
          </Button>
        </Tooltip>
      )}
      {!isCartFullWithBillsChecked && (
        <Tooltip
          isDisabled={operative && gireAvailable && !isErrorAmountPay}
          hasArrow
          placement='left'
          label={!operative || !gireAvailable ? TooltipTexts.gireFallTableButtons : BottomNavTexts.disabledWithErrorAmount}
        >
          <Button
            variant='primary'
            onClick={() => confirm()}
            rightIcon={<Icon as={ChevronRight} />}
            isDisabled={!operative || !gireAvailable || isDisabled}
            data-testid='prepare-payment-btn'
            size='md'
            marginLeft='15px'
            id='prepare-payment-btn'
          >
            {buttonText}
          </Button>
        </Tooltip>
      )}
    </>
  );
}

export default PreparePaymentButton;
