/* eslint-disable import/no-unresolved */
import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { AlertChakra as Alert } from '@wow/alert';
import { AvatarChakra as Avatar } from '@wow/avatar';
import { BadgeChakra as Badge } from '@wow/badge';
import { BreadcrumbChakra as Breadcrumb } from '@wow/breadcrumb';
import { ButtonChakra as Button } from '@wow/button';
import { CheckboxChakra as Checkbox } from '@wow/checkbox';
import { CloseButtonChakra as CloseButton } from '@wow/close-button';
import { FormChakra as Form } from '@wow/form-control';
import { FormErrorChakra as FormError } from '@wow/form-error';
import { FormLabelChakra as FormLabel } from '@wow/form-label';
import { InputChakra as Input } from '@wow/input';
import { SkeletonChakra as Skeleton } from '@wow/progress-indicator';
import { RadioChakra as Radio } from '@wow/radio';
import { themeChakra } from '@wow/themes';
import { TooltipChakra as Tooltip } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const bottomNavConfig = {
  overlay: {
    pointerEvents: 'none',
    background: 'transparent',
    zIndex: '0 !important',
  },
  dialogContainer: {
    pointerEvents: 'none',
    background: 'transparent',
    zIndex: '0 !important',
  },
  dialog: {
    pointerEvents: 'auto',
    width: 'auto',
    left: '67px !important',
    zIndex: '0 !important',
  },
};

const theme = extendTheme({
  ...ThemeData,
  textStyles: {
    ...themeChakra.textStyles,
  },
  colors: {
    ...themeChakra.colors,
  },
  components: {
    Alert,
    Badge,
    Breadcrumb,
    Button,
    CloseButton,
    Checkbox,
    Form,
    FormLabel,
    FormError,
    Input,
    Skeleton,
    Tooltip,
    Radio,
    Avatar,
    Drawer: {
      variants: {
        bottomNav: {
          ...bottomNavConfig,
        },
        bottomNavModeOffWizard: {
          ...bottomNavConfig,
          dialogContainer: {
            zIndex: '99 !important',
          },
        },
        bottomNavModeWizard: {
          ...bottomNavConfig,
          dialogContainer: {
            zIndex: '101 !important',
          },
        },
        drawerExpirationCalendar: {
          overlay: {
            zIndex: '2000 !important',
          },
          dialogContainer: {
            zIndex: '2000 !important',
          },
          dialog: {
            zIndex: '2000 !important',
          },
        },
      },
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <App />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
