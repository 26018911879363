import styled from 'styled-components';

import download from '../../assets/images/download.svg';
import { body14bold, colors } from '../../styled/generalStyles';

export const Link = styled.a`
  color: ${colors.blue};
  text-decoration: underline;
  text-decoration-skip-ink: underline;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.9375rem;
  letter-spacing: 0.00625rem;
  display: inline;
`;

export const iconDl = {
  cursor: 'pointer',
  color: colors.blue,
};

export const CompanyName = styled.span`
  ${body14bold};
`;

export const AmountPaid = styled.span`
  ${body14bold};
`;

export const DownloadIcon = styled.span`
  background: url(${download}) no-repeat;
`;

export const Container = styled.div`
  .topButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .title {
    margin-top: 15px;
  }
  .snackbar {
    position: fixed;
    bottom: 40px;
    margin-left: 0px;
    z-index: 100;
  }
`;
