import styled from 'styled-components';

import { colors } from '../../../styled/generalStyles';

export const CardContainer = styled.div`
  .iconBtnPrimary {
    color: ${colors.blue};
    width: 24px;
    height: 24px;
    margin-left: 24px;
    cursor: pointer;
  }

  .iconCardLargerThan1200 {
    display: flex;
    width: 30%;
    justify-content: end;
  }

  .iconCardSmallerThan1200 {
    display: flex;
    width: 50%;
    justify-content: end;
  }

  .textCardSmallerThan1200 {
    width: 50%;
  }

  .textCardLargerThan1200 {
    width: 70%;
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 24px;
    background: ${colors.white};
    border-radius: 12px;
    margin-bottom: 16px;
    border: 1px solid ${colors.gray5};
    width: 100%;
  }
`;

export default CardContainer;
