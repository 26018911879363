import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

const Container = styled.div`
  .drawer {
    border-radius: 8px 0px 0px 8px;
  }

  .title {
    margin-top: 10px;
  }
`;

const DrawerBody = styled.div`
  margin: 56px 36px 0px 36px;
`;

const Btn = styled.div`
  background: ${colors.transparent};
  color: ${'var(--chakra-colors-accent-500)'};
  border: 'none';
`;

const Td = styled.td`
  margin: 0px;
  padding: 23px 12px 23px 12px;
  border-bottom: 1px solid ${colors.borderGray};
`;

const DivTable = styled.div`
  margin-top: 12px;
`;

const P = styled.p`
  text-align: right;
`;

export const icon = {
  width: '20px',
  height: '20px',
  boxShadow: 'none',
  background: 'none',
};

export const iconClose = {
  marginTop: '15px',
  boxShadow: 'none',
  color: colors.blue,
};

export { Btn, Container, DivTable, DrawerBody, P, Td };
