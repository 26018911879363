import { Box, useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';

import Cancel from '../../../assets/images/Cancel';
import AppContext from '../../../context/Provider';
import useChakraToast from '../../../hooks/useChakraToast';
import { IBill } from '../../../interfaces/ICarts';
import { deleteCarts } from '../../../services/api';
import { ConfirmationModalTexts } from '../../../text/GeneralText';
import ConfirmationModal from '../../ConfirmationModal';
import { PaddingModal, SubTitle, Title } from '../../ConfirmationModal/styled';
import { styledModalContentDeleteCompany } from '../styled';

interface DeleteCompanyButtonProps {
  bills: IBill[];
  snackbarAction: (company: string, success: boolean, type: string) => void;
}

function DeleteCompanyButton({ bills, snackbarAction }: DeleteCompanyButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getCartData } = useContext(AppContext);
  const { ToastUtilities } = useChakraToast();

  const handleDeleteCompany = async () => {
    const hashList = bills.map(bill => bill.identifier);
    const companyName = bills[0].company_name;
    if (hashList.length > 0) {
      await deleteCarts({ items: hashList })
        .then(() => {
          getCartData();
          snackbarAction(companyName, true, 'company');
        })
        .catch(() => {
          snackbarAction(companyName, false, 'company');
        });
    }
  };

  const handleOpenModal = () => {
    ToastUtilities.closeAll();
    onOpen();
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        ModalContentCss={styledModalContentDeleteCompany}
        fn={handleDeleteCompany}
        cancelText={ConfirmationModalTexts.delete.cancelButton}
      >
        <PaddingModal>
          <div className='img'>
            <Cancel />
          </div>
          <Title>{ConfirmationModalTexts.delete.company.title}</Title>
          <SubTitle>{ConfirmationModalTexts.delete.company.subTitle}</SubTitle>
        </PaddingModal>
      </ConfirmationModal>
    );
  };

  return (
    <>
      <Box className='accordion-button-delete' onClick={handleOpenModal} data-testid='delete-company-btn' />
      {renderModal()}
    </>
  );
}

export default DeleteCompanyButton;
