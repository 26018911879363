/* eslint-disable import/no-unresolved */
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';

const useCreateNewPayment = () => {
  const navigate = useNavigate();
  const { deleteDataServicePayment } = useContext(AppContext);

  const handleGoNewPayment = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      item_id: 'Crear pago',
    });
    deleteDataServicePayment();
    navigate(`/${routesNames.CreatePayment}`);
  };

  return [handleGoNewPayment];
};

export default useCreateNewPayment;
