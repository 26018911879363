enum Routes {
  FavoritePayments = 'pagos-/favoritos',
  ReceiptsPayments = 'pagos-/comprobantes',
  PaymentCart = 'pagos-/carrito',
  Home = 'pagos-',
  SummaryPayments = 'pagos-/resumen',
  CreatePayment = 'pagos-/guardar-y-pagar-servicio',
  PaymentBills = 'pagos-/guardar-y-pagar-servicio/facturas',
  AccountSelection = 'pagos-/seleccion-de-cuentas',
  Root = '/',
}

export default Routes;
