import styled from 'styled-components';

export const Container = styled.div`
  .drawerContent {
    height: 72px;
    box-shadow: 0px -3px 8px 4px #0000001a;
  }

  .drawerBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 33px;
    width: 95%;
  }
  .content {
    display: flex;
  }

  .drawerDisabled {
    pointer-events: none;
  }

  .totalBoldText {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin: 0 16px 0 40px;
  }

  .selectedBillsText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 16px 0 16px;
  }

  .margin24 {
    margin-right: 24px;
  }

  .margin76 {
    margin-right: 76px;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export default Container;
