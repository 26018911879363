const NAMED_PERMISSIONS = {
  Transferencias_PresentacionAFIP_DDJJ: 'f1721b6c2c5c200675ffa178ab32a399f89448d0be9168c8b5ccbfbc4d8f8c51',
  Transferencias_PresentacionAFIP_Confeccion: 'a27c6e02dc79e0042f4cee6c4ec3776f84e69c429703783120cfc9b858112eb0',
  Transferencias_PresentacionAFIP_Anulacion: 'ea5cc7e0df69d681cceece302ed90b05062458eadee5996eefd8e464b817401b',
  Transferencias_Generacion_AFIP_Confeccion: '9bab9d4ee01a047b0838c19f38474cc7024b89c4e7b456f378a4e68a75ff4cba',
  Administracion_PagoServicios_AdhesionServicios: '34ce5ae9737b23a257dfa1652ecd36853ab945d2ac4aab01ae98045533d13aff',
  Administracion_PagoServicios_ConsultaComprobantes: '4408842bf013aeffccdd32fd2aed7032a45fbfb7c30715efaf0ab6634b53b3c6',
  Transferencias_Generacion_INT_PreconfeccionPropias: '99e74f0caf5a6b19822f01b5d42a9c8ce1422e292220e853dafd20e3a1a806ab',
  Transferencias_Generacion_INT_PreconfeccionTerceros: '025a34f53a3dca694191716771854155320bce2a6d381467b632150d8c77fe27',
  Transferencias_Generacion_INT_Confeccion: '2a93ed118cd6b8ce22dd53cda3bcd4281981a0b417cac6a9e15bb3d106260a9c',
  Administracion_PagoServicios: '592f8238b0079ea7cf39cefeeae4125809b69c9d62eb7736ea3d19ccdc4272d9',
  Transferencias_Generacion_AFI: '2165bb2bd5ef282bf3cb2b1a0b62148bc8796c8d39f5aa25dfdd602024fe6d78',
};

export default NAMED_PERMISSIONS;
