import styled, { css } from 'styled-components';

export const BoxIcon = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-right: 10px;
  & button {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.4;
        cursor: not-allowed;
        box-shadow: var(--chakra-shadows-none);
        border-color: #2172dc;
        color: #2172dc;
      `}
  }
`;

export default BoxIcon;
