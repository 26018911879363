import styled from 'styled-components';

import { colors, fontError, fonts, sizeFont } from '../../styled/generalStyles';

export const LabeledInput = styled.div`
  position: relative;

  .inputAmountOpen {
    border: 1px solid;
    border-color: ${colors.light};
    height: 40px;
    border-radius: 4px;
  }

  .error {
    border: 1px solid;
    border-color: ${colors.error};
    border-radius: 4px;
  }

  .error-message {
    position: absolute;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 6px;
    font-size: ${fontError.fontSize};
    font-family: ${fontError.fontFamily};
    margin-left: 10px;
  }

  .inputAmountClose {
    background: ${colors.gray};
    border: 1px solid;
    border-color: ${colors.backGray};
    border-radius: 4px;
  }

  input {
    height: 40px;
    border-radius: 4px;
    padding: 10px 0px 10px 10px;
    width: inherit;
    margin-left: 5px;
    :focus {
      outline: none;
      background-color: ${colors.white};
      border: 2px solid ${colors.blue};
      border-radius: 4px;
    }
  }

  .inputError {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize14};
    background: ${colors.white};
    border: 1px solid ${colors.error};
    :focus {
      outline: none;
      background-color: ${colors.white};
      border: 1px solid ${colors.error};
      border-radius: 4px;
    }
  }

  .inputFont {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize14};
  }

  .inputEnabled {
    background: ${colors.white};
    border: 1px solid ${colors.light};
  }

  .inputDisabled {
    background-color: ${colors.gray};
    border: 1px solid ${colors.backGray};
    border-radius: 4px;
    letter-spacing: 0.25px;
    color: ${colors.backGray};
  }
`;

export default LabeledInput;
