enum StatusTypes {
  POR_VENCER = 'por vencer',
  SIN_FECHA = 'sin fecha',
  VENCIDA = 'vencida',
  VENCE_HOY = 'vence hoy',
  PAGADA = 'pagada',
  EN_PROCESO = 'en proceso',
  AGREGADA_AL_CARRITO = 'agregada al carrito',
}

export default StatusTypes;
