/* eslint-disable react/jsx-props-no-spreading */
import { CreateToastFnReturn, StyleProps, useToast } from '@chakra-ui/react';
import { ToastComponent } from '@wow/toast';

import { ToastProps } from '../interfaces/IToast';

let toastRef: CreateToastFnReturn = null;
const DURATION_IN_MILLISECONDS = 10000;

const getDefaultStyles = (position: string, customStyles: StyleProps) => {
  return {
    minWidth: '389px',
    ...(position === 'bottom-left' && { margin: '0 0 45px 65px !Important' }),
    ...(customStyles && { ...customStyles }),
  };
};

/**
 * - ToastUtilities
 * @description Toast de ChakraUI que renderiza componente de Design System.
 * @version @wow/toast 4.0.1
 * @example Se puede utilizar por defecto: ToastUtilities.toast({...props}), o por sus status  ToastUtilities['success' || 'info' | 'error']({...props})
 * @params description {string} - Descripción del toast.
 * @params [isClosable=false] {boolean} - Botón para cerrar el toast.
 * @params [position='bottom-left']  {string} - Posición del toast .
 * @params id {string} - Id del toast .
 * @params [duration=10000] {number} - Duración del toast.
 * @params [toastOptions=null] {object} - Props del componente ToasComponent. Para manejar label y acción del "Reintentar"
 * @params containerStyle {object} - Estilos de CSS.
 * @params [enqueueToast=false] {boolean} - Permite encolar toast.
 *
 * - Metodos de ToastUtilities
 * @isActive
 * @description Función para verificar por medio de un id si el toast está activo.
 * @example ToastUtilities.isActive(id)
 * @return boolean
 *
 * @closeAll
 * @description Función para cerrar los toast activos.
 * @example ToastUtilities.closeAll()
 */
const ToastUtilities = {
  toast(props: ToastProps) {
    const {
      isClosable = false,
      position = 'bottom-left',
      id = `toast-${Date.now()}`,
      duration = DURATION_IN_MILLISECONDS,
      toastOptions,
      containerStyle,
      enqueueToast = false,
    } = props;
    // Close toast by id to avoid the queuing
    if (toastRef.isActive(id) && !enqueueToast) toastRef.close(id);

    toastRef({
      containerStyle: getDefaultStyles(position, containerStyle),
      isClosable,
      position,
      duration,
      id,
      ...props,
      render: res => <ToastComponent {...{ ...res, ...toastOptions }} />,
    });
  },
  success(props: ToastProps) {
    this.toast({ status: 'success', ...props });
  },
  error(props: ToastProps) {
    this.toast({ status: 'error', ...props });
  },
  info(props: ToastProps) {
    this.toast({ status: 'info', ...props });
  },
  closeAll() {
    toastRef.closeAll();
  },
  isActive(id: string) {
    return toastRef.isActive(id);
  },
};

// TODO remove this any

function useChakraToast(): any {
  toastRef = useToast();

  return { toastRef, ToastUtilities };
}

export default useChakraToast;
