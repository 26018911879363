import { colors, fonts, sizeFont } from '../../styled/generalStyles';

const Styled = {
  control: styles => ({
    ...styles,
    borderRadius: '4px',
    height: '40px',
    fontFamily: fonts.body,
    fontSize: sizeFont.fontSize14,
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  dropdownIndicator: styles => ({
    ...styles,
    color: colors.lightgray,
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  }),

  option: (base, { isFocused }) => {
    return {
      ...base,
      backgroundColor: isFocused ? colors.gray : colors.white,
      height: '48px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      color: colors.textgray,
      ':active': {
        ...base[':active'],
        backgroundColor: colors.backGray,
      },
    };
  },

  menu: styles => ({
    ...styles,
    borderRadius: '0px',
    padding: '8px 12px 12px 12px',
    position: 'relative',
  }),
};

export default Styled;
