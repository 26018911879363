import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .cart-icon {
    display: flex;
    flex-direction: row-reverse;
  }

  .topButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .buttonHeader {
    display: flex;
    align-items: center;
  }

  .createPay {
    padding: 5px;
  }
`;

export const ContainerExpirationHome = styled.div`
  .marginTop20 {
    margin-top: 20px;
  }
`;

export const iconClearBlue = {
  color: `${colors.blue}`,
  alignSelf: 'flex-end',
};

export default Container;
