import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-state-screen-title {
    text-align: center;
    margin-top: 25px;
  }

  .empty-state-screen-subtitle {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .empty-state-screen-button {
    display: flex;
    justify-content: center;
  }
`;

export const Link = styled.a`
  color: blue;
  text-decoration: underline;
  text-decoration-skip-ink: underline;
  text-align: center;
`;

export default { Container, Link };
