import styled from '@emotion/styled';

import { colors, fonts, fontWeight, sizeFont } from '../../styled/generalStyles';

export const AvailableBills = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    .lineGray {
      border: 1px solid ${colors.borderGray};
    }
  }
  .cart-icon {
    display: flex;
    flex-direction: row-reverse;
    margin-right: -4%;
    color: ${colors.blue};
  }
  .total {
    pointer-events: none;
    margin-top: 30px;
    margin-left: 24px;
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize18};
    color: ${colors.textgray};
    font-weight: ${fontWeight};
    line-height: 24px;
    text-align: right;
  }

  .amount {
    text-align: left;
  }
`;

export const TotalPaymentRow = styled.div`
  display: grid;
  grid-template-columns: 7.25fr 2.75fr 0.75fr;
  gap: 14px;
  margin-top: 22px;

  .total {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize18};
    color: ${colors.textgray};
    font-weight: ${fontWeight.fw5x};
    line-height: 24px;
  }
`;

export const CellTotalToPay = styled.div`
  font-weight: bold;
  text-align: end;
`;

export const SpanBadge = styled.div`
  .contentLeft {
    justify-content: left;
  }
`;

export const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 85px;
  left: 115px;
  z-index: 10;
`;
export default AvailableBills;
