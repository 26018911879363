import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='76'
      height='76'
      fillRule='evenodd'
      clipRule='evenodd'
      imageRendering='optimizeQuality'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
    >
      <path
        fill='#9d701a'
        d='M35.5 3.5c2.6.05 4.766 1.05 6.5 3l32.5 57a10.937 10.937 0 01-3 4.5c-22.667.667-45.333.667-68 0-3.8-2.93-3.967-6.096-.5-9.5 10.845-18.36 21.678-36.694 32.5-55z'
        opacity='0.881'
      />
      <path
        fill='#fef2e2'
        d='M35.5 6.5c2.48-.177 4.314.823 5.5 3a999.724 999.724 0 0030 52 8.434 8.434 0 01.5 4h-68a8.434 8.434 0 01.5-4 1127.114 1127.114 0 0031.5-55z'
      />
      <path fill='#cd931e' d='M35.5 27.5h4v23h-4v-23z' />
      <path fill='#cc9221' d='M35.5 54.5h4v4h-4v-4z' />
    </svg>
  );
}

export default Icon;
