/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenMode, pushAnalyticsEvent } from '@wow/utils';
import { ChevronRight, File, List, Star } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import RoutesNames from '../../../constants/routesNames';
import { ICardItem } from '../../../interfaces/Permissions';
import { AccessHome } from '../../../text/GeneralText';
import { iconClearBlue } from '../../ModalitiesFields/styled';
import { Container, GoButton } from '../styled';

function CardItem({ item, titleCard }: ICardItem) {
  const navigate = useNavigate();

  const handleIcon = () => {
    switch (item.title) {
      case AccessHome.titleFav:
        return Star;
      case AccessHome.titleAuth:
        return List;
      case AccessHome.titleCom:
        return File;
      default:
        return File;
    }
  };
  const handleGoTo = () => {
    pushAnalyticsEvent({
      content_type: 'Botones seccion pagos',
      item_id: item.title,
      event: 'select_content',
    });
    switch (item.title) {
      case AccessHome.titleFav:
        navigate(`/${RoutesNames.FavoritePayments}`);
        break;
      case AccessHome.titleAuth:
        window.open(
          OpenMode({
            module: 'urlComplete',
            urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_TRANSFERENCIAS_AUTORIZACIONES}`,
          }),
        );
        break;
      case AccessHome.titleCom:
        navigate(`/${RoutesNames.ReceiptsPayments}`);
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <div className='card' id={titleCard === 'Comprobantes' ? 'vouchers' : ''}>
        <Icon style={iconClearBlue} h={10} w={8} as={handleIcon()} />
        <div className='insider'>
          <Text textStyle='h6' className='pad7'>
            {item.title}
          </Text>
          <Text textStyle='bodyLg'>{item.description}</Text>
        </div>
        <GoButton data-testid='goButton' className='arrow' onClick={() => handleGoTo()}>
          <Icon style={iconClearBlue} w={6} h={6} as={ChevronRight} />
        </GoButton>
      </div>
    </Container>
  );
}

export default CardItem;
