/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionItem, Grid, GridItem } from '@chakra-ui/react';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { IBillPaymentErrorGroup } from '../../interfaces/IAvailableBills';
import { Bill } from '../../interfaces/ISummary';
import { AlertBannerInformation, SummaryPaymentsTexts } from '../../text/GeneralText';
import CommonPage from '../CommonPage';
import EmptyStateScreen from '../EmptyStateScreen';
import SummaryBills from './component/SummaryBills';
import SummaryHeader from './component/SummaryHeader';
import SummarySubHeader from './component/SummarySubHeader';
import { Container, ContainerSummary } from './styled';

const MIN_BILLS_LENGTH = 0;

function SummaryPayments({ billsPaymentErrors, cartItems }: { billsPaymentErrors: IBillPaymentErrorGroup; cartItems: Bill[] }) {
  const navigate = useNavigate();
  const { setTemporalCartItems, setBillsPaymentErrors } = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);
  const hasSuccess = billsPaymentErrors.ok.length > MIN_BILLS_LENGTH;
  const hasErrorOrWarning =
    billsPaymentErrors.invalidForPay.length > MIN_BILLS_LENGTH || billsPaymentErrors.prepare.length > MIN_BILLS_LENGTH;

  const initialExpansionState = {
    ok: !hasErrorOrWarning && hasSuccess,
    prepare: hasErrorOrWarning,
    invalidForPay: hasErrorOrWarning,
  };
  const [isTabExpanded, setIsTabExpanded] = useState(initialExpansionState);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const isEmptyScreen = Object.keys(billsPaymentErrors).some(key => billsPaymentErrors[key].length > MIN_BILLS_LENGTH);
  const commonEmptyStateProps = {
    page: 'SUMMARY',
    hasButton: true,
    onRetry: () => {
      navigate(`/${routesNames.Home}`);
    },
  };

  const commonVoidStateProps = {
    page: 'SUMMARY_VOID',
    hasButton: true,
    onRetry: () => {
      navigate(`/${routesNames.Home}`);
    },
  };

  const handleHover = (isActiveHover: boolean) => setIsHovered(isActiveHover);

  useEffect(() => {
    if (
      (billsPaymentErrors?.prepare?.length > MIN_BILLS_LENGTH || billsPaymentErrors?.confectionError?.length > MIN_BILLS_LENGTH) &&
      billsPaymentErrors?.ok?.length === MIN_BILLS_LENGTH
    ) {
      setIsAlertVisible(true);
    }
    return () => {
      setTemporalCartItems([]);
      setBillsPaymentErrors(null);
    };
  }, []);

  const getBillsForCategory = (category: string, hashes: string[]) => {
    return cartItems.filter(item => hashes.includes(item.identifier)).map(filteredItem => filteredItem);
  };

  const categorizedBills = {
    ok:
      getBillsForCategory(
        'ok',

        billsPaymentErrors?.ok?.map(error => error.hash),
      ) || [],
    prepare:
      getBillsForCategory(
        'prepare',

        billsPaymentErrors?.prepare?.map(error => error.hash),
      ) || [],
    invalidForPay:
      getBillsForCategory(
        'invalidForPay',

        billsPaymentErrors?.invalidForPay?.map(error => error.hash),
      ) || [],
    confectionError:
      getBillsForCategory(
        'confectionError',

        billsPaymentErrors?.confectionError?.map(error => error.hash),
      ) || [],
  };

  const categorizedBillsPrepareLength = billsPaymentErrors?.prepare?.length;
  const categorizedBillsConfectionErrorLength = billsPaymentErrors?.confectionError?.length;
  const categorizedBillsInvalidForPayLength = billsPaymentErrors?.invalidForPay?.length;
  const categorizedBillsConfectionsOKLength = billsPaymentErrors?.ok?.length;
  const confectionErrorLength = (billsPaymentErrors?.confectionError?.length || 0) + (billsPaymentErrors?.prepare?.length || 0);

  useEffect(() => {
    if (isEmptyScreen) {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Pagos - Resumen',
      });
    }
  }, [billsPaymentErrors]);

  const toggleExpansion = (prevState, category: 'ok' | 'prepare' | 'invalidForPay') => ({
    ...prevState,
    [category]: !prevState[category],
  });

  const handleAccordionClick = (category: 'ok' | 'prepare' | 'invalidForPay') => {
    setIsTabExpanded(prevState => toggleExpansion(prevState, category));
  };

  return (
    <>
      {isEmptyScreen ? (
        <Grid templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={12}>
            <CommonPage subheader={SummaryPaymentsTexts.title1} text={SummaryPaymentsTexts.subHeader1} />
          </GridItem>
          {isAlertVisible ? (
            <GridItem maxWidth='100%' width='full' colStart={1}>
              <AlertComponent id='alert-bills' variant='destructive' width='auto' marginTop='10px' onClose={() => setIsAlertVisible(false)}>
                <AlertTitle width='full'>{AlertBannerInformation.error.errorOnSummaryPaymentTitle}</AlertTitle>
                <AlertDescription width='full'>{AlertBannerInformation.error.errorOnSummaryPaymentSubtitle}</AlertDescription>
              </AlertComponent>
            </GridItem>
          ) : null}
          <GridItem colStart={1}>
            {billsPaymentErrors?.ok?.length > MIN_BILLS_LENGTH && (
              <ContainerSummary>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem className='accordion-item' data-testid='accor-item' onClick={() => handleAccordionClick('ok')}>
                    {categorizedBillsConfectionsOKLength > MIN_BILLS_LENGTH ? (
                      <div
                        className={`container-header   container-header_outline ${isTabExpanded ? 'selected' : ''} ${
                          isHovered ? 'hovered' : ''
                        }`}
                        data-testid='accor-header'
                      >
                        <SummaryHeader
                          handleHover={handleHover}
                          isExpanded={isTabExpanded.ok}
                          isSuccess
                          variantBadge='success-light'
                          badgeText={SummaryPaymentsTexts.badgeSuccess}
                          title={SummaryPaymentsTexts.titleSuccess2(categorizedBillsConfectionsOKLength || 0)}
                          subTitle={SummaryPaymentsTexts.subtitle1}
                        />
                        {isTabExpanded.ok ? <SummaryBills summaryData={categorizedBills.ok} isLineVisible /> : null}
                      </div>
                    ) : null}
                  </AccordionItem>
                </Accordion>
              </ContainerSummary>
            )}

            <>
              {categorizedBillsConfectionErrorLength > MIN_BILLS_LENGTH || categorizedBillsPrepareLength > MIN_BILLS_LENGTH ? (
                <ContainerSummary>
                  <Accordion defaultIndex={[1]} allowMultiple>
                    <AccordionItem className='accordion-item' data-testid='accor-item' onClick={() => handleAccordionClick('prepare')}>
                      <div className='container-header container-header_outline'>
                        <SummaryHeader
                          handleHover={handleHover}
                          isExpanded={isTabExpanded.prepare}
                          isSuccess={false}
                          isOnlyToggle
                          variantBadge='error-light'
                          badgeText={SummaryPaymentsTexts.badgeError}
                          title={SummaryPaymentsTexts.titleError1(confectionErrorLength)}
                          subTitle={SummaryPaymentsTexts.subtitleError1}
                        />

                        {categorizedBillsConfectionErrorLength > MIN_BILLS_LENGTH ? (
                          <>
                            {' '}
                            <Container>
                              <SummarySubHeader
                                text={SummaryPaymentsTexts.title2(categorizedBillsConfectionErrorLength)}
                                textLink={SummaryPaymentsTexts.goToCart}
                                to={routesNames.PaymentCart}
                              />
                            </Container>
                            <SummaryBills isLineVisible summaryData={categorizedBills?.confectionError} />
                          </>
                        ) : null}

                        {categorizedBillsPrepareLength > MIN_BILLS_LENGTH ? (
                          <>
                            {' '}
                            <Container>
                              <SummarySubHeader
                                text={SummaryPaymentsTexts.title3(categorizedBillsPrepareLength)}
                                textLink={SummaryPaymentsTexts.goToPayments}
                                to={routesNames.Home}
                              />
                            </Container>
                            {isTabExpanded.prepare ? <SummaryBills summaryData={categorizedBills.prepare} isLineVisible /> : null}
                          </>
                        ) : null}
                      </div>
                    </AccordionItem>
                  </Accordion>
                </ContainerSummary>
              ) : null}

              {categorizedBillsInvalidForPayLength > MIN_BILLS_LENGTH ? (
                <ContainerSummary>
                  <Accordion defaultIndex={[2]} allowMultiple>
                    <AccordionItem
                      className='accordion-item'
                      data-testid='accor-item'
                      onClick={() => handleAccordionClick('invalidForPay')}
                    >
                      <div className='container-header container-header_outline'>
                        <SummaryHeader
                          handleHover={handleHover}
                          isExpanded={isTabExpanded.invalidForPay}
                          isOnlyToggle
                          isSuccess={false}
                          variantBadge='info-light'
                          badgeText={SummaryPaymentsTexts.badgeInfo}
                          title={SummaryPaymentsTexts.titleInfo(categorizedBillsInvalidForPayLength)}
                          subTitle={SummaryPaymentsTexts.subTitleInfo(categorizedBillsInvalidForPayLength)}
                        />
                        {isTabExpanded.invalidForPay ? <SummaryBills summaryData={categorizedBills.invalidForPay} isLineVisible /> : null}
                      </div>
                    </AccordionItem>
                  </Accordion>
                </ContainerSummary>
              ) : null}
            </>
          </GridItem>
        </Grid>
      ) : (
        <div className='emptyState'>
          {billsPaymentErrors.invalidForPay?.length === 0 &&
          billsPaymentErrors.prepare?.length === 0 &&
          billsPaymentErrors.ok?.length === 0 ? (
            <EmptyStateScreen {...commonVoidStateProps} variant='NO_DATA' onRetry={() => navigate(`/${routesNames.Home}`)} />
          ) : (
            <EmptyStateScreen {...commonEmptyStateProps} variant='NO_DATA' onRetry={() => navigate(`/${routesNames.Home}`)} />
          )}
        </div>
      )}
    </>
  );
}
export default SummaryPayments;
