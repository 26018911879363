/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
import { Tooltip } from '@chakra-ui/react';

import { IBill } from '../../interfaces/IAvailableBills';
import StatusTypes from '../../interfaces/IStatus';
import { ColumnsConfigTable } from '../../interfaces/ITableComponent';
import { ColumnsConfigText, TooltipTexts } from '../../text/GeneralText';
import { capitalizeFirst, transformStringToCurrency, transformStringToDate } from '../../utils/converters';
import { numericSort } from '../../utils/sorting';
import BillStatus from '../BillStatus';
import RowSelectCheckbox from '../Checkbox/RowSelectCheckbox';
import DrawerDetail from '../DrawerDetails';
import InputWithAmount from '../InputWithAmount';
import { CellTotalToPay } from './styled';

const validStates: string[] = [StatusTypes.AGREGADA_AL_CARRITO, StatusTypes.EN_PROCESO, StatusTypes.PAGADA];

export default (serviceAlias, operative): ColumnsConfigTable<IBill>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <RowSelectCheckbox
        {...{
          checked: !operative ? false : table.getIsAllRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
          isSomeSelected: table.getIsSomeRowsSelected(),
          disabled: !operative,
          id: 'head',
        }}
      />
    ),
    cell: ({ row }) => {
      return (
        <RowSelectCheckbox
          {...{
            checked: !operative ? false : row.getIsSelected() || validStates.includes(row.original.status),
            onChange: row.getToggleSelectedHandler(),
            disabled: !row.original.enabled || !operative,
          }}
        />
      );
    },
  },
  {
    accessorKey: 'status',
    id: 'Estado',
    cell: info => (
      <span>
        <BillStatus status={capitalizeFirst(info.getValue())} />
      </span>
    ),
    header: 'Estado',
    enableSorting: true,
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'reference',
    id: 'Código de factura',
    header: () => (
      <Tooltip placement='top' label={TooltipTexts.codigoDeFactura} hasArrow>
        <span>Código de factura</span>
      </Tooltip>
    ),
    enableSorting: true,
    cell: info => {
      const isCellEnabled = info.row.original.enabled;
      return <span className={`${!isCellEnabled ? 'isDisabled' : ''}`}>{info.getValue() as string}</span>;
    },
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'expiration_date_1',
    id: 'Vencimiento',
    header: 'Vencimiento',
    cell: info => {
      const isCellEnabled = info.row.original.enabled;
      return (
        <>
          {info && (
            <p className={`${!isCellEnabled ? 'isDisabled' : ''}`}>
              {info.getValue() ? transformStringToDate(info.getValue() as string) : ColumnsConfigText.sinVencimiento}
            </p>
          )}
        </>
      );
    },
    enableSorting: true,
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorFn: row => row.amount1?.toString(),
    id: 'Total',
    header: 'Total',
    cell: info => {
      const isCellEnabled = info.row.original.enabled;
      return (
        <CellTotalToPay>
          <p className={`${!isCellEnabled ? 'isDisabled' : ''}`}>{transformStringToCurrency(info.getValue() as string)}</p>
        </CellTotalToPay>
      );
    },
    enableSorting: true,
    align: 'end',
    sortingFn: numericSort,
  },
  {
    accessorFn: row => row.amount1?.toString(),
    id: 'A pagar',
    cell: info => (
      <InputWithAmount
        value={info.row.original.amount1}
        onChange={val => {
          /* eslint-disable no-param-reassign */
          info.row.original.amountToPay = val;
          info.table.resetColumnOrder();
        }}
        minAmount={info.row.original.amountMin}
        amountType={info.row.original.amountType}
        maxAmount={info.row.original.amountMax}
      />
    ),
    header: 'A pagar',
    enableSorting: true,
    classNameCell: 'alignStart paddingLeft16',
    align: 'start',
    className: 'paddingLeft16',
    sortingFn: numericSort,
  },
  {
    id: 'Detalle',
    header: '',
    align: 'center',
    cell: detalle => (
      <DrawerDetail
        title={`${detalle.row.original.companyName} ${serviceAlias ? ` - ${serviceAlias}` : ''}`}
        drawerDetail={detalle.row.original.detail}
        status={detalle.row.original.status}
        enabled={detalle.row.original.enabled}
      />
    ),
  },
];
