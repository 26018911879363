/* eslint-disable consistent-return */
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import { dateValueToStringYYYYMMHHSS } from './converters';

// ticket size
const pageWidth = 500;
const pageHeight = 630;

async function createPdf(isZipFile: boolean, voucher: Array<string>, voucherName?: string, voucherDate?: Date) {
  const pdfDoc = await PDFDocument.create();
  const dateFormatted = dateValueToStringYYYYMMHHSS(voucherDate);
  const page = pdfDoc.addPage([pageWidth, pageHeight]);
  const font = await pdfDoc.embedFont(StandardFonts.Courier);
  const downloadName = `${voucherName}_${dateFormatted}.pdf`;
  const lineHeight = 20;
  const sectionMargin = 40;

  let currentY = pageHeight - 30;

  voucher.forEach(line => {
    page.drawText(line, {
      x: 50,
      y: currentY,
      size: 10,
      font,
      color: rgb(0, 0, 0),
    });

    if (
      line.includes('Fecha') ||
      line.includes('Fecha:') ||
      line.includes('Original') ||
      line.includes('Cuota:') ||
      line.includes('TOTAL **') ||
      line.includes('** TOTAL **') ||
      line.includes('Cod.Seg') ||
      line.includes('ORIGINAL')
    ) {
      currentY -= sectionMargin;
    } else {
      currentY -= lineHeight;
    }
  });
  const pdfBytes = await pdfDoc.save();

  if (isZipFile) {
    return pdfBytes;
  }
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = downloadName;
  link.click();
}

export default createPdf;
