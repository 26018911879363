import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ZoomIn } from 'react-feather';

import { DrawerProps } from '../../interfaces/IDrawerProps';
import { body14, body14bold } from '../../styled/generalStyles';
import { TooltipTexts } from '../../text/GeneralText';
import { capitalizeFirst, transformStringToCurrency, transformStringToDate } from '../../utils/converters';
import BillStatus from '../BillStatus';
import { Btn, Container, DivTable, DrawerBody, icon, iconClose, P, Td } from './styled';

function DrawerDetail({ title, drawerDetail = [], enabled = true, status, drawerVariant }: DrawerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const isDate = (field: string): boolean => {
    return field.toLowerCase().includes('fecha');
  };

  const isCurrency = (field: string): boolean => {
    return field.toLowerCase().includes('importe');
  };

  return (
    <>
      <Btn>
        <Tooltip label={TooltipTexts.detalle} placement='left' hasArrow>
          {enabled ? (
            <button ref={btnRef} onClick={onOpen}>
              <Icon as={ZoomIn} style={icon} />
            </button>
          ) : (
            <Icon as={ZoomIn} style={icon} className='detail' />
          )}
        </Tooltip>
      </Btn>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef} size='lg' variant={drawerVariant}>
        <DrawerOverlay />
        <Container>
          <DrawerContent className='drawer'>
            <DrawerCloseButton style={iconClose} />
            <DrawerBody>
              <Box>
                <BillStatus status={capitalizeFirst(status)} noTooltip />
              </Box>
              <Text textStyle='h3' className='title'>
                {title}
              </Text>
              <DivTable>
                <TableContainer>
                  <Table variant='simple'>
                    <Tbody>
                      {drawerDetail.map(detail => (
                        <Tr key={detail.value}>
                          <Td>
                            <Box style={body14}>{detail.label}</Box>
                          </Td>
                          <Td>
                            <Box className='rightAlign' style={body14bold}>
                              {(isDate(detail.label) && <P>{transformStringToDate(detail.value)}</P>) ||
                                (isCurrency(detail.label) && <P>{transformStringToCurrency(detail.value)}</P>) || <P>{detail.value}</P>}
                            </Box>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </DivTable>
            </DrawerBody>
          </DrawerContent>
        </Container>
      </Drawer>
    </>
  );
}

export default DrawerDetail;
