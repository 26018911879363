import styled from 'styled-components';

import { colors, fonts, sizeFont } from '../../styled/generalStyles';

export const Container = styled.div`
  padding: 1px 0px 25px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .favorite {
    text-align: center;
    margin: auto;
    margin-top: 25px;
  }
  .nothing {
    text-align: center;
    margin-top: 10px;
    font-family: ${fonts.headingBold};
    font-size: ${sizeFont.fontSize24};
  }
  .sub {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize14};
    text-align: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .favoritesContainer {
    margin-top: 12px;
    .btnMenuIcon {
      background-color: ${colors.transparent};
    }
    .btnMenuIcon :hover {
      background-color: ${colors.transparent};
    }
    .btnMenuIcon :active {
      background-color: ${colors.transparent};
    }
  }

  .marginTop10 {
    margin-top: 10px;
  }

  .snackbar {
    position: fixed;
    bottom: 40px;
    margin-left: 0px;
  }

  .gridSkeleton {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    border-radius: 12px;
    margin-bottom: 16px;
    grid-gap: 10px;
  }
`;

export const CardSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 5px;
`;

export const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export default { Container, CardSubtitle, CardTitle };
