import { useContext } from 'react';

import SummaryPayments from '../../components/SummaryHome';
import AppContext from '../../context/Provider';

function Summary() {
  const { billsPaymentErrors, temporalCartItems } = useContext(AppContext);
  return (
    <SummaryPayments
      billsPaymentErrors={billsPaymentErrors || { confectionError: [], invalidForPay: [], ok: [], prepare: [] }}
      cartItems={temporalCartItems || []}
    />
  );
}
export default Summary;
