/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import React, { memo, useContext, useRef, useState } from 'react';
import { Trash } from 'react-feather';

import BewareImage from '../../../assets/images/Beware';
import AppContext from '../../../context/Provider';
import useChakraToast from '../../../hooks/useChakraToast';
import { IBill } from '../../../interfaces/ICarts';
import { PropsCheckoutListDrawer } from '../../../interfaces/ICheckoutListDrawer';
import { deleteCarts } from '../../../services/api';
import { AliasDeleteModalText, CartSummaryTexts, ConfirmationModalTexts, ToastTexts } from '../../../text/GeneralText';
import { transformNumberToCurrency, transformStringToDate } from '../../../utils/converters';
import BadgeState from '../../BillStatus/index';
import ConfirmationModal from '../../ConfirmationModal';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import {
  CenteredContainer,
  Container,
  ContainerModalTextDrawerCheckoutDelete,
  ContainerSup,
  styledModalContentCheckoutDrawer,
} from './styled';

const MAX_CHARACTER_LARGE_MODAL = 80;
function CheckoutListDrawer({ onClose, summaryTotalInArray, cartItems, billsToPay, isOpen }: PropsCheckoutListDrawer) {
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const billRef = useRef<IBill>(null);
  const [inPromise, setInPromise] = useState(false);
  const { ToastUtilities } = useChakraToast();

  const handleOpenDeleteBill = (bill: IBill): void => {
    ToastUtilities.closeAll();
    onOpenDelete();
    billRef.current = bill;
  };
  const lengthModalWords = ConfirmationModalTexts.delete.bill.subTitleCompany(
    billRef.current?.company_name,
    billRef.current?.alias_name,
  ).length;

  const { getCartData } = useContext(AppContext);
  const subtitleCompanyAlias = ConfirmationModalTexts.delete.bill.subTitleCompany(
    billRef.current?.company_name,
    billRef.current?.alias_name,
  );

  const hasManyBills = detail => detail?.bills?.length > 1 || cartItems?.length > 1;

  const handlePromises = async (): Promise<void> => {
    await deleteCarts({ items: billRef.current.identifier });

    await getCartData();
  };
  const handleActions = async (): Promise<void> => {
    try {
      ToastUtilities.closeAll();
      setInPromise(true);
      await handlePromises();
      ToastUtilities.success({ id: 'toast-id', description: ToastTexts.successMessage1 });
    } catch {
      ToastUtilities.error({
        description: ToastTexts.errorMessage2,
        toastOptions: {
          ctaLabel: ToastTexts.ctaLabel1,
          ctaOnClick: () => {
            handleActions().then();
          },
        },
      });
    } finally {
      setInPromise(false);
    }
  };
  const expirationDate = (date: string): string => {
    if (!date || date === 's/vto') {
      return '-';
    }
    return transformStringToDate(date);
  };
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerOverlay />
      <Container>
        <DrawerContent className='drawer' data-testid='drawer-container'>
          <DrawerCloseButton data-testid='drawer-close' />
          <DrawerHeader>
            <Text textStyle='h3' className='title'>
              {CartSummaryTexts.titleDrawer}
            </Text>
            <Text textStyle='h5' className='sub-title'>
              {billsToPay}
            </Text>
            <Text className='total'>
              {CartSummaryTexts.labelTotal}
              <span>
                {summaryTotalInArray[0]} <ContainerSup>{summaryTotalInArray[1]}</ContainerSup>
              </span>
            </Text>
          </DrawerHeader>
          <DrawerBody className='container-table'>
            <Table variant='simple'>
              {cartItems?.map((detail, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`DrawerDetail_${detail.name}_${index}`}>
                  <Thead>
                    <Tr>
                      <Th colSpan={3}>{detail.name}</Th>
                      <Th colSpan={2} textAlign='end'>
                        {CartSummaryTexts.getBillAmount(detail?.items?.length)}
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {detail?.items?.map(bill => {
                      return (
                        <Tr key={`detailBill_${bill.identifier}`}>
                          <Td>
                            <BadgeState status={bill.status} />
                          </Td>
                          <Td className='aliasName'>
                            <TextEllipsisWithTooltip placement='top' text={bill.alias_name} style={{ fontSize: '14px' }}>
                              <span>{bill.alias_name}</span>
                            </TextEllipsisWithTooltip>
                            <TextEllipsisWithTooltip placement='top' text={bill.client_id} style={{ fontSize: '12px' }}>
                              <span>{bill.client_id}</span>
                            </TextEllipsisWithTooltip>
                          </Td>

                          <Td style={{ fontSize: '14px' }}>{expirationDate(bill.expiration_date_1)}</Td>
                          <Td textAlign={`${hasManyBills(detail) ? 'start' : 'end'}`} style={{ fontSize: '14px' }}>
                            {transformNumberToCurrency(bill.amount)}
                          </Td>
                          {hasManyBills(detail) && (
                            <Td textAlign='end'>
                              <IconButtonComponent
                                name={detail.name}
                                aria-label='icon-button-trash'
                                variant='icon-text'
                                size='i-md'
                                data-testid='button-delete'
                                onClick={() => handleOpenDeleteBill(bill)}
                                disabled={inPromise}
                                icon={<Trash />}
                              />
                            </Td>
                          )}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </React.Fragment>
              ))}
            </Table>
          </DrawerBody>
        </DrawerContent>
      </Container>
      <ConfirmationModal
        modalSize={lengthModalWords > MAX_CHARACTER_LARGE_MODAL ? 'xl' : 'lg'}
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        ModalContentCss={styledModalContentCheckoutDrawer}
        fn={handleActions}
        cancelText={AliasDeleteModalText.cancelButton}
      >
        <div>
          <CenteredContainer>
            <div className='img'>
              <BewareImage />
            </div>
          </CenteredContainer>

          <Text textAlign='center' textStyle='h3'>
            {ConfirmationModalTexts.delete.bill.title2}
          </Text>

          <ContainerModalTextDrawerCheckoutDelete>
            <Text marginTop='1rem' textAlign='center' textStyle='bodyMd'>
              {subtitleCompanyAlias}
            </Text>

            <Text marginTop='20px' textAlign='center' textStyle='bodyMd'>
              {ConfirmationModalTexts.delete.bill.subtitle4}
              <Box marginLeft='4px' as='span' textStyle='bodyMdSemi'>
                {ConfirmationModalTexts.delete.bill.subtitle5}
              </Box>
            </Text>
          </ContainerModalTextDrawerCheckoutDelete>
        </div>
      </ConfirmationModal>
    </Drawer>
  );
}

export default memo(CheckoutListDrawer);
