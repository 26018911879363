export default () => {
  const date = new Date();

  const day = `00${date.getUTCDate()}`;
  const month = `00${date.getUTCMonth() + 1}`;
  const hour = `00${date.getUTCHours()}`;
  const minute = `00${date.getUTCMinutes()}`;
  const second = `00${date.getUTCSeconds()}`;

  const aliasName = `ID ${date.getFullYear()}${month.slice(-2)}${day.slice(-2)}${hour.slice(-2)}${minute.slice(-2)}${second.slice(-2)}`;

  return aliasName;
};
