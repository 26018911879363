import { Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { SummarySubHeaderProps } from '../../../interfaces/ISummary';

// eslint-disable-next-line react/prop-types
function SummarySubHeader({ text, to, textLink }: SummarySubHeaderProps) {
  return (
    <div className='container-gray-header container-gray-header_paddings container-gray-header_paddings'>
      <div className='flex items-center justify-between flex-row'>
        <Text textStyle='titleMdBold'>{text}</Text>
        <Link className='link' to={`/${to}`}>
          {textLink}
        </Link>
      </div>
    </div>
  );
}

export default SummarySubHeader;
