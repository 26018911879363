import styled from 'styled-components';

import { colors, fonts, sizeFont } from '../../styled/generalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  justify-content: space-between;

  .alertConfig {
    margin-top: 10px;
  }

  .title {
    font-family: ${fonts.headingBold};
    font-size: ${sizeFont.fontSize28};
    line-height: 36px;
    text-align: left;
    margin-top: 30px;
  }

  .description {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize14};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin: 10px 0px 12px 0px;
  }

  .picture {
    text-align: center;
    margin: auto;
    margin-top: 25px;
  }

  .empty-sub {
    text-align: center;
    margin-top: 10px;
    font-family: ${fonts.headingBold};
    font-size: ${sizeFont.fontSize24};
  }

  .empty-desc {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize16};
    text-align: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .empty-create-button {
    display: flex;
    justify-content: center;
  }

  .button-create-payment:hover {
    opacity: 0.9;
  }

  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .snackbar {
    position: fixed;
    bottom: 75px;
    margin-left: 0px;
  }
`;

export const Button = styled.div`

  font-size: ${sizeFont.fontSize14};
  font-weight: 700;
  background: ${colors.blue};
  border-radius: 8px;
  color: ${colors.wh};
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 20px
  text-underline-offset: 4px;
  padding: 10px 16px;
  width: 105px;
`;

export const icon = {
  width: '20px',
  height: '20px',
  boxShadow: 'none',
  background: 'none',
  color: '#049ACA',
  marginRight: '10px',
};

export default { Container, Button };
