import { Accordion } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Cart } from '../../interfaces/ICarts';
import AccordionCompanyItem from './AccordionCompanyItem';
import AccordionContainer from './styled';

function AccordionCart({ companies, snackbarAction }: Cart) {
  const prevPage = useLocation();

  const renderCartItem = () => {
    return companies.map(item => {
      return (
        <AccordionCompanyItem
          key={item.name}
          name={item.name}
          total={item.total}
          bills={item.items}
          snackbarAction={(company, success, type) => snackbarAction(company, success, type)}
        />
      );
    });
  };

  return (
    <AccordionContainer>
      {prevPage.state === 'factura' ? (
        <Accordion defaultIndex={[0]} className={`accordion ${companies.length === 1 ? 'one-item' : ''}`} allowMultiple>
          {renderCartItem()}
        </Accordion>
      ) : (
        <Accordion className={`accordion ${companies.length === 1 ? 'one-item' : ''}`} allowMultiple>
          {renderCartItem()}
        </Accordion>
      )}
    </AccordionContainer>
  );
}

export default AccordionCart;
