export interface ComponentProps {
  value: number;
  minAmount: string;
  amountType: string;
  maxAmount: string;
  onChange: (val) => void;
}

export enum AmountTypes {
  ABIERTO = 'ABI',
}
