import { useEffect, useState } from 'react';

import { IAccess } from '../../interfaces/Permissions';
import { getPermissions } from '../../services/api';
import { AccessHome } from '../../text/GeneralText';
import CardItem from './CardItem';
import { columnOdd, columnOne, columnPair, GlobalContain, Wizard } from './styled';

function CardHome() {
  const [access, setAccess] = useState([]);
  useEffect(() => {
    const list: IAccess[] = [
      {
        title: AccessHome.titleFav,
        description: AccessHome.descriptionFav,
      },
    ];
    getPermissions()
      .then(res => {
        if (res.data.transferenciasAutorizacion) {
          list.push({
            title: AccessHome.titleAuth,
            description: AccessHome.descriptionAuth,
          });
        }
        list.push({
          title: AccessHome.titleCom,
          description: AccessHome.descriptionCom,
        });
        setAccess(list);
      })
      .catch(() => {
        list.push({
          title: AccessHome.titleCom,
          description: AccessHome.descriptionCom,
        });
        setAccess(list);
      });
  }, []);

  const items = access.length;
  const numberColumns = number => {
    switch (number) {
      case 1:
        return columnOne;
      case number % 2 === 0:
        return columnPair;
      case 3:
        return columnOdd;
      default:
        return columnPair;
    }
  };
  return (
    <Wizard id='shortcuts'>
      <GlobalContain style={numberColumns(items)}>
        {access.map(item => {
          return <CardItem item={item} key={item.title} titleCard={item.title} />;
        })}
      </GlobalContain>
    </Wizard>
  );
}

export default CardHome;
