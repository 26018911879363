import { ModalContentProps } from '@chakra-ui/react';
import styled from 'styled-components';

import deleteIcon from '../../assets/images/deleteIcon.svg';
import { boxShadows, colors, sizeFont } from '../../styled/generalStyles';

const AccordionContainer = styled.div`
  display: grid;
  margin-top: 24px;
  background: ${colors.white};

  .accordion {
    border-top-width: none;
    border-color: ${colors.white};
  }

  .accordion-header {
    display: grid;
    grid-template-columns: 9fr 2fr 0.5fr;
    gap: 40px;
    height: 85px;
    padding: 8px 16px;
    border-radius: none;
    outline: 1px ${colors.LavenderGray} solid;
  }

  .accordion-header.selected {
    background: ${colors.AzureishWhite};
    box-shadow: ${boxShadows.grayAccordion};
    height: 85px;
  }

  .accordion-header.selected.hovered {
    background: ${colors.OuterSpace};
  }

  .accordion-header.hovered {
    background: ${colors.OuterSpace};
  }

  .accordion-button-arrow.selected {
    background: ${colors.AliceBlue};
  }

  .accordion-item:last-child .accordion-header {
    box-shadow: ${boxShadows.grayAccordion};
  }

  .accordion-item:last-child .accordion-header.selected {
    border-radius: 0px;
    box-shadow: ${boxShadows.grayAccordion};
  }

  .accordion-button-arrow {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    font-size: ${sizeFont.fontSize24};
    border-radius: 8px;
  }

  .accordion-button-arrow:focus {
    box-shadow: none;
  }

  .accordion-button-arrow:hover {
    background: ${colors.Gainsboro};
  }

  .accordion-buttons {
    display: flex;
    align-items: center;
    color: ${colors.blue};
    gap: 12px;
  }

  .flexEnd {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;
  }

  .accordion-button-delete {
    background: url(${deleteIcon}) no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .flexCenter {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`;

export const styledModalContentDeleteCompany: ModalContentProps['css'] = {
  maxWidth: '400px',
};

export default AccordionContainer;
