import { Box, Grid as ChakraGrid } from '@chakra-ui/react';
import styled from 'styled-components';

import { colors, sizeFont } from '../../styled/generalStyles';

export const Container = styled.div`
  margin-top: 12px;
`;

export const ContainerSummary = styled.div`
  display: grid;
  margin-top: 24px;
  background: ${colors.white};
  border: none;
  .container-header {
    border-radius: 12px;
    padding-bottom: 20px;
  }
  .container-header_outline {
    outline: 1px ${colors.gray5} solid;
  }
  .container-header_grid {
    display: grid;
    grid-template-columns: 12fr 2fr;
    gap: 20px;
    padding: 20px 12px;
    min-width: 930px;
  }
  .container-gray-header {
    display: grid;
    grid-template-columns: 1fr;
    height: 44px;
    background-color: ${colors.gray7};
  }

  .container-gray-header_paddings {
    padding: 0 24px;
  }
  .accordion-header.selected.hovered {
    background: ${colors.OuterSpace};
    border: none;
  }

  .accordion-header.hovered {
    background: ${colors.OuterSpace};
  }

  .accordion-button-arrow.selected {
    background: ${colors.AliceBlue};
  }

  .accordion-button-arrow {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    font-size: ${sizeFont.fontSize24};
  }

  .accordion-button-arrow:focus {
    box-shadow: none;
  }

  .accordion-button-arrow:hover {
    background: ${colors.Gainsboro};
  }

  .accordion-buttons {
    display: flex;
    align-items: center;
    color: ${colors.blue};
    gap: 12px;
  }
  .justify-content-end {
    justify-content: end;
  }
  .link {
    color: var(--Primary-100, var(--Color-Primary-100, #2172dc));
    text-align: center;

    /* Label/label.lg.bold.underline */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    text-decoration-line: underline;
  }

  .titleLgBold {
    color: var(--Grey-120, #2f3034);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }
  .chakra-accordion__item:first-of-type {
    border-top-width: 0;
  }

  .chakra-accordion__item:last-of-type {
    border-bottom-width: 0;
  }

  /* Flex Container */
  .flex {
    display: flex;
  }

  /* Justify Content */
  .justify-start {
    justify-content: flex-start;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  /* Align Items */
  .items-start {
    align-items: flex-start;
  }

  .items-end {
    align-items: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-stretch {
    align-items: stretch;
  }

  /* Flex Direction */
  .flex-row {
    flex-direction: row;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-col {
    flex-direction: column;
  }

  .flex-col-reverse {
    flex-direction: column-reverse;
  }
`;

export const StyledGrid = styled(ChakraGrid)<{ isLast?: boolean; isLineVisible?: boolean; index?: number }>`
  padding: 12px 55px 12px 22px;
  border-top: ${({ isLineVisible, index }) => (isLineVisible && index === 0 ? '1px solid #C7C6CA' : 'none')};
  border-bottom: 1px solid #c7c6ca;
  grid-template-columns: repeat(3, 1fr);
`;

export const ContainerGridSummaryBills = styled.div`
  padding: 20px 0;
`;
// flex flex-col justify-start items-start
export const StyledFlexText = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
