import Binoculars from '../../assets/images/Binoculars';
import Disconected from '../../assets/images/Disconected';
import NoAccess from '../../assets/images/NoAccess';
import NoResultFence from '../../assets/images/NoResultsFence';
import {
  Buttons,
  CheckoutTexts,
  ConnectionError,
  DataCartInformation,
  DataExpirationHome,
  DataHomeInformation,
  GoCreatePaymentButtonTexts,
  noData,
  PaymentReceiptsText,
  SummaryPaymentsTexts,
  SystemUnavailable,
} from '../../text/GeneralText';

export default {
  FAVORITES: {
    NO_DATA: {
      title: noData.title,
      subtitle: DataHomeInformation.subFavoriteEmpty,
      picture: <Binoculars />,
      hasButton: false,
    },
    ERROR_CONN: {
      title: ConnectionError.title,
      subtitle: ConnectionError.subtitle,
      picture: <Disconected />,
      hasButton: true,
      buttonLabel: Buttons.retryConnection,
    },
  },
  PAYMENT_RECEIPTS: {
    NO_DATA: {
      title: PaymentReceiptsText.paymentReceiptsEmpty,
      subtitle: PaymentReceiptsText.paymentReceiptsEmptySub,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: PaymentReceiptsText.paymentEmptyButton,
    },
    ERROR_CONN: {
      title: ConnectionError.title,
      subtitle: ConnectionError.subtitle,
      picture: <Disconected />,
      hasButton: true,
      buttonLabel: Buttons.retryConnection,
    },
  },
  CART: {
    NO_DATA: {
      title: DataCartInformation.subtitle,
      subtitle: DataCartInformation.emptyDescription,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: DataCartInformation.createPaymentLink,
    },
  },
  SERVICE_PAYMENT: {
    ERROR_CONN: {
      title: ConnectionError.title,
      subtitle: ConnectionError.subtitle,
      picture: <Disconected />,
      hasButton: true,
      buttonLabel: Buttons.retryConnection,
    },
    SYSTEM_UNAVAILABLE: {
      title: SystemUnavailable.title,
      subtitle: SystemUnavailable.subtitle,
      picture: <Disconected />,
      hasButton: true,
      buttonLabel: Buttons.retryConnection,
    }
  },
  HOME: {
    NO_ACCESS: {
      title: DataExpirationHome.displayPage,
      subtitle: DataExpirationHome.licence,
      picture: <NoAccess />,
      hasButton: false,
    },
    NO_DATA: {
      title: DataExpirationHome.noDataTitle,
      subtitle: DataExpirationHome.noDataSubtitle,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: DataExpirationHome.noDataButtonLink,
    },
    ERROR_CONN: {
      title: ConnectionError.title,
      subtitle: ConnectionError.subtitle,
      picture: <Disconected />,
      hasButton: true,
      buttonLabel: Buttons.retryConnection,
    },
  },
  SUMMARY: {
    NO_DATA: {
      title: SummaryPaymentsTexts.summaryPaymentsEmpty,
      subtitle: SummaryPaymentsTexts.summaryPaymentsEmptySub,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: SummaryPaymentsTexts.goToPayments,
    },
  },
  SUMMARY_VOID: {
    NO_DATA: {
      title: SummaryPaymentsTexts.summaryPaymentsEmpty,
      subtitle: SummaryPaymentsTexts.summaryPaymentsEmptySub,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: SummaryPaymentsTexts.goToPayments,
    },
  },
  CHECKOUT: {
    NO_ACCESS: {
      title: CheckoutTexts.checkoutTextsEmptyNoAccess,
      subtitle: CheckoutTexts.checkoutTextsEmptyNoAccesSub,
      picture: <NoResultFence />,
      hasButton: true,
      buttonLabel: CheckoutTexts.goToHome,
    },
    NO_DATA: {
      title: CheckoutTexts.checkoutTextsEmptyNoData,
      subtitle: CheckoutTexts.checkoutTextsEmptyNoDataSub,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: CheckoutTexts.goToAccount,
    },
    ACCESS_NO_DATA: {
      title: CheckoutTexts.checkoutTextsEmptyAccessNoData,
      subtitle: CheckoutTexts.checkoutTextsEmptyAccessNoDataSub,
      picture: <Binoculars />,
      hasButton: true,
      buttonLabel: CheckoutTexts.goToPayments,
    },
    ERROR_CONN: {
      title: CheckoutTexts.checkoutTextsEmptyErrorConect,
      subtitle: CheckoutTexts.checkoutTextsEmptyErrorConectSub,
      picture: <NoResultFence />,
      hasButton: true,
      buttonLabel: CheckoutTexts.retryCheckout,
    },
  },
};
