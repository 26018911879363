import { Home, Icon, Star } from 'react-feather';

import { AliasModalText } from '../../text/GeneralText';

export interface AliasModalConfType {
  icon: Icon;
  title: string;
  method: string;
  inputLabel: string;
  label?: string;
}

export const AliasModalConf = {
  create: {
    icon: Star,
    method: 'createServiceAlias',
    title: AliasModalText.title,
    inputLabel: AliasModalText.inputLabelCreate,
    label: AliasModalText.LabelCreate,
  },
  edit: {
    icon: Home,
    method: 'updateServiceAlias',
    title: AliasModalText.titleEdit,
    inputLabel: AliasModalText.inputLabelEdit,
  },
};
