import styled from 'styled-components';

export const Container = styled.div`
  .head {
    padding-top: 22px;
    padding-bottom: 10px;
  }
`;

export default Container;
