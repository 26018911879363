import styled from 'styled-components';

import { borders, colors, labelBold } from '../../styled/generalStyles';

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 72px;
  margin-left: -120px;
  box-shadow: ${borders.grayBorder};
  background-color: ${colors.white};
  z-index: 2;

  .heightGrid {
    height: 72px;
    display: flex;
    justify-content: space-between;
  }

  .buttonRight {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 80px;
    & > button > div {
      font-family: ${labelBold.fontFamily};
    }
  }

  .buttonLeft {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 100px;
    :focus {
      background: none;
    }
  }

  .margin15 {
    margin-right: 15px;
  }

  .tooltipButton {
    margin-right: 15px;
  }

  .marginTotal {
    margin: 0px 20px 0px 30px;
  }
`;

export { borders, Container };
