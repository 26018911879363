import { Icon } from '@chakra-ui/react';
import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import { IColumnSortProp } from '../../interfaces/IColumnSortProp';

function ColumnSort({ onSort, children }: IColumnSortProp) {
  return (
    <div style={{ display: 'flex' }}>
      {children}
      <div className='flexColumn'>
        <button className='flexIcon' onClick={() => onSort('asc')}>
          <Icon w={4} h={4} as={ChevronUp} data-testid='order-up' />
        </button>
        <button className='flexIcon' onClick={() => onSort('desc')}>
          <Icon w={4} h={4} as={ChevronDown} data-testid='order-down' />
        </button>
      </div>
    </div>
  );
}
export default ColumnSort;
