import { Button, Icon, useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';
import { MinusCircle } from 'react-feather';

import Beware from '../../assets/images/Beware';
import AppContext from '../../context/Provider';
import { deleteCarts } from '../../services/api';
import { AliasDeleteModalText, Buttons, ConfirmationModalTexts } from '../../text/GeneralText';
import { styledModalContentDeleteCompany } from '../AccordionCart/styled';
import ConfirmationModal from '../ConfirmationModal';
import { PaddingModal, SubTitle, Title } from '../ConfirmationModal/styled';

function EmptyCartButton() {
  const { getCartData, setCartFull, setIsLoadingScreen, cartItems } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleEmptyCart = () => {
    const hashList = { items: cartItems.flatMap(companyCart => companyCart.items.map(bill => bill.identifier)) };
    deleteCarts(hashList)
      .then(() => {
        setCartFull(false);
        getCartData();
      })
      .finally(() => {
        setIsLoadingScreen(true);
      });
  };
  const handleOpenModal = (): void => {
    onOpen();
  };
  const renderModal = () => {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        ModalContentCss={styledModalContentDeleteCompany}
        fn={handleEmptyCart}
        cancelText={AliasDeleteModalText.cancelButton}
      >
        <PaddingModal>
          <div className='img'>
            <Beware />
          </div>
          <Title>{ConfirmationModalTexts.empty.cart.title}</Title>
          <SubTitle>{ConfirmationModalTexts.empty.cart.subTitle}</SubTitle>
        </PaddingModal>
      </ConfirmationModal>
    );
  };

  return (
    <Button size='md' variant='error-text' onClick={handleOpenModal} rightIcon={<Icon as={MinusCircle} />}>
      {Buttons.emptyCart}
      {renderModal()}
    </Button>
  );
}

export default EmptyCartButton;
