/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/extensions
import './styleCalendar.css';

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import es from 'date-fns/locale/es';
import React, { useContext, useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AlertCircle, Calendar, X } from 'react-feather';

import AppContext from '../../context/Provider';
import useFormValidation from '../../hooks/useFormValidation';
import { IFormField, IFormFieldsProps } from '../../interfaces/IModalityForm';
import { labelTextBlue } from '../../styled/generalStyles';
import { FormRequestError } from '../../text/GeneralText';
import SelectComponent from '../SelectComponent';
import { buttonX, errorMessage, FormStyles, iconAlert, iconGray, iconRed, inputText, inputTextInvalid } from './styled';

registerLocale('lang', es);

function ModalitiesFormFields({ data, formOutput, isRequestError }: IFormFieldsProps) {
  const { modalitiesForm, setModalitiesForm, setIsButtonAvailable } = useContext(AppContext);
  const {
    form,
    handleChange,
    handleBlur,
    handleDateChange,
    handleComboChange,
    handleBlurCombo,
    handleFocusCombo,
    handleBlurDate,
    handleFocus,
    handleFocusDate,
    handleClick,
  } = useFormValidation();
  const inputRef = useRef();

  useEffect(() => {
    formOutput(form);
  }, [form, formOutput]);

  useEffect(() => {
    const fields = Object.values(modalitiesForm.fields).filter(el => el !== '');
    if (data && data.form?.length === fields.length) {
      setIsButtonAvailable(true);
    } else {
      setIsButtonAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, modalitiesForm]);

  useEffect(() => {
    if (data) {
      setModalitiesForm({ ...modalitiesForm, type: data.paymentType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function setInputGroup(val) {
    let field;
    if (val.component === 'TEXT_FIELD' || val.component === 'EDI' || val.component === 'HIDDEN_TEXT_FIELD' || val.component === 'CEO') {
      if (val.datatype === 'ALF' || val.datatype === 'CBA') {
        field = inputFromType(val, 'text');
      }
      if (val.datatype === 'IMP') {
        field = inputFromType(val, 'text-decimal');
      }
      if (val.datatype === 'FEC') {
        field = inputFromType(val, 'FEC');
      }
    }
    if (val.component === 'COMBO_BOX_ESTATICO' || val.component === 'COM') {
      field = inputFromType(val, 'combo');
    }
    return <div>{field}</div>;
  }

  const inputFromType = (val, type: string) => {
    const value = modalitiesForm.fields[val.field] !== undefined ? modalitiesForm.fields[val.field] : '';
    switch (type) {
      case 'FEC':
        return (
          <InputGroup>
            <FormControl isInvalid={isRequestError}>
              <FormHelperText id={val.field} style={labelTextBlue} className='helperText' />
              {isRequestError ? (
                <InputLeftElement style={iconAlert}>
                  <Icon id={val.position} className='iconCalendarRed' h={5} w={5} as={Calendar} />
                </InputLeftElement>
              ) : (
                <InputLeftElement style={iconAlert}>
                  <Icon id={val.position} className='iconGray' h={5} w={5} as={Calendar} />
                </InputLeftElement>
              )}
              <DatePicker
                portalId='root-portal'
                wrapperClassName={isRequestError ? 'datePickerError' : 'datePicker'}
                dateFormat='dd/MM/yyyy'
                placeholderText={val.label}
                selected={form[val.field]}
                onChange={e => {
                  handleDateChange(e, val.field, val);
                }}
                name={val.field}
                formatWeekDay={(day: string) => day.substring(0, 1)}
                locale='lang'
                calendarStartDay={0}
                onBlur={e => {
                  handleBlurDate(e, val);
                }}
                onFocus={e => {
                  handleFocusDate(e, val);
                }}
                value={value}
                id={val.position}
                autoComplete='off'
                popperPlacement='top-end'
                onKeyDown={e => {
                  e.preventDefault();
                }}
              />
              {isRequestError ? (
                InputAndMessage(val.position)
              ) : (
                <InputRightElement className='buttonClean'>
                  <Button onClick={() => handleClick(val.field)} style={buttonX} size='md'>
                    <Icon style={iconGray} h={6} w={6} as={X} />
                  </Button>
                </InputRightElement>
              )}
            </FormControl>
          </InputGroup>
        );
      case 'combo':
        return (
          <InputGroup>
            <FormControl key={val.position} isInvalid={isRequestError}>
              <FormHelperText id={val.field} style={labelTextBlue} className='helperText' />
              <SelectComponent
                name={val.field}
                valueOptions={val.values}
                handle={handleComboChange}
                placeholder={val.label}
                handleBlur={handleBlurCombo}
                handleFocus={handleFocusCombo}
              />
              {isRequestError && (
                <FormErrorMessage id={val} style={errorMessage} textStyle='body'>
                  {FormRequestError.text}
                </FormErrorMessage>
              )}
            </FormControl>
          </InputGroup>
        );
      default:
        return (
          <InputGroup>
            <FormControl key={val.position} isInvalid={isRequestError}>
              <FormHelperText id={val.field} style={labelTextBlue} className='helperText' />
              <Input
                sx={inputText}
                ref={inputRef}
                id={val.label}
                type={type}
                placeholder={val.label}
                name={val.field}
                onChange={handleChange}
                onBlur={e => handleBlur(e, val)}
                _invalid={inputTextInvalid}
                onFocus={e => handleFocus(e, val)}
                maxLength={val.length}
                value={value}
                autoComplete='off'
              />
              {isRequestError ? InputAndMessage(val.position) : null}
            </FormControl>
          </InputGroup>
        );
    }
  };

  return (
    <Grid templateColumns='repeat(12, 1fr)' columnGap={4} rowGap={0}>
      {data &&
        data?.form?.map((el: IFormField) => (
          <GridItem key={el.field} colSpan={4} className='positionGrid'>
            <FormStyles>{setInputGroup(el)}</FormStyles>
          </GridItem>
        ))}
    </Grid>
  );
}
export default ModalitiesFormFields;

function InputAndMessage(val) {
  return (
    <>
      <InputRightElement style={iconAlert}>
        <Icon style={iconRed} h={5} w={5} as={AlertCircle} />
      </InputRightElement>
      <FormErrorMessage id={val} style={errorMessage} textStyle='body'>
        {FormRequestError.text}
      </FormErrorMessage>
    </>
  );
}
