import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components';

import { colors, fonts, sizeFont } from '../../styled/generalStyles';

export const inputText = {
  height: '40px',
  background: `${colors.white}`,
  border: '1px solid',
  borderColor: `${colors.light}`,
  borderRadius: '4px',
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '::placeholder': {
    color: `${colors.light}`,
  },
  ':focus': {
    zIndex: '-1',
    borderColor: `${colors.blue}`,
  },
  ':hover': {
    borderColor: `${colors.textgray}`,
  },
};

export const inputTextInvalid = {
  background: `${colors.white}`,
  border: '2px solid',
  borderColor: `${colors.red}`,
  borderRadius: '4px',
  ':focus': {
    borderColor: `${colors.red}`,
    boxShadow: 'none',
  },
  ':hover': {
    borderColor: `${colors.red}`,
  },
};

export const iconRed = {
  color: `${colors.red}`,
  background: `${colors.white}`,
};

export const iconGray = {
  color: `${colors.lightgray}`,
};

export const buttonX = {
  background: `${colors.transparent}`,
  border: 'none',
};

export const iconAlert = {
  marginTop: '22px',
};

export const iconClear = {
  cursor: 'pointer',
};
export const iconClearBlue = {
  color: `${colors.blue}`,
};

export const errorMessage = {
  color: `${colors.red}`,
  fontFamily: `${fonts.roboto}`,
  fontSize: `${sizeFont.fontSize12}`,
  letterSpacing: '0.4px',
  paddingLeft: '10px',
  marginTop: '3px',
};

export const marginForm = {
  marginBottom: '30px',
};

export const FormStyles = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .buttonClean {
    margin-top: 22px;
    width: 30px;
    margin-right: 10px;
  }

  .iconBlue {
    color: ${colors.blue};
  }

  .iconGray {
    color: ${colors.lightgray};
  }

  .iconCalendarRed {
    color: ${colors.red};
  }

  .helperText {
    height: 15px;
  }

  .datePicker input {
    background: ${colors.white};
    height: 40px;
    border: 1px solid ${colors.light};
    border-radius: 4px;
    padding-left: 35px;
    width: 100%;
    ::placeholder {
      color: ${colors.light};
    }
    :focus {
      border: 2px solid ${colors.blue};
      border-radius: 4px;
      outline: none !important;
    }
  }

  .datePickerError input {
    height: 40px;
    padding-left: 35px;
    width: 100%;
    background: ${colors.white};
    border: 2px solid ${colors.red};
    border-radius: 4px;
    :focus {
      border-color: ${colors.red};
      box-shadow: none;
    }
    :hover {
      border-color: ${colors.red};
    }
  }
`;
