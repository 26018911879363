import { Text } from '@chakra-ui/react';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../context/Provider';
import { IModalitiesFormProps } from '../../interfaces/IModalityForm';
import { AlertBannerInformation, ModalitiesFormInformation } from '../../text/GeneralText';
import CommonPage from '../CommonPage';
import ModalitiesFormFields from '../ModalitiesFields';
import { Container } from './styled';

function ModalitiesForm({ companyCode, modalityId, forms = [] }: IModalitiesFormProps) {
  const modalitiesFormData = forms.find((form: any) => form.id === modalityId) as any;

  const { setBillsReqBody, isBillsError, setBillsPayload } = useContext(AppContext);
  const [formOutput, setFormOutput] = useState({});
  const [billsInputData, setBillsInputData] = useState({ companyCode: '', modalityId: '', fields: {} });

  useEffect(() => {
    setBillsInputData({ companyCode, modalityId, fields: formOutput });
  }, [formOutput, companyCode, modalityId]);

  useEffect(() => {
    setBillsReqBody(billsInputData);
    setBillsPayload(billsInputData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billsInputData, setBillsReqBody]);

  const getAlertComponentError = () => {
    return (
      <AlertComponent id='alert-modalitiesError' variant='destructive' width='100%' marginBottom='11px'>
        <AlertTitle>{AlertBannerInformation.error.title}</AlertTitle>
        <AlertDescription>{AlertBannerInformation.error.description}</AlertDescription>
      </AlertComponent>
    );
  };

  return (
    <div>
      <div>
        <Container>
          <CommonPage subheader={ModalitiesFormInformation.title} />
        </Container>
        {!isBillsError && (
          <Container>
            <Text textStyle='bodyMd'>{ModalitiesFormInformation.subtitile}</Text>
          </Container>
        )}
        {isBillsError && getAlertComponentError()}
      </div>
      <div>
        <ModalitiesFormFields data={modalitiesFormData} formOutput={setFormOutput} isRequestError={isBillsError} />
      </div>
    </div>
  );
}

export default ModalitiesForm;
