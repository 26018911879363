import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

export const Container = styled.div`
  .containerFilter {
    border: 1px solid ${colors.gray5};
    border-radius: 8px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .cellAliasTableHome {
    width: 20px;
    padding-right: 25px;
  }

  .emptyState {
    margin: 40px 0px 25px 0px;
  }

  .marginTable {
    margin: 20px 0px 20px 0px;
  }

  .labelLgBold {
    padding: 0px 16px 0px 16px;
  }

  .flexMenuButton {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .iconMenuButton {
    margin-left: 12px;
    width: 18px;
  }

  .menuButtonEnabled {
    height: 32px;
    border: 1px solid ${colors.light};
    border-radius: 8px;
    color: ${colors.textgray};
    padding: 0px 8px 0px 8px;
    &:disabled {
      background: ${colors.white};
      color: ${colors.borderGray};
      border: 1px solid ${colors.rowHeaderBackgroundTable};
      border-radius: 8px;
    }
  }

  .filter {
    display: flex;
    align-items: center;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
  }

  .marg10Right {
    margin-right: 10px;
  }
`;

export default Container;
