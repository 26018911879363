/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator?.onLine ?? true);
  const [reconnectTimer, setReconnectTimer] = useState<NodeJS.Timeout | null>(null);
  const resetTimer = 1000;
  const setOnline = (): void => {
    if (reconnectTimer) {
      clearTimeout(reconnectTimer);
      setReconnectTimer(null);
    }
    setIsOnline(true);
  };

  const setOffline = (): void => {
    setIsOnline(false);

    const timer = setTimeout(() => {
      setOnline();
    }, resetTimer);
    setReconnectTimer(timer);
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [reconnectTimer]);

  return { isOnline, setOnline, setOffline };
};

export default useOnlineStatus;
