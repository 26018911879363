export default [
  {
    alias: '',
    amount1: 20000,
    amountToPay: 20000,
    amountType: 'ABI',
    companyName: 'Favorito 1',
    detail: [],
    dueDate: '2023-10-12',
    enabled: true,
    hash: '',
    maxAmount: '99999999',
    minAmount: '0.5',
    reference: '2425238383993933...',
    status: 'vencida',
  },
  {
    alias: '',
    amount1: 20000,
    amountToPay: 20000,
    amountType: 'ABI',
    companyName: 'Favorito 2',
    detail: [],
    dueDate: '2023-10-12',
    enabled: true,
    hash: '',
    maxAmount: '99999999',
    minAmount: '0.5',
    reference: '2425238383993933...',
    status: 'vencida',
  },
  {
    alias: '',
    amount1: 20000,
    amountToPay: 20000,
    amountType: 'ABI',
    companyName: 'Favorito 3 ',
    detail: [],
    dueDate: '2023-10-12',
    enabled: true,
    hash: '',
    maxAmount: '99999999',
    minAmount: '0.5',
    reference: '2425238383993933...',
    status: 'vence hoy',
  },
  {
    alias: '',
    amount1: 10000,
    amountToPay: 10000,
    amountType: 'ABI',
    companyName: 'Favorito 4',
    detail: [],
    dueDate: '2023-10-14',
    enabled: true,
    isWizard: true,
    hash: '',
    maxAmount: '99999999',
    minAmount: '0.5',
    reference: '2425238383993933...',
    status: 'vence hoy',
  },
  {
    alias: '',
    amount1: 10000,
    amountToPay: 10000,
    amountType: 'ABI',
    companyName: 'Favorito 5',
    detail: [],
    dueDate: '2023-10-14',
    enabled: true,
    isWizard: true,
    hash: 'FBmBoMBzlb5R6cjVKPBr4mq45W2aPr0JwRYA7zQzsM7',
    maxAmount: '99999999',
    minAmount: '0.5',
    reference: '2425238383993933...',
    status: 'por vencer',
  },
];
