import { Box, Grid, GridItem, Skeleton, Stack } from '@chakra-ui/react';

function SkeletonOnLoading() {
  return (
    <>
      <Stack>
        <Skeleton color='#E3E2E6' height='27.352px' />
        <Skeleton color='#E3E2E6' height='27.352px' />
        <Skeleton color='#E3E2E6' height='27.352px' />
      </Stack>
      <Box marginTop='20px'>
        <Grid h='589px' templateColumns='repeat(2, 1fr)' gap={4}>
          <GridItem rowStart={1} rowEnd={2}>
            <Skeleton height='64px' />
          </GridItem>
          <GridItem rowStart={2} rowEnd={3}>
            <Skeleton height='507px' />
          </GridItem>
          <GridItem colStart={2} rowStart={1} rowEnd={3}>
            <Skeleton height='100%' />
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default SkeletonOnLoading;
