import { Checkbox, CheckboxGroup, MenuItem } from '@chakra-ui/react';

import { BodyFilterMenuProps } from '../../../interfaces/IAccountSelectionFilter';

function BodyFilterMenu<T>({ handleClickOption, selectedItems, dataList }: BodyFilterMenuProps<T>) {
  return (
    <CheckboxGroup>
      {dataList?.map(({ code, codeName }: never) => (
        <MenuItem key={`BodyFilterMenu-${code}`}>
          <Checkbox defaultChecked={false} id={code} onChange={() => handleClickOption(code)} isChecked={selectedItems.includes(code)}>
            {codeName}
          </Checkbox>
        </MenuItem>
      ))}
    </CheckboxGroup>
  );
}
export default BodyFilterMenu;
