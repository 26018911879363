import React from 'react';

function Cancel() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='76' height='76' fill='none' viewBox='0 0 76 76'>
      <path
        fill='#FFDADF'
        d='M38 73.15C18.618 73.15 2.85 57.382 2.85 38 2.85 18.618 18.618 2.85 38 2.85c19.382 0 35.15 15.768 35.15 35.15 0 19.382-15.768 35.15-35.15 35.15z'
      />
      <path
        fill='#E43861'
        d='M38 3.8c18.858 0 34.2 15.342 34.2 34.2 0 18.858-15.343 34.2-34.2 34.2C19.142 72.2 3.8 56.857 3.8 38 3.8 19.142 19.142 3.8 38 3.8zm0-1.9C18.063 1.9 1.9 18.063 1.9 38S18.063 74.1 38 74.1 74.1 57.937 74.1 38 57.937 1.9 38 1.9z'
      />
      <path fill='#BA1B1B' d='M26.58 53.45l-4.03-4.03 26.87-26.87 4.03 4.03-26.87 26.87z' />
      <path fill='#BA1B1B' d='M22.55 26.58l4.03-4.03 26.87 26.87-4.03 4.03-26.87-26.87z' />
      <path
        fill='#BA1B1B'
        d='M53.45 26.581l-4.031-4.032L38 33.97 26.581 22.55l-4.032 4.031L33.97 38 22.55 49.419l4.031 4.032L38 42.03l11.419 11.42 4.032-4.031L42.03 38l11.42-11.419z'
      />
    </svg>
  );
}

export default Cancel;
