/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Skeleton, useToast } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { TablePaginationComponent } from '@wow/table-pagination';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import mockWizard from '../../__mocks__/NextExpirations/onboardingDataWizard/mockWizard';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { IRowBillSelected } from '../../interfaces/IAvailableBills';
import IExpirationCalendar from '../../interfaces/IExpirationCalendar';
import { INextExpiration, Pagination as PageInterfaces } from '../../interfaces/INextExpirationsBills';
import { getNextExpirations, getPermissions } from '../../services/api';
import { IndexWizardSteps, ToastTexts } from '../../text/GeneralText';
import { getJumpValue } from '../../utils/onJumpPagination';
import BottomNav from '../BottomNav';
import { Wizard } from '../CardHome/styled';
import EmptyStateScreen from '../EmptyStateScreen';
import TableComponent from '../TableComponent';
import ExpirationCalendarColumns from './component/ColumnsConfig';
import { Container } from './styled';

function ExpirationCalendar({ isFullCart, isWizardActive, wizzardCurrentStept }: IExpirationCalendar) {
  const { deleteDataServicePayment, operative, maxCartItem, cartItemsCount, getCartData, gireAvailable } = useContext(AppContext);
  const [pagination, setPagination] = useState<PageInterfaces>({
    actualPage: 0,
    elementsPerPage: 0,
    totalElements: 0,
    totalPages: 0,
  });
  const [page, setPage] = useState<number>(0);
  const [pageBefore, setPageBefore] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const [hasPermissionPreconfeccion, setHasPermissionPreconfeccion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listBills, setListBills] = useState([]);
  const [sortProp, setSortProp] = useState<string>('FechaVto');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const expirationCalendarColumns = useMemo(() => ExpirationCalendarColumns, []);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [hashesSelectedPrevius, setHashesSelectedPrevius] = useState([]);
  const numRows = 4;
  const navigate = useNavigate();
  const [facturasChecked, setFacturasChecked] = useState(new Map<string, IRowBillSelected>());
  const [unselectBills, setUnselectBills] = useState(false);
  const [totalPaymentSelect, setTotalPaymentSelect] = useState(0);
  const [isErrorAmountPay, setIsErrorAmountPay] = useState(false);
  const [amountToPayValues, setAmountToPayValues] = useState({});
  const [errorOnAddItemToCart, setErrorOnAddItemToCart] = useState(false);
  const [isFinishPrepareAction, setIsFinishPrepareAction] = useState(false);
  const { stepTwo, stepThree } = IndexWizardSteps;
  const DURATION_IN_MILLISECONDS = 4000;
  const ID_TOAST = 'expiration-toast';

  const { ToastUtilities } = useChakraToast();
  const drawerVariant = useMemo(
    () =>
      !isWizardActive
        ? 'bottomNav'
        : ([stepTwo, stepThree].includes(wizzardCurrentStept) && 'bottomNavModeWizard') || 'bottomNavModeOffWizard',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWizardActive, wizzardCurrentStept],
  );

  useEffect(() => {
    checkIfhasPermissions();
  }, []);

  // update the selected rows when isWizardActive state changes
  useEffect(() => {
    setRowsSelected(isWizardActive ? [{ original: mockWizard[3] }, { original: mockWizard[4] }] : []);
  }, [isWizardActive]);

  useEffect(() => {
    if (hasPermissionPreconfeccion || errorOnAddItemToCart) {
      getBills();
      keepRowsSelectedOnSorting(sortProp, sortOrder);
      setErrorOnAddItemToCart(false);
      setIsFinishPrepareAction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermissionPreconfeccion, sortProp, sortOrder, page, size, operative, errorOnAddItemToCart, isFinishPrepareAction]);

  useEffect(() => {
    if (isFinishPrepareAction) {
      getCartData();
      setPage(0);
      setRowsSelected([]);
      setHashesSelectedPrevius([]);
      setFacturasChecked(new Map());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishPrepareAction]);

  useEffect(() => {
    setRowsSelected([]);
    setHashesSelectedPrevius([]);
    setFacturasChecked(new Map());
  }, [operative]);

  useEffect(() => {
    let facturasCheckedLocal = new Map(facturasChecked);
    let hashesSelected = [];
    isNumberPageIdem(facturasCheckedLocal);

    rowsSelected.forEach(rowSelected => {
      hashesSelected.push(rowSelected.original.hash);
      if (!facturasCheckedLocal.has(rowSelected.original.hash)) {
        facturasCheckedLocal.set(rowSelected.original.hash, {
          ...rowSelected.original,
          enabled: rowSelected.original.enabled,
        });
      }
    });
    setHashesSelectedPrevius(hashesSelected);
    setFacturasChecked(facturasCheckedLocal);
    isFullCart(facturasCheckedLocal.size + cartItemsCount > maxCartItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected, cartItemsCount, maxCartItem, operative]);

  useEffect(() => {
    const selectedBillsArray = Array.from(facturasChecked.values());
    let addition = 0;
    let inError = 0;

    selectedBillsArray.forEach(bill => {
      const originalAmount = bill.amountToPay;
      let modifiedAmount = amountToPayValues[bill.hash] || originalAmount;
      if (amountToPayValues[bill.hash] === 0) {
        modifiedAmount = 0;
      }
      if (isAmountError(modifiedAmount, bill.minAmount, bill.maxAmount)) {
        inError += 1;
        addition += 0;
      } else {
        addition += modifiedAmount;
      }
    });

    setIsErrorAmountPay(inError > 0);
    setTotalPaymentSelect(addition);
  }, [facturasChecked, isErrorAmountPay, amountToPayValues]);

  useEffect(() => {
    if (unselectBills) {
      setUnselectBills(false);
    }
    setRowsSelected([]);
    setHashesSelectedPrevius([]);
    setFacturasChecked(new Map());
  }, [operative, unselectBills]);

  //! This is a patch for the table component component 17/12/2024 removed when DS have the fix for this
  useEffect(() => {
    const selectorCenterElement = document.querySelector('[data-testid="select"]');
    if (pagination.totalElements <= 15) {
      selectorCenterElement.setAttribute('disabled', '');
    } else {
      selectorCenterElement.removeAttribute('disabled');
    }
  }, [page, pagination.totalElements]);
  const isAmountError = (modifiedAmount, minAmount, maxAmount) => {
    return (modifiedAmount !== 0 && modifiedAmount < Number(minAmount)) || modifiedAmount > Number(maxAmount) || modifiedAmount === 0;
  };

  const updateAmountToPayValue = (hash, value) => {
    setAmountToPayValues(prevValues => ({
      ...prevValues,
      [hash]: value,
    }));
  };

  const setSort = (sortPropCol: string, sortOrderCol: string) => {
    setSortProp(sortPropCol);
    setSortOrder(sortOrderCol);
  };

  const isNumberPageIdem = facturasCheckedLocal => {
    if (page === pageBefore) {
      if (hashesSelectedPrevius.length >= rowsSelected.length) {
        const hashesSelected = rowsSelected.map(row => row.original.hash);
        const hashesUnselected = hashesSelectedPrevius.filter(element => !hashesSelected.includes(element));
        hashesUnselected.forEach(element => {
          facturasCheckedLocal.delete(element);
        });
      }
    } else {
      setHashesSelectedPrevius([]);
      setPageBefore(page);
    }
  };

  const getBills = () => {
    setIsLoading(true);
    getNextExpirations(sortProp, sortOrder, Number(page), Number(size))
      .then(res => {
        setListBills(res.data.nextExpirations);
        setPagination(res.data.page);
        setHasErrorConnection(false);
        setIsLoading(false);
      })
      .catch(error => {
        setHasErrorConnection(true);
        setIsLoading(false);
      });
  };

  const checkIfhasPermissions = async () => {
    setIsLoading(true);
    try {
      const res = await getPermissions();
      setHasPermissionPreconfeccion(res?.data?.generacionPreconfeccionPropia && res?.data?.generacionConfeccion);
    } catch (e) {
      setHasPermissionPreconfeccion(false);
    } finally {
      setIsLoading(false);
    }
  };

  const goCreatePayment = () => {
    deleteDataServicePayment();
    navigate(`/${routesNames.CreatePayment}`);
  };

  const keepRowsSelectedOnSorting = (sortProperty, sortOrder) => {
    const selectedRowsBeforeSorting = hashesSelectedPrevius;
    let selectedRowsAfterSorting = Array.from(facturasChecked.values());
    selectedRowsAfterSorting = listBills.filter(row => selectedRowsBeforeSorting.some(selectedRow => selectedRow.hash === row.hash));
    setHashesSelectedPrevius(selectedRowsAfterSorting);
  };

  if (errorOnAddItemToCart) {
    ToastUtilities.error({ id: ID_TOAST, duration: DURATION_IN_MILLISECONDS, description: ToastTexts.errorOnAddItemToCart });
  }

  const getScreen = () => {
    let screen;
    if (isLoading) {
      screen = (
        <>
          <Skeleton height='40px' marginBottom='6px' marginRight='6px' borderRadius='8px' marginTop='20px' />
          {[...Array(numRows)].map((_, index) => (
            <SkeletonRow key={index} widths={skeletonWidths} />
          ))}
        </>
      );
    } else if (!hasPermissionPreconfeccion && !isWizardActive) {
      screen = (
        <div className='emptyState'>
          <EmptyStateScreen page='HOME' variant='NO_ACCESS' hasButton={false} />
        </div>
      );
    } else if (hasErrorConnection && !isWizardActive) {
      screen = (
        <div className='emptyState'>
          <EmptyStateScreen page='HOME' variant='ERROR_CONN' hasButton onRetry={getBills} />
        </div>
      );
    } else {
      screen = (
        <Wizard id='calendarBills'>
          <TableComponent<INextExpiration[]>
            inputData={isWizardActive ? mockWizard : listBills}
            columnsData={
              expirationCalendarColumns(
                setSort,
                operative,
                updateAmountToPayValue,
                amountToPayValues,
                gireAvailable,
              ) as ColumnDef<RowData>[]
            }
            setSelectedRows={setRowsSelected}
            emptyContent={<EmptyStateScreen page='HOME' variant='NO_DATA' hasButton onRetry={goCreatePayment} />}
            rowsSelectedBefore={facturasChecked}
            isClearSelection={!operative || unselectBills}
          />
        </Wizard>
      );
    }
    return screen;
  };

  return (
    <Container>
      {getScreen()}
      <TablePaginationComponent
        totalPages={pagination.totalPages || 1}
        currentIndex={pagination.actualPage}
        totalRecords={pagination.totalElements}
        perPage={[15, 30, 45]}
        disabledPerPage={pagination.totalElements <= 15}
        currentPerPage={pagination.elementsPerPage}
        onNext={() => setPage(page + 1)}
        onPrev={() => setPage(page - 1)}
        onFirst={() => setPage(0)}
        onLast={() => setPage(pagination.totalPages - 1)}
        onJump={value => setPage(Number(getJumpValue(value)))}
        onPerPageChange={value => {
          setSize(Number(value));
          setPage(0);
        }}
      />
      <BottomNav
        drawerVariant={drawerVariant}
        countBills
        rowsSelected={facturasChecked}
        show={facturasChecked.size >= 1 || rowsSelected.length >= 1}
        isWizardActive={isWizardActive}
        unselectBills={setUnselectBills}
        totalAmountPay={totalPaymentSelect}
        isErrorAmountPay={isErrorAmountPay}
        amountToPayValues={amountToPayValues}
        setErrorOnAddItemToCart={setErrorOnAddItemToCart}
        setIsFinishPrepareAction={setIsFinishPrepareAction}
      />
    </Container>
  );
}

export default ExpirationCalendar;

const skeletonWidths = [5, 20, 10, 20, 10, 20, 10, 5];

function SkeletonRow({ widths }) {
  return (
    <div className='flexRow'>
      {widths.map((width, index) => (
        <Skeleton key={index} height='40px' width={`${width}%`} marginRight='6px' marginBottom='6px' borderRadius='8px' />
      ))}
    </div>
  );
}
