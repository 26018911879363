import { Button, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { TooltipTexts } from '../../text/GeneralText';
import { renderContentTextButton } from '../../utils/converters';

function CreatePaymentButton() {
  const { deleteDataServicePayment, permissions, operative, gireAvailable } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoNewPayment = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Buscar servicio',
    });
    deleteDataServicePayment();
    navigate(`/${routesNames.CreatePayment}`);
  };
  const getTooltipLabel = (): string => {
    if (!operative) {
      return TooltipTexts.gireFall;
    }
    if (!gireAvailable) {
      return TooltipTexts.gireFall;
    }
    return TooltipTexts.withoutPermissions;
  };
  const tooltipDisabled = operative && gireAvailable && permissions?.generacionPreconfeccionPropia && permissions?.generacionConfeccion;
  const buttonDisabled = !operative || !gireAvailable || !permissions?.generacionPreconfeccionPropia || !permissions?.generacionConfeccion;
  return (
    <Tooltip isDisabled={tooltipDisabled} hasArrow placement='left' label={getTooltipLabel()} shouldWrapChildren>
      <Button
        loadingText='Button'
        size='md'
        spinnerPlacement='start'
        isDisabled={buttonDisabled}
        variant='primary'
        onClick={() => handleGoNewPayment()}
      >
        {renderContentTextButton(location.pathname)}
      </Button>
    </Tooltip>
  );
}

export default CreatePaymentButton;
