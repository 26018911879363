import styled from 'styled-components';

export const PagosPanel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  height: 100%;
  position: relative;
  width: 95%;
  margin-bottom: 150px;
`;

export default PagosPanel;
